import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import i18n from 'src/i18n';

import mainStore from 'src/stores/mainStore';

import EmployeeEDMListSection from './sections/employee';
import CollaboratorEDMListSection from './sections/collaborator';

import styles from './styles.module.scss';

@observer
class EDMListPage extends React.Component {
  render() {
    return (
      <div className={clsx(styles.pageContainer)}>
        <div className={styles.pageTitle}>
          {i18n.t('edm_list_page_title')}
        </div>
        {
          mainStore.userType !== 'collaborator'
            ? (<EmployeeEDMListSection />)
            : (<CollaboratorEDMListSection />)
        }
      </div>
    );
  }
}

EDMListPage.propTypes = {

};

EDMListPage.defaultProps = {

};

export default EDMListPage;
