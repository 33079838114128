import { makeObservable, observable, action, computed, runInAction, toJS } from 'mobx';
import { message } from 'antd';

import i18n from 'src/i18n';
import { LEVEL_TYPE, SOURCE_CATEGORY_OPTIONS, TAG_ACTIONS } from 'src/constants/normal';
import ProjectService from 'src/services/project';
import mainStore from 'src/stores/mainStore';

export default class TopicContentBarViewModel {
  id = '';
  parent = '';

  @observable action = '';
  @observable hasEvaluation = false;
  @observable level = '';
  @observable level1 = {};
  @observable level2 = {};
  @observable level3 = {};
  @observable tagParent = {};
  @observable tag = {};
  @observable category = [];
  @observable website = [];
  @observable channel = [];

  @observable contentError = false;

  @computed get levelText() {
    switch (this.level) {
      case LEVEL_TYPE.Brand:
        return this.parent?.projectSearchAngle === 'brand' ? i18n.t('common_brand') : i18n.t('common_brand_category');
      case LEVEL_TYPE.ProductLine:
        return this.parent?.projectSearchAngle === 'brand' ? i18n.t('common_product_line') : i18n.t('common_brand');
      case LEVEL_TYPE.Product:
        return i18n.t('common_product');
      case LEVEL_TYPE.Tag:
        return i18n.t('common_tag');
      default:
        return '';
    }
  }

  @computed get levelPrefix() {
    return {
      level1: this.parent.projectSearchAngle === 'brand' ? i18n.t('common_brand') : i18n.t('common_brand_category'),
      level2: this.parent.projectSearchAngle === 'brand' ? i18n.t('common_product_line') : i18n.t('common_brand'),
      level3: i18n.t('common_product')
    };
  }

  @computed get description() {
    switch (this.level) {
      case LEVEL_TYPE.Brand:
        return `${this.level1?.name ?? ''}`;
      case LEVEL_TYPE.ProductLine:
        return `${this.level1?.name ?? ''}/${this.level2?.name ?? ''}`;
      case LEVEL_TYPE.Product:
        return `${this.level1?.name ?? ''}/${this.level3?.name ?? ''}`;
      case LEVEL_TYPE.Tag:
        return `${this.level1?.id ? `${this.level1.name}/` : ''}${this.tagParent?.name ?? ''}/${this.tag?.name ?? ''}`;
      default:
        return '';
    }
  }

  @computed get sourceText() {
    return !this.category[0]
      ? i18n.t('edm_drawer_topic_source_all_nodeco')
      : `${SOURCE_CATEGORY_OPTIONS.find((category) => category.value === this.category[0])?.label}`;
  }

  @computed get sendContent() {
    return {
      id: this.action === TAG_ACTIONS.Create ? null : this.id,
      action: this.action,
      hasEvaluation: this.hasEvaluation,
      ...(
        [LEVEL_TYPE.Brand, LEVEL_TYPE.ProductLine, LEVEL_TYPE.Product].includes(this.level)
          ? {
            searchKeywordLevel1Id: this.level1.id,
            searchTagParentId: null,
            searchTagId: null
          }
          : { searchKeywordLevel1Id: null }
      ),
      ...(
        this.level === LEVEL_TYPE.ProductLine
          ? { searchKeywordLevel2Id: this.level2.id }
          : { searchKeywordLevel2Id: null }
      ),
      ...(
        this.level === LEVEL_TYPE.Product
          ? { searchKeywordLevel3Id: this.level3.id }
          : { searchKeywordLevel3Id: null }
      ),
      ...(
        this.level === LEVEL_TYPE.Tag
          ? {
            searchKeywordLevel1Id: this.level1.id ?? null,
            searchKeywordLevel2Id: null,
            searchKeywordLevel3Id: null,
            searchTagParentId: this.tagParent.id,
            searchTagId: this.tag.id
          }
          : {}
      ),
      category: toJS(this.category),
      website: toJS(this.website.map((website) => encodeURIComponent(website))),
      channel: toJS(this.channel)
    };
  }

  constructor(data, parent) {
    makeObservable(this);

    this.init(data, parent);
  }

  @action init = async (data, parent) => {
    this.parent = parent;

    await this.updateContent(data);
  };

  @action updateContent = async (data) => {
    const {
      id,
      action: topicAction,
      hasEvaluation,
      searchKeywordLevel1Id,
      searchKeywordLevel2Id,
      searchKeywordLevel3Id,
      searchTagParentId,
      searchTagId,
      category,
      website,
      channel
    } = data;

    this.id = id;
    this.action = topicAction ?? '';
    this.hasEvaluation = hasEvaluation;
    this.category = category ?? [];
    this.website = website?.map((w) => decodeURIComponent(w)) ?? [];
    this.channel = channel ?? [];
    this.contentError = false;

    if (searchKeywordLevel1Id && searchKeywordLevel2Id) {
      runInAction(() => {
        this.level = LEVEL_TYPE.ProductLine;
      });
      await this.updateProductLineLevelContent(searchKeywordLevel1Id, searchKeywordLevel2Id);
    }

    if (searchKeywordLevel1Id && searchKeywordLevel3Id) {
      runInAction(() => {
        this.level = LEVEL_TYPE.Product;
      });
      await this.updateProductLevelContent(searchKeywordLevel1Id, searchKeywordLevel3Id);
    }

    if (searchKeywordLevel1Id && !searchKeywordLevel2Id && !searchKeywordLevel3Id) {
      runInAction(() => {
        this.level = LEVEL_TYPE.Brand;
      });
      await this.updateBrandLevelContent(searchKeywordLevel1Id);
    }

    if (searchTagParentId) {
      runInAction(() => {
        this.level = LEVEL_TYPE.Tag;
      });
      if (searchKeywordLevel1Id) {
        await this.updateBrandLevelContent(searchKeywordLevel1Id);
      } else {
        runInAction(() => {
          this.level1 = {};
        });
      }
      await this.updateTagContent(searchTagParentId, searchTagId);
    }
  };

  @action updateBrandLevelContent = async (brandId) => {
    if (mainStore.checkIsInQueue(`levelOneData${this.id}`)) {
      return;
    }

    mainStore.setLoading(`levelOneData${this.id}`);

    try {
      const [brand] = await ProjectService.getLevelContentByIds(this.parent.selectedProject, [brandId]);

      runInAction(() => {
        this.level1 = {
          id: brand._id,
          name: brand.name
        };
      });
    } catch (error) {
      this.contentError = true;
      message.error(i18n.t('api_get_project_level_one_data_error'));
    } finally {
      mainStore.setLoadingComplete(`levelOneData${this.id}`);
    }
  };

  @action updateProductLineLevelContent = async (brandId, productLineId) => {
    try {
      if (mainStore.checkIsInQueue(`levelTwoData${this.id}`)) {
        return;
      }
      mainStore.setLoading(`levelTwoData${this.id}`);

      const [brand, productLine] = await ProjectService.getLevelContentByIds(this.parent.selectedProject, [brandId, productLineId]);

      runInAction(() => {
        this.level1 = {
          id: brand._id,
          name: brand.name
        };
        this.level2 = {
          id: productLine._id,
          name: productLine.name
        };
      });
    } catch (error) {
      this.contentError = true;
      message.error(i18n.t('api_get_project_level_two_data_error'));
    } finally {
      mainStore.setLoadingComplete(`levelTwoData${this.id}`);
    }
  };

  @action updateProductLevelContent = async (brandId, productId) => {
    try {
      if (mainStore.checkIsInQueue(`levelThreeData${this.id}`)) {
        return;
      }
      mainStore.setLoading(`levelThreeData${this.id}`);

      const [brand, product] = await ProjectService.getLevelContentByIds(this.parent.selectedProject, [brandId, productId]);

      runInAction(() => {
        this.level1 = {
          id: brand._id,
          name: brand.name
        };
        this.level3 = {
          id: product._id,
          name: product.name
        };
      });
    } catch (error) {
      this.contentError = true;
      message.error(i18n.t('api_get_project_level_three_data_error'));
    } finally {
      mainStore.setLoadingComplete(`levelThreeData${this.id}`);
    }
  };

  @action updateTagContent = async (parentId, tagId) => {
    try {
      if (mainStore.checkIsInQueue(`tagData${this.id}`)) {
        return;
      }
      mainStore.setLoading(`tagData${this.id}`);

      const [tagParent, tag] = await ProjectService.getTagContentByIds(this.parent.selectedProject, [parentId, tagId]);

      runInAction(() => {
        this.tagParent = {
          id: tagParent._id,
          name: tagParent.name
        };
        this.tag = {
          id: tag._id,
          name: tag.name
        };
      });
    } catch (error) {
      this.contentError = true;
      message.error(i18n.t('api_get_project_tag_data_error'));
    } finally {
      mainStore.setLoadingComplete(`tagData${this.id}`);
    }
  };

  @action onEditClick = () => {
    this.parent.topicModalViewModel.onEditContentOpen(this);
  };

  @action onDeleteClick = () => {
    this.parent.onCheckDeleteModalOpen({
      type: 'topic',
      value: {
        level: this.levelText,
        topic: this.description
      },
      callback: this.onConfirmDeleteCallback
    });
  };

  @action onConfirmDeleteCallback = () => {
    if (this.action === TAG_ACTIONS.Create) {
      this.parent.onRemoveTopic(this.id);
    } else {
      this.action = TAG_ACTIONS.Delete;
    }
  };
}
