import { makeObservable, observable, action, computed, runInAction, reaction, toJS } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import WordCloud from 'wordcloud';

export default class WordCloudViewModel {
  type = 'words';
  key = uuidv4();
  @observable id = '';
  @observable name = '';
  @observable words = [];

  @observable parent = null;

  @observable resizeObserver = null;
  @observable width = 0;
  @observable widthCache = 0;

  @observable isDraw = false;

  @observable once = null;

  @observable isDrawnComplete = false;

  @computed get config() {
    return {
      list: this.words,
      fontFamily: 'Noto Sans TC',
      rotateRatio: 0,
      color: (_, __, ___, ____, _____, extraData) => extraData[0],
      fontWeight: 600,
      classes: 'words-item',
      shrinkToFit: true,
      shape: 'circle'
    };
  }

  constructor(words, parent) {
    makeObservable(this);

    this.init(words, parent);
  }

  @action init = (words, parent) => {
    this.parent = parent;
    this.updateList(words);
  };

  @action updateList = (words) => {
    const { data, id, name } = words;
    this.words = data;
    this.id = id;
    this.name = name;
  };

  didMount = () => {
    const resizeObserver = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      runInAction(() => {
        this.width = width;
      });
    });
    const container = document.querySelector(`#${this.id}`);

    runInAction(() => {
      this.resizeObserver = resizeObserver;
      this.resizeObserver.observe(container);
    });

    this.onDraw();
  };

  @action onDraw = () => {
    if (this.isDraw || this.words.length < 1) {
      this.isDrawnComplete = true;
      return;
    }
    this.isDraw = true;

    const container = document.querySelector(`#${this.id}`);

    container.addEventListener('wordclouddrawn', (e) => this.onItemDrawn(e));

    WordCloud(container, this.config);

    container.removeEventListener('wordclouddrawn', (e) => this.onItemDrawn(e));

    this.isDraw = false;
  };

  onItemDrawn = (e) => {
    const item = e.detail.item[0];
    const lastItem = this.words[this.words.length - 1]?.[0];

    if (item === lastItem) {
      setTimeout(() => {
        runInAction(() => {
          this.isDrawnComplete = true;
        });
      }, 1000);
    }
  };

  willUnmount = () => {
    const container = document.querySelector(`#${this.id}`);
    if (!container) {
      return;
    }
    this.resizeObserver?.unobserve(container);

    // this.once?.();
  };
}
