import API from 'src/apis';

import UserModel from 'src/models/response/User';

import Auth from './auth';

export default class AuthorizationService {
  static async getProfile() {
    const res = await API.auth.getProfile();

    return UserModel.fromRes(res.data.result);
  }

  static async login() {
    await API.auth.login();
  }

  static logout() {
    Auth.cleanToken();
  }

  static async checkMaintenance() {
    const res = await API.auth.checkMaintenance();

    return res.data.result;
  }
}
