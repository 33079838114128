import i18n from 'src/i18n';

// > {
// >  title: String 欄位名稱
// >  dataIndex: String 對應資料index
// >  key: String key
// >  render: function 客製化資料render
// > }

export const EDM_LIST_KEYS = {
  Name: 'name',
  ProjectId: 'projectId',
  Time: 'time',
  AutoSend: 'autoSend',
  Frequency: 'frequency',
  Template: 'template',
  Collaborator: 'collaborator',
  PersonInCharge: 'personInCharge',
  LastChangedTime: 'lastChangedTime',
  Project: 'project',
  EdmAuto: 'edmAuto',
  EdmManual: 'edmManual',
  Action: 'action'
};

export const EDM_LIST_TABLE = [
  {
    title: i18n.t('edm_list_table_name_title'),
    dataIndex: EDM_LIST_KEYS.Name,
    key: EDM_LIST_KEYS.Name,
    width: 150,
    fixed: 'left'
  },
  {
    title: i18n.t('edm_list_table_project_id_title'),
    dataIndex: EDM_LIST_KEYS.ProjectId,
    key: EDM_LIST_KEYS.ProjectId,
    width: 110
  },
  {
    title: i18n.t('edm_list_table_time_title'),
    dataIndex: EDM_LIST_KEYS.Time,
    key: EDM_LIST_KEYS.Time,
    width: 150
  },
  {
    title: i18n.t('edm_list_table_auto_send_title'),
    dataIndex: EDM_LIST_KEYS.AutoSend,
    key: EDM_LIST_KEYS.AutoSend,
    width: 80
  },
  {
    title: i18n.t('edm_list_table_frequency_title'),
    dataIndex: EDM_LIST_KEYS.Frequency,
    key: EDM_LIST_KEYS.Frequency,
    width: 100
  },
  {
    title: i18n.t('edm_list_table_template_title'),
    dataIndex: EDM_LIST_KEYS.Template,
    key: EDM_LIST_KEYS.Template,
    width: 100
  },
  {
    title: i18n.t('edm_list_table_collaborator_title'),
    dataIndex: EDM_LIST_KEYS.Collaborator,
    key: EDM_LIST_KEYS.Collaborator,
    width: 120
  },
  {
    title: i18n.t('edm_list_table_person_in_charge_title'),
    dataIndex: EDM_LIST_KEYS.PersonInCharge,
    key: EDM_LIST_KEYS.PersonInCharge,
    width: 60
  },
  {
    title: i18n.t('edm_list_table_last_changed_time_title'),
    dataIndex: EDM_LIST_KEYS.LastChangedTime,
    key: EDM_LIST_KEYS.LastChangedTime,
    width: 150
  },
  {
    title: i18n.t('edm_list_table_project_status_title'),
    dataIndex: EDM_LIST_KEYS.Project,
    key: EDM_LIST_KEYS.Project,
    width: 150
  },
  {
    title: i18n.t('edm_list_table_edm_auto_status_title'),
    dataIndex: EDM_LIST_KEYS.EdmAuto,
    key: EDM_LIST_KEYS.EdmAuto,
    width: 150
  },
  {
    title: i18n.t('edm_list_table_edm_manual_status_title'),
    dataIndex: EDM_LIST_KEYS.EdmManual,
    key: EDM_LIST_KEYS.EdmManual,
    width: 150
  },
  {
    title: i18n.t('edm_list_table_action_title'),
    dataIndex: EDM_LIST_KEYS.Action,
    key: EDM_LIST_KEYS.Action,
    width: 80,
    fixed: 'right'
  }
];

export const EDM_LIST_COLLABORATOR_TABLE = [
  {
    title: i18n.t('edm_list_table_name_title'),
    dataIndex: EDM_LIST_KEYS.Name,
    key: EDM_LIST_KEYS.Name,
    width: 200
  },
  {
    title: i18n.t('edm_list_table_time_title'),
    dataIndex: EDM_LIST_KEYS.Time,
    key: EDM_LIST_KEYS.Time,
    width: 200
  },
  {
    title: i18n.t('edm_list_table_action_title'),
    dataIndex: EDM_LIST_KEYS.Action,
    key: EDM_LIST_KEYS.Action,
    width: 80
  }
];



export const RESULT_LIST_KEYS = {
  Time: 'time',
  Frequency: 'frequency',
  Project: 'project',
  EdmAuto: 'edmAuto',
  EdmManual: 'edmManual',
  LatestActionTime: 'latestActionTime',
  LatestSendTime: 'latestSendTime',
  LatestSender: 'latestSender',
  Action: 'action'
};

export const RESULT_LIST_TABLE = [
  {
    title: i18n.t('result_page_table_time'),
    dataIndex: RESULT_LIST_KEYS.Time,
    key: RESULT_LIST_KEYS.Time,
    width: 200,
    fixed: 'left'
  },
  {
    title: i18n.t('result_page_table_frequency'),
    dataIndex: RESULT_LIST_KEYS.Frequency,
    key: RESULT_LIST_KEYS.Frequency,
    width: 150
  },
  {
    title: i18n.t('result_page_table_project_analysis_status'),
    dataIndex: RESULT_LIST_KEYS.Project,
    key: RESULT_LIST_KEYS.Project,
    width: 150
  },
  {
    title: i18n.t('result_page_table_newsletter_analysis_auto_status'),
    dataIndex: RESULT_LIST_KEYS.EdmAuto,
    key: RESULT_LIST_KEYS.EdmAuto,
    width: 150
  },
  {
    title: i18n.t('result_page_table_newsletter_analysis_manual_status'),
    dataIndex: RESULT_LIST_KEYS.EdmManual,
    key: RESULT_LIST_KEYS.EdmManual,
    width: 150
  },
  {
    title: i18n.t('result_page_table_latest_action_time'),
    dataIndex: RESULT_LIST_KEYS.LatestActionTime,
    key: RESULT_LIST_KEYS.LatestActionTime,
    width: 200
  },
  {
    title: i18n.t('result_page_table_latest_send_time'),
    dataIndex: RESULT_LIST_KEYS.LatestSendTime,
    key: RESULT_LIST_KEYS.LatestSendTime,
    width: 120
  },
  {
    title: i18n.t('result_page_table_latest_sender'),
    dataIndex: RESULT_LIST_KEYS.LatestSender,
    key: RESULT_LIST_KEYS.LatestSender,
    width: 120
  },
  {
    title: i18n.t('result_page_table_action'),
    dataIndex: RESULT_LIST_KEYS.Action,
    key: RESULT_LIST_KEYS.Action,
    width: 100,
    fixed: 'right'
  }
];

export const EDITABLE_TOPIC_KEYS = {
  Order: 'order',
  Title: 'title',
  Brand: 'brand',
  Author: 'author',
  Date: 'date',
  LatestDate: 'latestDate',
  Category: 'category',
  Website: 'website',
  Channel: 'channel',
  WOMCount: 'womCount',
  CommentCount: 'commentCount',
  Url: 'url',
  Sentiment: 'sentiment',
  EvalType: 'evalType',
  TopicAttr: 'topicAttr',
  ReportAdvice: 'reportAdvice',
  DepartmentAttr: 'departmentAttr',
  ShouldSend: 'shouldSend'
};

export const EDITABLE_TOPIC_TABLE = [
  {
    title: i18n.t('result_edit_page_topic_table_order'),
    dataIndex: EDITABLE_TOPIC_KEYS.Order,
    key: EDITABLE_TOPIC_KEYS.Order,
    width: 50,
    fixed: 'left'
  },
  {
    title: i18n.t('result_edit_page_topic_table_title'),
    dataIndex: EDITABLE_TOPIC_KEYS.Title,
    key: EDITABLE_TOPIC_KEYS.Title,
    width: 240,
    fixed: 'left'
  },
  {
    title: i18n.t('result_edit_page_topic_table_category'),
    dataIndex: EDITABLE_TOPIC_KEYS.Category,
    key: EDITABLE_TOPIC_KEYS.Category,
    width: 75
  },
  {
    title: i18n.t('result_edit_page_topic_table_channel'),
    dataIndex: EDITABLE_TOPIC_KEYS.Channel,
    key: EDITABLE_TOPIC_KEYS.Channel,
    width: 150
  },
  {
    title: i18n.t('common_brand'),
    dataIndex: EDITABLE_TOPIC_KEYS.Brand,
    key: EDITABLE_TOPIC_KEYS.Brand,
    width: 75
  },
  {
    title: i18n.t('result_edit_page_topic_table_wom_count'),
    dataIndex: EDITABLE_TOPIC_KEYS.WOMCount,
    key: EDITABLE_TOPIC_KEYS.WOMCount,
    width: 100
  },
  {
    title: i18n.t('result_edit_page_topic_table_sentiment'),
    dataIndex: EDITABLE_TOPIC_KEYS.Sentiment,
    key: EDITABLE_TOPIC_KEYS.Sentiment,
    width: 50
  },
  {
    title: i18n.t('result_edit_page_topic_table_eval_type'),
    dataIndex: EDITABLE_TOPIC_KEYS.EvalType,
    key: EDITABLE_TOPIC_KEYS.EvalType,
    width: 150
  },
  {
    title: i18n.t('result_edit_page_topic_table_topic_attribute'),
    dataIndex: EDITABLE_TOPIC_KEYS.TopicAttr,
    key: EDITABLE_TOPIC_KEYS.TopicAttr,
    width: 150
  },
  {
    title: i18n.t('result_edit_page_topic_table_report_advice'),
    dataIndex: EDITABLE_TOPIC_KEYS.ReportAdvice,
    key: EDITABLE_TOPIC_KEYS.ReportAdvice,
    width: 150
  },
  {
    title: i18n.t('result_edit_page_topic_table_department'),
    dataIndex: EDITABLE_TOPIC_KEYS.DepartmentAttr,
    key: EDITABLE_TOPIC_KEYS.DepartmentAttr,
    width: 150
  },
  {
    title: i18n.t('result_edit_page_topic_table_should_send'),
    dataIndex: EDITABLE_TOPIC_KEYS.ShouldSend,
    key: EDITABLE_TOPIC_KEYS.ShouldSend,
    width: 100
  }
];


export const TOPICS_CSV_TITLE_ROW = [
  { key: EDITABLE_TOPIC_KEYS.Order, header: i18n.t('download_key_order') },
  { key: EDITABLE_TOPIC_KEYS.Title, header: i18n.t('download_key_title') },
  { key: EDITABLE_TOPIC_KEYS.Brand, header: i18n.t('download_key_brand') },
  // { key: 'content', header: '內容' },
  { key: EDITABLE_TOPIC_KEYS.Author, header: i18n.t('download_key_author') },
  { key: EDITABLE_TOPIC_KEYS.Date, header: i18n.t('download_key_date') },
  { key: EDITABLE_TOPIC_KEYS.LatestDate, header: i18n.t('download_key_latest_date') },
  { key: EDITABLE_TOPIC_KEYS.Category, header: i18n.t('download_key_category') },
  { key: EDITABLE_TOPIC_KEYS.Website, header: i18n.t('download_key_website') },
  { key: EDITABLE_TOPIC_KEYS.Channel, header: i18n.t('download_key_channel') },
  // { key: 'viewCount', header: '點閱數' },
  // { key: 'shareCount', header: '分享數' },
  { key: EDITABLE_TOPIC_KEYS.CommentCount, header: i18n.t('download_key_comment_count') },
  { key: EDITABLE_TOPIC_KEYS.WOMCount, header: i18n.t('download_key_wom_count') },
  { key: EDITABLE_TOPIC_KEYS.Sentiment, header: i18n.t('download_key_sentiment') },
  { key: EDITABLE_TOPIC_KEYS.EvalType, header: i18n.t('download_key_eval_type') },
  { key: EDITABLE_TOPIC_KEYS.TopicAttr, header: i18n.t('download_key_topic_attribute') },
  { key: EDITABLE_TOPIC_KEYS.ReportAdvice, header: i18n.t('download_key_report_advice') },
  { key: EDITABLE_TOPIC_KEYS.DepartmentAttr, header: i18n.t('download_key_department_attribute') },
  { key: EDITABLE_TOPIC_KEYS.Url, header: i18n.t('download_key_url') }
];

export const OPEN_EDIT_TOPIC_KEYS = {
  Order: 'order',
  Title: 'title',
  Category: 'category',
  Channel: 'channel',
  WOMCount: 'womCount',
  EvalType: 'evalType',
  TopicAttr: 'topicAttr',
  ReportAdvice: 'reportAdvice',
  DepartmentAttr: 'departmentAttr'
};

export const OPEN_EDIT_TOPIC_TABLE = [
  {
    title: i18n.t('preview_page_topic_table_order'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.Order,
    key: OPEN_EDIT_TOPIC_KEYS.Order,
    width: 60
  },
  {
    title: i18n.t('preview_page_topic_table_title'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.Title,
    key: OPEN_EDIT_TOPIC_KEYS.Title,
    width: 200
  },
  {
    title: i18n.t('preview_page_topic_table_category'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.Category,
    key: OPEN_EDIT_TOPIC_KEYS.Category,
    width: 80
  },
  {
    title: i18n.t('preview_page_topic_table_channel'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.Channel,
    key: OPEN_EDIT_TOPIC_KEYS.Channel,
    width: 150
  },
  {
    title: i18n.t('preview_page_topic_table_wom_count'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.WOMCount,
    key: OPEN_EDIT_TOPIC_KEYS.WOMCount,
    width: 60
  },
  {
    title: i18n.t('preview_page_topic_table_eval_type'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.EvalType,
    key: OPEN_EDIT_TOPIC_KEYS.EvalType
  },
  {
    title: i18n.t('preview_page_topic_table_topic_attribute'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.TopicAttr,
    key: OPEN_EDIT_TOPIC_KEYS.TopicAttr
  },
  {
    title: i18n.t('preview_page_topic_table_report_advice'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.ReportAdvice,
    key: OPEN_EDIT_TOPIC_KEYS.ReportAdvice
  },
  {
    title: i18n.t('preview_page_topic_table_department_attribute'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.DepartmentAttr,
    key: OPEN_EDIT_TOPIC_KEYS.DepartmentAttr
  }
];

export const OPEN_EDIT_TOPIC_WITH_LEVEL_ONE_KEYS = {
  Order: 'order',
  Title: 'title',
  Category: 'category',
  Channel: 'channel',
  Brand: 'brand',
  WOMCount: 'womCount',
  EvalType: 'evalType',
  TopicAttr: 'topicAttr',
  ReportAdvice: 'reportAdvice',
  DepartmentAttr: 'departmentAttr'
};
export const OPEN_EDIT_TOPIC_WITH_LEVEL_ONE_TABLE = [
  {
    title: i18n.t('preview_page_topic_table_order'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.Order,
    key: OPEN_EDIT_TOPIC_KEYS.Order,
    width: 60
  },
  {
    title: i18n.t('preview_page_topic_table_title'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.Title,
    key: OPEN_EDIT_TOPIC_KEYS.Title,
    width: 200
  },
  {
    title: i18n.t('preview_page_topic_table_category'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.Category,
    key: OPEN_EDIT_TOPIC_KEYS.Category,
    width: 80
  },
  {
    title: i18n.t('preview_page_topic_table_channel'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.Channel,
    key: OPEN_EDIT_TOPIC_KEYS.Channel,
    width: 150
  },
  {
    title: i18n.t('common_brand'),
    dataIndex: OPEN_EDIT_TOPIC_WITH_LEVEL_ONE_KEYS.Brand,
    key: OPEN_EDIT_TOPIC_WITH_LEVEL_ONE_KEYS.Brand,
    width: 80
  },
  {
    title: i18n.t('preview_page_topic_table_wom_count'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.WOMCount,
    key: OPEN_EDIT_TOPIC_KEYS.WOMCount,
    width: 60
  },
  {
    title: i18n.t('preview_page_topic_table_eval_type'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.EvalType,
    key: OPEN_EDIT_TOPIC_KEYS.EvalType
  },
  {
    title: i18n.t('preview_page_topic_table_topic_attribute'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.TopicAttr,
    key: OPEN_EDIT_TOPIC_KEYS.TopicAttr
  },
  {
    title: i18n.t('preview_page_topic_table_report_advice'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.ReportAdvice,
    key: OPEN_EDIT_TOPIC_KEYS.ReportAdvice
  },
  {
    title: i18n.t('preview_page_topic_table_department_attribute'),
    dataIndex: OPEN_EDIT_TOPIC_KEYS.DepartmentAttr,
    key: OPEN_EDIT_TOPIC_KEYS.DepartmentAttr
  }
];

export const CLOSE_EDIT_TOPIC_KEYS = {
  Order: 'order',
  Title: 'title',
  Category: 'category',
  Channel: 'channel',
  WOMCount: 'womCount',
  Sentiment: 'sentiment'
};

export const CLOSE_EDIT_TOPIC_TABLE = [
  {
    title: i18n.t('preview_page_topic_table_order'),
    dataIndex: CLOSE_EDIT_TOPIC_KEYS.Order,
    key: CLOSE_EDIT_TOPIC_KEYS.Order,
    width: 60
  },
  {
    title: i18n.t('preview_page_topic_table_title'),
    dataIndex: CLOSE_EDIT_TOPIC_KEYS.Title,
    key: CLOSE_EDIT_TOPIC_KEYS.Title
  },
  {
    title: i18n.t('preview_page_topic_table_category'),
    dataIndex: CLOSE_EDIT_TOPIC_KEYS.Category,
    key: CLOSE_EDIT_TOPIC_KEYS.Category,
    width: 120
  },
  {
    title: i18n.t('preview_page_topic_table_channel'),
    dataIndex: CLOSE_EDIT_TOPIC_KEYS.Channel,
    key: CLOSE_EDIT_TOPIC_KEYS.Channel,
    width: 200
  },
  {
    title: i18n.t('preview_page_topic_table_wom_count'),
    dataIndex: CLOSE_EDIT_TOPIC_KEYS.WOMCount,
    key: CLOSE_EDIT_TOPIC_KEYS.WOMCount,
    width: 80
  },
  {
    title: i18n.t('preview_page_topic_table_sentiment'),
    dataIndex: CLOSE_EDIT_TOPIC_KEYS.Sentiment,
    key: CLOSE_EDIT_TOPIC_KEYS.Sentiment,
    width: 120
  }
];

export const CLOSE_EDIT_TOPIC_WITH_LEVEL_ONE_KEYS = {
  Order: 'order',
  Title: 'title',
  Category: 'category',
  Channel: 'channel',
  Brand: 'brand',
  WOMCount: 'womCount',
  Sentiment: 'sentiment'
};

export const CLOSE_EDIT_TOPIC_WITH_LEVEL_ONE_TABLE = [
  {
    title: i18n.t('preview_page_topic_table_order'),
    dataIndex: CLOSE_EDIT_TOPIC_KEYS.Order,
    key: CLOSE_EDIT_TOPIC_KEYS.Order,
    width: 60
  },
  {
    title: i18n.t('preview_page_topic_table_title'),
    dataIndex: CLOSE_EDIT_TOPIC_KEYS.Title,
    key: CLOSE_EDIT_TOPIC_KEYS.Title
  },
  {
    title: i18n.t('preview_page_topic_table_category'),
    dataIndex: CLOSE_EDIT_TOPIC_KEYS.Category,
    key: CLOSE_EDIT_TOPIC_KEYS.Category,
    width: 120
  },
  {
    title: i18n.t('preview_page_topic_table_channel'),
    dataIndex: CLOSE_EDIT_TOPIC_KEYS.Channel,
    key: CLOSE_EDIT_TOPIC_KEYS.Channel,
    width: 200
  },
  {
    title: i18n.t('common_brand'),
    dataIndex: CLOSE_EDIT_TOPIC_WITH_LEVEL_ONE_KEYS.Brand,
    key: CLOSE_EDIT_TOPIC_WITH_LEVEL_ONE_KEYS.Brand,
    width: 120
  },
  {
    title: i18n.t('preview_page_topic_table_wom_count'),
    dataIndex: CLOSE_EDIT_TOPIC_KEYS.WOMCount,
    key: CLOSE_EDIT_TOPIC_KEYS.WOMCount,
    width: 80
  },
  {
    title: i18n.t('preview_page_topic_table_sentiment'),
    dataIndex: CLOSE_EDIT_TOPIC_KEYS.Sentiment,
    key: CLOSE_EDIT_TOPIC_KEYS.Sentiment,
    width: 120
  }
];

export const FEATURE_KEYWORD_KEYS = {
  Ranking: 'ranking',
  Keyword: 'keyword',
  Count: 'count'
};

export const FEATURE_KEYWORD_TABLE = [
  {
    title: i18n.t('preview_page_feature_table_ranking'),
    dataIndex: FEATURE_KEYWORD_KEYS.Ranking,
    key: FEATURE_KEYWORD_KEYS.Ranking,
    width: 60
  },
  {
    title: i18n.t('preview_page_feature_table_keyword'),
    dataIndex: FEATURE_KEYWORD_KEYS.Keyword,
    key: FEATURE_KEYWORD_KEYS.Keyword
  },
  {
    title: i18n.t('preview_page_feature_table_count'),
    dataIndex: FEATURE_KEYWORD_KEYS.Count,
    key: FEATURE_KEYWORD_KEYS.Count,
    width: 60
  }
];
