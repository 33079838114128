import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from 'src/i18n';

import Auth from 'src/services/auth';

import styles from './styles.module.scss';

const LoginSuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (Auth.hasToken()) {
      navigate('/');
    }

    if (Auth.hasJWT()) {
      Auth.setToken();
      setTimeout(() => {
        navigate('/');
      }, 500);
    } else {
      const interval = setInterval(() => {
        if (Auth.hasJWT()) {
          clearInterval(interval);

          Auth.setToken();
          setTimeout(() => {
            navigate('/');
          }, 500);
        }
      }, 1000);
    }
  });
  return (
    <div className={styles.page}>
      <svg width="200" height="200" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill="#2d4059">
        <circle cx="50" cy="50" r="30" strokeWidth="8" stroke="#2d4059" strokeDasharray="47.1 47.1" fill="none" transform="rotate(225 50 50)">
          <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 50 50" to="360 50 50" dur="1s" repeatCount="indefinite" />
        </circle>
        <circle cx="50" cy="50" r="24" strokeWidth="8" stroke="#2d4059" strokeDasharray="37.7 37.7" fill="none" transform="rotate(-45 50 50)">
          <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 50 50" to="-360 50 50" dur="1s" repeatCount="indefinite" />
        </circle>
        <circle cx="50" cy="50" r="18" strokeWidth="8" stroke="#2d4059" strokeDasharray="28.3 28.3" fill="none" transform="rotate(225 50 50)">
          <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 50 50" to="360 50 50" dur="2s" repeatCount="indefinite" />
        </circle>
      </svg>
      <div className={styles.text}>
        {i18n.t('common_user_auth')}
      </div>
    </div>
  );
};

LoginSuccessPage.propTypes = {

};

LoginSuccessPage.defaultProps = {

};


export default LoginSuccessPage;
