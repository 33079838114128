import { makeObservable, observable, action } from 'mobx';

export default class ErrorModalViewModel {
  @observable isOpen = false;

  @observable errorContent = '';

  constructor(text) {
    makeObservable(this);

    this.init(text);
  }

  @action init = (text) => {
    this.errorContent = text;
  };

  @action onOpen = () => {

    this.isOpen = true;
  };

  @action onClose = () => {

    this.isOpen = false;
  };
}
