import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import BarChart from 'src/components/BarChart';
import LineChart from 'src/components/LineChart';
import WordCloud from 'src/components/WordCloud';

import withRouter from 'src/components/withRouter';

import ScreenshotPageViewModel from './viewModel';

import styles from './styles.module.scss';

@observer
class ScreenshotPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new ScreenshotPageViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount(this.props.router.params);
  }

  render() {
    return (
      <div className={styles.main}>
        <div className={clsx('mainBrandLine', styles.lineChartContainer)}>
          <LineChart
            data={this.viewModel.mainBrandTrend}
            isScreenShot
            onCompletedCallback={this.viewModel.onLineChartCompleted}
          />
        </div>
        <div className={clsx('mainBrandBar', styles.barChartContainer)}>
          <BarChart
            data={this.viewModel.mainBrandRanking}
            isScreenShot
            searchAngle={this.viewModel.searchAngle}
            onCompletedCallback={this.viewModel.onBarChartCompleted}
          />
        </div>
        <div className={styles.wordCloudsContainer}>
          {
            this.viewModel.mainBrandWordCloud
            && (
              <div className={clsx('mainBrandCloud', styles.cloudItem)}>
                <WordCloud
                  viewModel={this.viewModel.mainBrandWordCloud}
                  isScreenshot
                  idName="mainBrandCloud"
                />
              </div>
            )
          }
          {
            this.viewModel.subjectWordCloud.map((subject) => {
              return (
                <div
                  key={subject.id}
                  className={clsx(subject.wordCloud.id, styles.cloudItem)}
                >
                  <WordCloud
                    viewModel={subject.wordCloud}
                    isScreenshot
                  />
                </div>
              );
            })
          }
        </div>
        {
          ((this.viewModel.subjectWordCloud.length > 0 && this.viewModel.subjectWordCloud.every((item) => item.wordCloud.isDrawnComplete))
            && (this.viewModel.isBarCHartCompleted && this.viewModel.isLineChartCompleted))
          && (
            <div className={styles.completed} id="completed" />
          )
        }
      </div>
    );
  }
}

ScreenshotPage.propTypes = {
  router: PropTypes.object

};

ScreenshotPage.defaultProps = {
  router: {}
};

export default withRouter(ScreenshotPage);
