import API from 'src/apis';

import ProjectListModel from 'src/models/response/ProjectList';
import SearchTagModel from 'src/models/response/SearchTag';
import SourceModel from 'src/models/response/Source';

export default class ProjectService {
  static async getProjectsList(params) {
    const res = await API.project.getProjectList(params);

    return ProjectListModel.fromRes(res.data.result);
  }

  static async getProjectDetailById(id) {
    const res = await API.project.getProjectDetailById(id);

    return res.data.result;
  }

  static async getFeatureSetByProject(id) {
    const res = await API.project.getFeatureSetByProject(id);

    return { featureSetList: res.data.result.map((feature) => ({ value: feature._id, label: feature.name })) };
  }

  static async getBrandByProject(id) {
    const res = await API.project.getBrandByProject(id);

    return { brand: res.data.result.keywords?.map((item) => ({ value: item.keyword._id, label: item.keyword.name })) };
  }

  static async getProductLineByBrandId(pid, bid) {
    const res = await API.project.getProductLineByBrandId(pid, bid);

    return { productLine: res.data.result.keywords?.map((item) => ({ value: item.keyword._id, label: item.keyword.name })) };
  }

  static async getProductByBrandId(pid, bid) {
    const res = await API.project.getProductByBrandId(pid, bid);

    return { product: res.data.result.keywords?.map((item) => ({ value: item.keyword._id, label: item.keyword.name })) };
  }

  static async getSearchTagByProjectId(id) {
    const res = await API.project.getSearchTagByProjectId(id);

    return SearchTagModel.fromRes(res.data.result);
  }

  static async getLevelContentByIds(id, ids) {
    const res = await API.project.getLevelContentByIds(id, ids);

    return res.data.result;
  }

  static async getTagContentByIds(id, ids) {
    const res = await API.project.getTagContentByIds(id, ids);

    return res.data.result;
  }

  static async getSourceById(id) {
    const res = await API.project.getSource(id);

    return SourceModel.fromRes(res.data.result);
  }
}
