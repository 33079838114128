import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';
import dayjs from 'dayjs';
import * as xlsx from 'xlsx';

import mainStore from 'src/stores/mainStore';
import i18n from 'src/i18n';
import { TIME_SELECT_MODAL_TYPE, SOURCE_CATEGORY_TC } from 'src/constants/normal';
import { TOPICS_CSV_TITLE_ROW } from 'src/constants/table';
import ProjectService from 'src/services/project';
import EDMService from 'src/services/edm';

export default class TimeSelectModalViewModel {
  parent = null;

  @observable isOpen = false;
  @observable type = null; // > TIME_SELECT_MODAL_TYPE

  targetNewsletterId = null;
  @observable selectedDay = [null, null];
  @observable availableDate = [dayjs(), dayjs()];

  // > for download topic use.
  searchAngle = 'brand';
  projectId = null;
  name = '';
  subjectList = [];
  tagPool = [];

  @computed get ratingTags() {
    const result = {};
    this.tagPool.filter((tag) => tag.type === 'rating').forEach((tag) => {
      result[`${tag.value}`] = tag.label;
    });
    return result;
  }

  @computed get topicTags() {
    const result = {};
    this.tagPool.filter((tag) => tag.type === 'topic').forEach((tag) => {
      result[`${tag.value}`] = tag.label;
    });
    return result;
  }

  @computed get suggestionTags() {
    const result = {};
    this.tagPool.filter((tag) => tag.type === 'suggestion').forEach((tag) => {
      result[`${tag.value}`] = tag.label;
    });
    return result;
  }

  @computed get departmentTags() {
    const result = {};
    this.tagPool.filter((tag) => tag.type === 'department').forEach((tag) => {
      result[`${tag.value}`] = tag.label;
    });
    return result;
  }

  constructor(parent) {
    makeObservable(this);

    this.init(parent);
  }

  @action init = (parent) => {
    this.parent = parent;
  };

  @action onOpen = (id, time, type) => {
    this.targetNewsletterId = id;
    this.type = type;

    const [gte, lte] = time.split('-');
    const compareLte = Math.min(dayjs(lte, 'Asia/Taipei').valueOf(), dayjs().valueOf());

    this.availableDate = [dayjs(gte, 'Asia/Taipei'), dayjs(compareLte)];

    this.isOpen = true;
  };

  @action onConfirm = async () => {
    if (this.type === TIME_SELECT_MODAL_TYPE.ReAnalysis) {
      await this.onReAnalysis();
    }

    if (this.type === TIME_SELECT_MODAL_TYPE.Download) {
      await this.onDownload();
    }

    this.parent.onRefresh();

    this.onClose();
  };

  @action onDateSelect = (date) => {
    this.selectedDay = date;
  };

  @action onReAnalysis = async () => {
    if (mainStore.checkIsInQueue('reanalysis')) {
      return;
    }
    mainStore.setLoading('reanalysis');
    try {
      if (!this.targetNewsletterId) {
        return;
      }

      await EDMService.reanalysisNewsletter(
        this.targetNewsletterId,
        {
          gte: dayjs(this.selectedDay[0]).tz('Asia/Taipei').startOf('day').toISOString(),
          lte: dayjs(this.selectedDay[1]).tz('Asia/Taipei').endOf('day').toISOString()
        }
      );
      await EDMService.updateResultTopicsByTime(
        this.targetNewsletterId,
        {
          gte: dayjs(this.selectedDay[0]).tz('Asia/Taipei').startOf('day').toISOString(),
          lte: dayjs(this.selectedDay[1]).tz('Asia/Taipei').endOf('day').toISOString()
        }
      );

      message.success(i18n.t('edm_list_table_action_re-analysis_message_success'));
    } catch (error) {
      message.error(i18n.t('edm_list_table_action_re-analysis_message_error'));
    } finally {
      mainStore.setLoadingComplete('reanalysis');
    }
  };

  @action onDownload = async () => {
    try {
      if (mainStore.checkIsInQueue('download')) {
        return;
      }
      mainStore.setLoading('download');
      const [{ list }, _] = await Promise.all([
        EDMService.getDownloadTopic(
          this.targetNewsletterId,
          {
            gte: dayjs(this.selectedDay[0]).tz('Asia/Taipei').startOf('day').toISOString(),
            lte: dayjs(this.selectedDay[1]).tz('Asia/Taipei').endOf('day').toISOString()
          }
        ),
        this.getNewsletterDetail()
      ]);

      await this.updateSearchAngle();



      const formattedList = this.formattedTopics(list);


      const wordbook = xlsx.utils.book_new();
      formattedList.forEach((subject, index) => {
        const data = [...subject.post];
        let row = TOPICS_CSV_TITLE_ROW;
        if (this.searchAngle !== 'brand') {
          row[2].header = i18n.t('common_brand_category');
        }
        if (subject.type !== 'withBrand') {
          row = row.filter((item) => item.key !== 'brand');
        }
        const header = row.map((key) => key.key);
        const worksheet = xlsx.utils.json_to_sheet(data, { header });
        xlsx.utils.sheet_add_aoa(worksheet, [row.map((key) => key.header)], { origin: 'A1' });
        worksheet['!cols'] = header.map((__, i) => ({ width: i === 1 || i === 15 ? 100 : 15 }));
        xlsx.utils.book_append_sheet(wordbook, worksheet, `${index + 1}.${subject.name?.slice(0, 27)}`);
      });
      xlsx.writeFile(wordbook, `${this.name}_${dayjs(this.selectedDay[0]).tz('Asia/Taipei').format('YYYY-MM-DD')}-${dayjs(this.selectedDay[1]).tz('Asia/Taipei').format('YYYY-MM-DD')}_話題列表.xlsx`);
      message.success(i18n.t('download_success'));

    } catch (error) {
      console.log('error', error);
      message.error(i18n.t('download_fail'));
    } finally {
      mainStore.setLoadingComplete('download');
    }
  };

  formattedTopics = (list) => {
    return list.map((subject) => ({
      id: subject.id,
      name: (this.subjectList.find((s) => s.id === subject.id)?.name ?? '電子報主題').split('/').join('-'),
      type: (this.subjectList.find((s) => s.id === subject.id)?.type ?? 'withoutBrand'),
      post: subject.post.map((postItem) => ({
        ...postItem,
        evalType: this.ratingTags[postItem.evalType] ?? '無',
        topicAttr: this.topicTags[postItem.topicAttr] ?? '無',
        reportAdvice: this.suggestionTags[postItem.reportAdvice] ?? '無',
        departmentAttr: this.departmentTags[postItem.departmentAttr] ?? '無'
      }))
    }));
  };

  @action updateSearchAngle = async () => {
    try {
      if (mainStore.checkIsInQueue('searchAngle')) {
        return;
      }
      mainStore.setLoading('searchAngle');

      const { meta: { searchAngle } } = await ProjectService.getProjectDetailById(this.projectId);

      runInAction(() => {
        this.searchAngle = searchAngle;
      });

    } catch (error) {
      message.error(i18n.t('api_get_project_angle_error'));
    } finally {
      mainStore.setLoadingComplete('searchAngle');
    }
  };

  @action getNewsletterDetail = async () => {
    try {
      if (mainStore.checkIsInQueue('getDetail')) {
        return;
      }
      mainStore.setLoading('getDetail');
      const { name, projectId, topics, tags } = await EDMService.getEDMDetail(this.targetNewsletterId);

      runInAction(() => {
        this.name = name;
        this.projectId = projectId;
        this.tagPool = tags.map((tag) => ({ value: tag.id, label: tag.value, type: tag.type }));
      });

      const subjectList = await Promise.all([
        ...topics.map((item) => this.updateSubjectContent(item))
      ]);
      runInAction(() => {
        this.subjectList = subjectList;
      });

    } catch (error) {
      message.error(i18n.t('api_get_edm_detail_error'));
    } finally {
      mainStore.setLoadingComplete('getDetail');
    }
  };

  @action updateSubjectContent = async (item) => {
    if (mainStore.checkIsInQueue(`${item.id}GetDetail`)) {
      return {};
    }
    mainStore.setLoading(`${item.id}GetDetail`);
    const {
      id,
      searchKeywordLevel1Id,
      searchKeywordLevel2Id,
      searchKeywordLevel3Id,
      searchTagParentId,
      searchTagId,
      category
    } = item;

    const sourceText = !category[0]
      ? ''
      : `-${SOURCE_CATEGORY_TC[category[0]]}`;


    let name = '';
    const type = searchTagParentId ? 'withBrand' : 'withoutBrand';
    try {

      if (searchKeywordLevel1Id && searchKeywordLevel2Id) {
        const [brand, productLine] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id, searchKeywordLevel2Id]);
        name = `${brand.name}/${productLine.name}${sourceText}`;
      }

      if (searchKeywordLevel1Id && searchKeywordLevel3Id) {
        const [brand, product] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id, searchKeywordLevel3Id]);
        name = `${brand.name}/${product.name}${sourceText}`;
      }

      if (searchKeywordLevel1Id && !searchKeywordLevel2Id && !searchKeywordLevel3Id) {
        const [brand] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id]);
        name = `${brand.name}${sourceText}`;
      }

      if (searchTagParentId) {
        let brandName = '';
        if (searchKeywordLevel1Id) {
          const [brand] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id]);
          brandName = brand.name;
        }
        const [tagParent, tag] = await ProjectService.getTagContentByIds(this.projectId, [searchTagParentId, searchTagId]);
        name = `${brandName.trim() ? `${brandName}/` : ''}${tagParent.name}/${tag.name}${sourceText}`;
      }

      return {
        id,
        name,
        type
      };

    } catch (error) {
      return {
        id,
        name: i18n.t('common_error_subject_title'),
        type
      };
    } finally {
      mainStore.setLoadingComplete(`${item.id}GetDetail`);
    }
  };

  @action onClose = () => {
    this.type = null;
    this.targetNewsletterId = null;
    this.selectedDay = [null, null];
    this.availableDate = [dayjs(), dayjs()];

    this.isOpen = false;
  };
}
