import { SOURCE_CATEGORY_TC } from 'src/constants/normal';

export default class SourceModel {
  constructor(data) {
    this.source = data?.map((item) => ({
      id: item.category,
      name: SOURCE_CATEGORY_TC[item.category],
      children: item.website?.map((website) => ({
        id: website.name,
        name: website.name,
        children: website.channel?.map((channel) => ({
          id: channel.id,
          name: channel.name
        })) ?? []
      })) ?? []
    })) ?? [];
  }

  static fromRes(data) {
    return new SourceModel(data);
  }
}
