import * as routes from './routes';
import * as normal from './normal';
import * as table from './table';
import * as config from './config';

export default {
  routes,
  normal,
  table,
  config
};
