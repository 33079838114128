import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Modal, Button, Select, Switch } from 'antd';
import { CloseOutlined, CaretDownOutlined } from '@ant-design/icons';

import i18n from 'src/i18n';
import Arrow from 'src/assets/common/arrow.svg';
import { LEVEL_TYPE, SOURCE_TYPE_OPTIONS } from 'src/constants/normal';

import FilledButton from 'src/components/CustomizeButton/FilledButton';
import NoBorderButton from 'src/components/CustomizeButton/NoboderButton';

import SourceSelect from './SourceSelect';


import styles from './styles.module.scss';

@observer
class TopicModal extends React.Component {

  render() {
    const { viewModel } = this.props;
    return (
      <Modal
        open={viewModel.isOpen}
        centered
        footer={null}
        closeIcon={null}
        width="100%"
        styles={{ content: { padding: 0 } }}
        onCancel={viewModel.onModalClose}
        keyboard
        className={styles.modal}
      >
        <div className={styles.container}>
          <div className={styles.containerHeader}>
            <div className={styles.modalTitle}>
              <img src={Arrow} alt="" />
              <div className={styles.titleText}>
                {i18n.t('edm_setting_modal_topic_setting_title')}
              </div>
            </div>
            <NoBorderButton
              icon={<CloseOutlined />}
              onClick={viewModel.onModalClose}
            >
              {i18n.t('common_close')}
            </NoBorderButton>
          </div>

          <div className={styles.content}>
            <div className={styles.row}>
              <div className={styles.rowTitle}>
                {i18n.t('edm_setting_modal_topic_setting_open_edit')}
              </div>
              <div className={styles.rowContent}>
                <Switch
                  className={styles.switch}
                  onChange={viewModel.onHasEvaluationChange}
                  checked={viewModel.hasEvaluation}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.rowTitle}>
                {i18n.t('edm_setting_modal_topic_setting_select_level')}
              </div>
              <div className={styles.rowContent}>
                {
                  viewModel.levelOptions.map((level) => {
                    return (
                      <Button
                        className={clsx(styles.normalBtn, (viewModel.selectedLevel !== level.value && !!viewModel.selectedLevel) && styles.inActive)}
                        onClick={() => viewModel.onLevelSelect(level.value)}
                        key={level.value}
                      >
                        {level.label}
                      </Button>
                    );
                  })
                }
              </div>
            </div>
            <div className={styles.row}>
              <div className={clsx(styles.rowTitle, !viewModel.selectedLevel && styles.disabled)}>
                {i18n.t('edm_drawer_topic_prefix')}
              </div>
              <div className={clsx(styles.rowContent, styles.twoBlock)}>
                <Select
                  className={clsx(styles.select, !viewModel.selectedLevel && styles.disabled)}
                  suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                  placeholder={viewModel.conditionOnePlaceholder}
                  disabled={!viewModel.selectedLevel}
                  showSearch
                  options={viewModel.conditionOneList}
                  value={viewModel.selectedConditionOne}
                  onChange={viewModel.onConditionOneSelect}
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                />
                {
                  viewModel.selectedLevel !== LEVEL_TYPE.Brand
                  && (
                    <Select
                      className={clsx(styles.select, (!viewModel.selectedLevel || !viewModel.selectedConditionOne) && styles.disabled)}
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      placeholder={viewModel.conditionTwoPlaceholder}
                      disabled={!viewModel.selectedLevel || !viewModel.selectedConditionOne}
                      showSearch
                      options={viewModel.conditionTwoList}
                      value={viewModel.selectedConditionTwo}
                      onChange={viewModel.onConditionTwoSelect}
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    />
                  )
                }
              </div>
            </div>
            {
              viewModel.selectedLevel === 'tag'
              && (
                <div className={styles.row}>
                  <div className={clsx(styles.rowTitle, !viewModel.selectedLevel && styles.disabled)}>
                    {
                      viewModel.angle === 'brand'
                        ? i18n.t('edm_setting_modal_topic_setting_brand_select_all')
                        : i18n.t('edm_setting_modal_topic_setting_brand_category_select_all')
                    }
                  </div>
                  <div className={clsx(styles.rowContent, styles.autoAndRest)}>
                    <Switch
                      className={styles.switch}
                      onChange={viewModel.onToggleTagAllLevelOne}
                      checked={viewModel.selectedAllLevelOne}
                    />
                    {
                      !viewModel.selectedAllLevelOne
                      && (
                        <Select
                          className={clsx(styles.select, !viewModel.selectedLevel && styles.disabled)}
                          suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                          placeholder={viewModel.tagLevelOnePlaceHolder}
                          disabled={!viewModel.selectedLevel}
                          showSearch
                          options={viewModel.tagLevelOneList}
                          value={viewModel.selectedTagLevelOne}
                          onChange={viewModel.onTagLevelOneChange}
                          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                        />
                      )
                    }
                  </div>
                </div>
              )
            }

            <div className={styles.row}>
              <div className={clsx(styles.rowTitle, !viewModel.selectedLevel && styles.disabled)}>
                {i18n.t('edm_setting_modal_topic_setting_source_title')}
              </div>
              <div className={clsx(styles.rowContent, styles.oneBlock)}>
                <Select
                  className={clsx(styles.select, !viewModel.selectedLevel && styles.disabled)}
                  suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                  placeholder={i18n.t('edm_setting_modal_topic_setting_source_placeholder')}
                  options={SOURCE_TYPE_OPTIONS}
                  value={viewModel.selectedSourceType}
                  onChange={viewModel.onSourceTypeChange}
                  allowClear
                  disabled={!viewModel.selectedLevel}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div />
              <div className={clsx(styles.rowContent, styles.oneBlock)}>
                {
                  viewModel.selectedSourceType
                    ? (
                      <SourceSelect viewModel={viewModel.sourceSelectViewModel} />
                    )
                    : (
                      <div className={styles.noteContainer}>
                        {i18n.t('edm_setting_modal_topic_setting_source_block_note')}
                      </div>
                    )
                }
              </div>
            </div>
          </div>

          <div className={styles.footer}>
            <FilledButton
              disabled={!viewModel.canSubmit}
              onClick={viewModel.onSubmit}
            >
              {i18n.t('common_apply')}
            </FilledButton>
          </div>
        </div>
      </Modal>
    );
  }
}

TopicModal.propTypes = {
  viewModel: PropTypes.object
};

TopicModal.defaultProps = {
  viewModel: {}
};

export default TopicModal;
