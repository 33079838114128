import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Modal, Button, Input } from 'antd';
import { TIME_SELECT_MODAL_TYPE } from 'src/constants/normal';

import i18n from 'src/i18n';

import styles from './styles.module.scss';


@observer
class DeleteModal extends React.Component {
  constructor() {
    super();

  }

  render() {
    const { viewModel } = this.props;
    return (
      <Modal
        open={viewModel.isOpen}
        centered
        footer={null}
        closeIcon={null}
        width={500}
        onCancel={viewModel.onClose}
        styles={{ content: { padding: 0 } }}
      >
        <div className={styles.container}>
          <div className={styles.title}>
            {
              i18n.t('edm_list_table_action_delete_title')
                .replace('%name%', `{${viewModel.newsletterName}}`)
            }
          </div>
          <div className={styles.contentText}>
            <div>
              {i18n.t('edm_list_table_action_delete_desc')}
            </div>
            <div>
              <span>
                {i18n.t('edm_list_table_action_delete_confirm_desc').replace('%name%', `{${viewModel.newsletterName}}`)}
              </span>
              <span className={styles.red}>
                {i18n.t('edm_list_table_action_delete_confirm_note')}
              </span>
              。
            </div>
          </div>
          <div className={styles.content}>
            <div>
              {i18n.t('edm_list_table_action_delete_input_title')}
            </div>
            <Input
              value={viewModel.input}
              onChange={viewModel.onChange}
              onKeyDown={viewModel.onKeydown}
            />
          </div>
          <div className={styles.footer}>
            <Button
              className={styles.confirmBtn}
              onClick={viewModel.onConfirm}
            >
              {i18n.t('common_confirm')}
            </Button>
            <Button
              className={styles.cancelBtn}
              onClick={viewModel.onClose}
            >
              {i18n.t('common_cancel')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

DeleteModal.propTypes = {
  viewModel: PropTypes.object.isRequired
};

DeleteModal.defaultProps = {
  // viewModel: {}
};

export default DeleteModal;
