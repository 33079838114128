import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { validate } from 'uuid';
import { message } from 'antd';

import i18n from 'src/i18n';
import { LEVEL_OPTIONS, LEVEL_OPTIONS_CATEGORY_ANGLE, LEVEL_TYPE, TAG_ACTIONS, SOURCE_TYPE } from 'src/constants/normal';
import ProjectService from 'src/services/project';
import mainStore from 'src/stores/mainStore';

import SourceSelectViewModel from './SourceSelect/viewModel';

export default class TopicModalViewModel {
  parent = null;

  id = null;
  projectId = '';
  @observable angle = 'brand';
  action = '';

  @observable isOpen = false;
  @observable hasEvaluation = true;
  @observable selectedLevel = null;

  @observable conditionOneList = [];
  @observable conditionTwoList = [];
  @observable tagLevelOneList = [];
  /**
   * @param id string
   * @param name string
   * @param isInit boolean
   * @param product { id: string, name: string, isInit: boolean }[]
   * @param productLine { id: string, name: string, isInit: boolean }[]
   */
  brand = null; // > maybe should change to level1 level2 level3
  /**
   * @param id string
   * @param name string
   * @param isInit: boolean
   * @param tags { id: string, name: string, isInit: boolean }[]
   */
  tag = null;

  @observable selectedConditionOne = null;
  @observable selectedConditionTwo = null;
  @observable selectedAllLevelOne = true;
  @observable selectedTagLevelOne = null;

  @observable selectedSourceType = null;

  @observable sourceSelectViewModel = new SourceSelectViewModel(this);

  editContent = {};

  @computed get levelOptions() {
    return this.angle === 'brand' ? LEVEL_OPTIONS : LEVEL_OPTIONS_CATEGORY_ANGLE;
  }

  @computed get conditionOnePlaceholder() {
    switch (this.selectedLevel) {
      case 'brand':
      case 'productLine':
      case 'product':
        return this.angle === 'brand' ? i18n.t('edm_setting_modal_topic_setting_brand_placeholder') : i18n.t('edm_setting_modal_topic_setting_brand_category_placeholder');
      case 'tag':
        return i18n.t('edm_setting_modal_topic_setting_tag_placeholder');
      default:
        return i18n.t('common_select_placeholder');
    }
  }

  @computed get conditionTwoPlaceholder() {
    switch (this.selectedLevel) {
      case 'productLine':
        return this.angle === 'brand' ? i18n.t('edm_setting_modal_topic_setting_product_line_placeholder') : i18n.t('edm_setting_modal_topic_setting_brand_placeholder');
      case 'product':
        return i18n.t('edm_setting_modal_topic_setting_product_placeholder');
      case 'tag':
        return i18n.t('edm_setting_modal_topic_setting_sub_tag_placeholder');
      case 'brand':
      default:
        return i18n.t('common_select_placeholder');
    }
  }

  @computed get tagLevelOnePlaceHolder() {
    return this.angle === 'brand' ? i18n.t('edm_setting_modal_topic_setting_brand_placeholder') : i18n.t('edm_setting_modal_topic_setting_brand_category_placeholder');
  }

  @computed get showCategory() {
    return this.source.map((el) => ({
      title: el.name,
      key: el.id
    }));
  }

  @computed get canSubmit() {
    switch (this.selectedLevel) {
      case 'productLine':
      case 'product':
      case 'tag':
        return this.selectedConditionOne && this.selectedConditionTwo;
      case 'brand':
        return !!this.selectedConditionOne;
      default:
        return false;
    }
  }

  constructor(parent) {
    makeObservable(this);

    this.init(parent);
  }

  @action init = (parent) => {
    this.parent = parent;
  };

  @action updateProjectId = (projectId, angle) => {
    this.projectId = projectId;
    this.angle = angle;

    this.brand = null;
    this.tag = null;
    this.selectedLevel = null;
    this.conditionOneList = [];
    this.conditionTwoList = [];
    this.selectedConditionOne = null;
    this.selectedConditionTwo = null;
    this.selectedSourceType = null;

    this.sourceSelectViewModel.updateProjectId(projectId);
  };

  @action onOpen = () => {
    this.onCleanContent();
    this.onModalOpen();
  };

  @action onCleanContent = () => {
    this.id = null;
    this.hasEvaluation = true;
    this.selectedLevel = null;
    this.conditionOneList = [];
    this.conditionTwoList = [];
    this.selectedConditionOne = null;
    this.selectedConditionTwo = null;
    this.selectedSourceType = null;

    this.sourceSelectViewModel.cleanData();
  };

  @action onEditContentOpen = async (content) => {
    this.editContent = content;
    const {
      id,
      hasEvaluation,
      level,
      level1,
      level2,
      level3,
      tagParent,
      tag,
      category,
      website,
      channel
    } = content;

    this.id = id;
    this.hasEvaluation = hasEvaluation;
    this.category = category;
    this.website = website;
    this.channel = channel;

    if (this.channel.length > 0) {
      this.selectedSourceType = SOURCE_TYPE.Channel;
    } else if (this.website.length > 0) {
      this.selectedSourceType = SOURCE_TYPE.Website;
    } else if (this.category[0]) {
      this.selectedSourceType = SOURCE_TYPE.Category;
    } else {
      this.selectedSourceType = null;
    }

    await this.onLevelSelect(level);
    switch (level) {
      case LEVEL_TYPE.Brand:
        await this.onConditionOneSelect(level1.id);
        break;
      case LEVEL_TYPE.ProductLine:
        await this.onConditionOneSelect(level1.id);
        this.onConditionTwoSelect(level2.id);
        break;
      case LEVEL_TYPE.Product:
        await this.onConditionOneSelect(level1.id);
        this.onConditionTwoSelect(level3.id);
        break;
      case LEVEL_TYPE.Tag:
        await this.onConditionOneSelect(tagParent.id);
        this.onConditionTwoSelect(tag.id);
        if (level1.id) {
          this.onToggleTagAllLevelOne(false);
          this.onTagLevelOneChange(level1.id);
        } else {
          this.onToggleTagAllLevelOne(true);
        }
        break;
      default:
        break;
    }

    this.sourceSelectViewModel.updateContent({ category, website, channel, type: this.selectedSourceType });

    this.onModalOpen();
  };

  @action onModalOpen = () => {
    this.sourceSelectViewModel.cleanKeyword();
    this.isOpen = true;
  };

  @action onModalClose = () => {
    this.isOpen = false;
  };

  @action onHasEvaluationChange = (boolean) => {
    this.hasEvaluation = boolean;
  };

  @action onLevelSelect = async (level) => {
    this.selectedLevel = level;

    this.selectedConditionOne = null;
    this.selectedConditionTwo = null;
    this.conditionTwoList = [];

    switch (this.selectedLevel) {
      case 'brand':
      case 'productLine':
      case 'product':
        if (!this.brand) {
          await this.getBrandList();
        }
        runInAction(() => {
          this.conditionOneList = this.brand.map((brand) => ({ value: brand.id, label: brand.name }));
        });
        break;
      case 'tag':
        if (!this.tag) {
          await this.getTagList();
        }
        if (!this.brand) {
          await this.getBrandList();
        }
        runInAction(() => {
          this.conditionOneList = this.tag.map((tag) => ({ value: tag.id, label: tag.name }));
          this.tagLevelOneList = this.brand.map((brand) => ({ value: brand.id, label: brand.name }));
          this.selectedAllLevelOne = true;
          this.selectedTagLevelOne = null;
        });
        break;
      default:
        this.conditionOneList = [];
        break;
    }
  };

  @action getBrandList = async () => {
    try {
      if (mainStore.checkIsInQueue('levelOneList')) {
        return;
      }
      mainStore.setLoading('levelOneList');

      const { brand } = await ProjectService.getBrandByProject(this.projectId);
      runInAction(() => {
        this.brand = brand.map((el) => ({
          id: el.value,
          name: el.label,
          isInit: true,
          product: null,
          productLint: null
        }));
      });
    } catch (error) {
      message.error(i18n.t('api_get_project_level_one_list_error'));
    } finally {
      mainStore.setLoadingComplete('levelOneList');
    }
  };

  @action updateConditionTwoByProductLine = async () => {
    const targetBrand = this.brand.find((brand) => brand.id === this.selectedConditionOne);
    if (!targetBrand) {
      message.error(i18n.t('api_level_one_not_found_error'));
      return;
    }

    if (!targetBrand.productLine) {
      await this.getProductLineList();
    }
    runInAction(() => {
      this.conditionTwoList = targetBrand.productLine.map((line) => ({ value: line.id, label: line.name }));
    });
  };

  @action getProductLineList = async () => {
    try {
      if (mainStore.checkIsInQueue('levelTwoList')) {
        return;
      }
      mainStore.setLoading('levelTwoList');

      const { productLine } = await ProjectService.getProductLineByBrandId(this.projectId, this.selectedConditionOne);

      runInAction(() => {
        const targetBrand = this.brand.find((brand) => brand.id === this.selectedConditionOne);
        targetBrand.productLine = productLine.map((line) => ({ id: line.value, name: line.label }));
      });
    } catch (error) {
      message.error(i18n.t('api_get_project_level_two_list_error'));
    } finally {
      mainStore.setLoadingComplete('levelTwoList');
    }
  };

  @action updateConditionTwoByProduct = async () => {
    const targetBrand = this.brand.find((brand) => brand.id === this.selectedConditionOne);
    if (!targetBrand) {
      message.error(i18n.t('api_level_one_not_found_error'));
      return;
    }

    if (!targetBrand.product) {
      await this.getProductList();
    }
    runInAction(() => {
      this.conditionTwoList = targetBrand.product.map((line) => ({ value: line.id, label: line.name }));
    });
  };

  @action getProductList = async () => {
    try {
      if (mainStore.checkIsInQueue('levelThreeList')) {
        return;
      }
      mainStore.setLoading('levelThreeList');

      const { product } = await ProjectService.getProductByBrandId(this.projectId, this.selectedConditionOne);

      runInAction(() => {
        const targetBrand = this.brand.find((brand) => brand.id === this.selectedConditionOne);
        targetBrand.product = product.map((line) => ({ id: line.value, name: line.label }));
      });
    } catch (error) {
      message.error(i18n.t('api_get_project_level_three_list_error'));
    } finally {
      mainStore.setLoadingComplete('levelThreeList');
    }
  };

  @action getTagList = async () => {
    try {
      if (mainStore.checkIsInQueue('tagList')) {
        return;
      }
      mainStore.setLoading('tagList');

      const { searchTag } = await ProjectService.getSearchTagByProjectId(this.projectId);

      runInAction(() => {
        this.tag = [...searchTag];
      });
    } catch (error) {
      message.error(i18n.t('api_get_project_level_tag_list_error'));
    } finally {
      mainStore.setLoadingComplete('tagList');
    }
  };

  @action updateConditionTwoByTag = () => {
    const target = this.tag.find((tag) => tag.id === this.selectedConditionOne);
    if (!target) {
      return;
    }

    this.conditionTwoList = target.children.map((tag) => ({ value: tag.id, label: tag.name }));
  };

  @action onConditionOneSelect = async (value) => {
    this.selectedConditionOne = value;
    this.selectedConditionTwo = null;
    switch (this.selectedLevel) {
      case 'productLine':
        await this.updateConditionTwoByProductLine();
        break;
      case 'product':
        await this.updateConditionTwoByProduct();
        break;
      case 'tag':
        this.updateConditionTwoByTag();
        break;
      default:
        break;
    }
  };

  @action onConditionTwoSelect = (value) => {
    this.selectedConditionTwo = value;
  };

  @action onSourceTypeChange = (value) => {
    this.selectedSourceType = value;
    this.sourceSelectViewModel.updateShowSourceType(value);
  };

  @action onToggleTagAllLevelOne = (boolean) => {
    this.selectedAllLevelOne = boolean;
  };

  @action onTagLevelOneChange = (value) => {
    this.selectedTagLevelOne = value;
  };

  @action onSubmit = () => {
    const topicContent = {
      id: this.id,
      action: this.id && !validate(this.id) ? TAG_ACTIONS.Update : TAG_ACTIONS.Create,
      hasEvaluation: this.hasEvaluation,
      ...(
        ['brand', 'product', 'productLine'].includes(this.selectedLevel)
          ? { searchKeywordLevel1Id: this.selectedConditionOne }
          : {}
      ),
      ...(
        this.selectedLevel === 'productLine'
          ? { searchKeywordLevel2Id: this.selectedConditionTwo }
          : {}
      ),
      ...(
        this.selectedLevel === 'product'
          ? { searchKeywordLevel3Id: this.selectedConditionTwo }
          : {}
      ),
      ...(
        this.selectedLevel === 'tag'
          ? {
            ...(
              this.selectedAllLevelOne
                ? {}
                : {
                  searchKeywordLevel1Id: this.selectedTagLevelOne
                }
            ),
            searchTagParentId: this.selectedConditionOne,
            searchTagId: this.selectedConditionTwo
          }
          : {}
      ),
      category: this.sourceSelectViewModel.selectedCategory ? [this.sourceSelectViewModel.selectedCategory] : [] ?? [],
      website: this.sourceSelectViewModel.selectedWebsites,
      channel: this.sourceSelectViewModel.selectedChannels
    };

    if (!this.id) {
      this.parent.onTopicSubmit(topicContent);
    } else {
      this.editContent.updateContent(topicContent);
    }
    this.onModalClose();
  };
}
