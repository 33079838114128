import dayjs from 'dayjs';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';

import mainStore from 'src/stores/mainStore';

import ErrorModalViewModel from 'src/components/ErrorModal/viewModel';

import i18n from 'src/i18n';
import SSOService from 'src/services/sso';
import EDMService from 'src/services/edm';
import ResultService from 'src/services/result';
import { QUEUE_SCHEDULE_TYPE, TIME_SELECT_MODAL_TYPE, NEWSLETTER_STATUS_TYPE } from 'src/constants/normal';

import TimeSelectModalViewModel from './components/timeSelectModal/viewModel';

export default class NewsletterPageViewModel {
  id = '';
  projectId = '';
  @observable newsletterName = '';
  @observable subject = '';

  @observable selectedDate = [null, null];
  @observable availableDate = [dayjs(), dayjs()];
  @observable selectedType = [QUEUE_SCHEDULE_TYPE.Daily];
  @observable selectedStatus = null;

  @observable ownerList = [];
  @observable collaboratorList = [];
  @observable selectedSender = null;

  @observable letterList = [];
  @observable anchor = null;
  @observable totalCount = 0;

  // ? resize related
  resizeObserver = null;
  @observable width = 1920;
  @observable height = 1080;

  timeSelectModalViewModel = new TimeSelectModalViewModel();
  errorModalViewModel = new ErrorModalViewModel(i18n.t('result_page_status_check_fail_project'));

  @computed get showClean() {
    return this.selectedDate[0] || this.selectedDate[1] || !(this.selectedType.length === 1 && this.selectedType[0] === QUEUE_SCHEDULE_TYPE.Daily) || this.selectedStatus || this.selectedSender;
  }

  @computed get senderList() {
    const list = [{
      value: 'SYSTEM',
      label: i18n.t('result_page_table_latest_sender_system')
    }];
    const ownerSet = new Set([...this.ownerList.map((user) => user.value)]);
    this.collaboratorList.forEach((user) => {
      if (!ownerSet.has(user.value)) {
        list.push(user);
      }
    });
    list.push(...this.ownerList);
    return list;
  }

  constructor() {
    makeObservable(this);
  }

  findSender = (value) => {
    return this.senderList.find((user) => user.value === value)?.label ?? '不明寄件者';
  };

  @action didMount = async (id) => {
    this.id = id;

    this.registerObserver();

    await Promise.all([
      this.getList(),
      this.getNewsletterDetail(),
      this.getPersonInChargeList(),
      this.getCollaboratorList()
    ]);
  };

  @action registerObserver = () => {
    const resizeObserver = new ResizeObserver((entries) => {
      const { height, width } = entries[0].contentRect;
      runInAction(() => {
        this.width = width;
        this.height = height;
      });
    });
    this.resizeObserver = resizeObserver;
    const container = document.querySelector('#app');
    this.resizeObserver.observe(container);
  };

  @action willUnmount = () => {
    const container = document.querySelector('#app');
    this.resizeObserver.unobserve(container);
  };

  @action getList = async () => {
    try {
      if (mainStore.checkIsInQueue('resultList')) {
        return;
      }
      mainStore.setLoading('resultList');
      this.isLoadingResult = true;
      const params = {
        ...(this.anchor && { anchor: this.anchor }),
        ...(this.selectedType && { type: this.selectedType }),
        ...(this.selectedDate[0] && { startDate: this.selectedDate[0].startOf('day').toISOString() }),
        ...(this.selectedDate[1] && { endDate: this.selectedDate[1].endOf('day').toISOString() }),
        ...(this.selectedSender && { sender: this.selectedSender })
      };

      const { list, anchor, count } = await EDMService.getNewsLetterResult(this.id, params);

      const statusList = await Promise.all(
        list.map((item) => EDMService.getEDMStatusByTime(this.id, { gte: item.startDate, lte: item.endDate }).catch(() => {
          return {};
        }))
      );

      const formatList = list.map((timeSection, index) => ({
        ...timeSection,
        ...statusList[index]
      }));

      runInAction(() => {
        this.letterList = [...this.letterList, ...formatList];
        this.anchor = anchor;
        if (count) {
          this.totalCount = count;
        }
      });

    } catch (error) {
      message.error(i18n.t('api_get_edm_result_list_error'));
    } finally {
      mainStore.setLoadingComplete('resultList');
    }
  };

  @action getNewsletterDetail = async () => {
    try {
      if (mainStore.checkIsInQueue('newsletterDetail')) {
        return;
      }
      mainStore.setLoading('newsletterDetail');
      const {
        name,
        subject,
        projectId,
        emailStartDate,
        emailEndDate
      } = await EDMService.getEDMDetail(this.id);

      runInAction(() => {
        this.projectId = projectId;
        this.newsletterName = name;
        this.subject = subject;
        this.availableDate = [dayjs(emailStartDate).startOf('day'), dayjs(emailEndDate).endOf('day')];
      });

    } catch (error) {
      message.error(i18n.t('api_get_edm_detail_error'));
    } finally {
      mainStore.setLoadingComplete('newsletterDetail');
    }
  };

  @action getPersonInChargeList = async () => {
    try {
      if (mainStore.checkIsInQueue('owner')) {
        return;
      }
      mainStore.setLoading('owner');
      const { list } = await SSOService.getOwners();

      runInAction(() => {
        this.ownerList = list.map((user) => ({ value: user.value, label: `(${i18n.t('common_owner')})${user.label}` }));
      });
    } catch (error) {
      message.error(i18n.t('api_get_owner_error'));
    } finally {
      mainStore.setLoadingComplete('owner');
    }
  };

  @action getCollaboratorList = async () => {
    try {
      if (mainStore.checkIsInQueue('collaborator')) {
        return;
      }
      mainStore.setLoading('collaborator');
      const { list } = await SSOService.getCollaborators();

      runInAction(() => {
        this.collaboratorList = list.map((user) => ({ value: user.value, label: `(${i18n.t('common_collaborator')})${user.label}` }));
      });
    } catch (error) {
      message.error(i18n.t('api_get_collaborator_error'));
    } finally {
      mainStore.setLoadingComplete('collaborator');
    }
  };

  onCheckStatus = async (date) => {
    if (mainStore.checkIsInQueue('checkStatus')) {
      return false;
    }
    try {
      mainStore.setLoading('checkStatus');
      const { edmAuto, edmManual } = await EDMService.getEDMStatusByTime(this.id, date);
      const newsletterAutoStatus = [NEWSLETTER_STATUS_TYPE.Done, NEWSLETTER_STATUS_TYPE.Fail].includes(edmAuto?.stage);
      const newsletterManualStatus = [NEWSLETTER_STATUS_TYPE.Done, NEWSLETTER_STATUS_TYPE.Fail].includes(edmManual?.stage);
      return newsletterAutoStatus && newsletterManualStatus;


    } catch (error) {
      return false;
    } finally {
      mainStore.setLoadingComplete('checkStatus');
    }
  };


  @action onTypeChange = (value) => {
    this.selectedType = value;
  };

  @action onDateChange = (date) => {
    this.selectedDate = date;
  };

  @action onStatusSelect = (value) => {
    this.selectedStatus = value;
  };

  @action onSenderSelect = (value) => {
    this.selectedSender = value;
  };

  @action onSubmit = () => {
    this.anchor = null;
    this.letterList = [];

    this.getList();
  };

  @action onClean = () => {
    this.selectedDate = [null, null];
    this.selectedType = [QUEUE_SCHEDULE_TYPE.Daily];
    this.selectedStatus = null;
    this.selectedSender = null;

    this.anchor = null;
    this.letterList = [];

    this.getList();
  };

  onEditClick = async (target, router) => {
    const canContinue = await this.onCheckStatus({ gte: target.startDate, lte: target.endDate });
    if (!canContinue) {
      this.errorModalViewModel.onOpen();
      return;
    }
    router.navigate(`/newsletter/${this.id}/result/${target.id}`);
    this.updateLatestActionTime(target.id);
  };

  onPreviewClick = async (target) => {
    const canContinue = await this.onCheckStatus({ gte: target.startDate, lte: target.endDate });
    if (!canContinue) {
      this.errorModalViewModel.onOpen();
      return;
    }
    window.open(`/newsletter/${this.id}/result/${target.id}/preview`, '_blank');
    this.updateLatestActionTime(target.id);
  };

  onDownloadClick = async (time, startDate, endDate, resultId) => {
    const canContinue = await this.onCheckStatus({ gte: startDate, lte: endDate });
    if (!canContinue) {
      this.errorModalViewModel.onOpen();
      return;
    }
    this.timeSelectModalViewModel.onOpen(this.id, resultId, { gte: startDate, lte: endDate }, time, TIME_SELECT_MODAL_TYPE.Download);
  };

  onReAnalysisClick = async (time, startDate, endDate) => {
    const canContinue = await this.onCheckStatus({ gte: startDate, lte: endDate });
    if (!canContinue) {
      this.errorModalViewModel.onOpen();
      return;
    }
    this.timeSelectModalViewModel.onOpen(this.id, null, { gte: startDate, lte: endDate }, time, TIME_SELECT_MODAL_TYPE.ReAnalysis);
  };

  // > no await for update time.
  updateLatestActionTime = (id) => {
    try {
      ResultService.updateLatestActionTime(this.id, id);

    } catch (error) {
      // console.log('error', error);
    }
  };
}
