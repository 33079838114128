import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Input, Checkbox, Tree, Empty } from 'antd';
import { Virtuoso } from 'react-virtuoso';

import SearchIcon from 'src/assets/common/search.svg';
import { SOURCE_TYPE } from 'src/constants/normal';

import i18n from 'src/i18n';

import styles from './styles.module.scss';

@observer
class SourceSelect extends React.Component {
  _renderCategory = (item) => {
    if (!item) {
      return <div />;
    }
    const { viewModel } = this.props;

    return (
      <Observer>
        {() => {
          return (
            <div className={styles.levelItem}>
              <Checkbox
                checked={viewModel.selectedCategory === item.id}
                onChange={(e) => viewModel.onCategorySelect(e.target.checked, item.id)}
              />
              <div className={styles.levelName}>
                {item.name}
              </div>
            </div>
          );
        }}
      </Observer>
    );
  };

  _renderWebsite = (item) => {
    const { viewModel } = this.props;
    if (!item) {
      return <div />;
    }
    return (
      <Observer>
        {() => {
          return (
            <div className={styles.levelItem}>
              <Checkbox
                checked={viewModel.selectedWebsites.includes(item.id)}
                onChange={(e) => viewModel.onWebsiteSelect(e.target.checked, item.id)}
              />
              <div className={styles.levelName}>
                {item.name}
              </div>
            </div>
          );
        }}
      </Observer>
    );
  };

  render() {
    const { viewModel } = this.props;
    return (
      <div className={clsx(styles.sourceContainer)}>
        <div className={styles.sourceItem}>
          <div className={styles.title}>
            <div className={styles.sourceTitle}>
              {i18n.t('edm_setting_modal_topic_setting_category')}
            </div>
          </div>
          <div className={styles.sourceContent}>
            <div className={styles.searchContent}>
              <Input
                className={styles.input}
                placeholder={i18n.t('edm_setting_modal_topic_setting_search_placeholder')}
                value={viewModel.categoryKeyword}
                onPressEnter={viewModel.onCategoryKeywordSubmit}
                onChange={viewModel.onCategoryKeywordChange}
              />
              <div
                className={styles.searchBtn}
                onClick={viewModel.onCategoryKeywordChange}
              >
                <img src={SearchIcon} alt="" />
              </div>
            </div>
            <div className={styles.treeContainer}>
              <Virtuoso
                data={viewModel.searchCategory}
                style={{ height: 188 }}
                initialItemCount={6}
                overscan={20}
                fixedItemHeight={32}
                totalCount={viewModel.searchCategory.length}
                itemContent={(index, item) => this._renderCategory(item)}
              />
            </div>
          </div>
        </div>
        {
          (viewModel.showSourceType === SOURCE_TYPE.Website || viewModel.showSourceType === SOURCE_TYPE.Channel)
          && (
            <div className={styles.sourceItem}>
              <div className={styles.title}>
                <div className={styles.sourceTitle}>
                  {i18n.t('edm_setting_modal_topic_setting_website')}
                </div>
                {
                  viewModel.selectedCategory
                  && (
                    <div className={styles.selectAllCheckbox}>
                      <Checkbox
                        onChange={viewModel.onSelectAllWebsites}
                        indeterminate={viewModel.websiteSelectedIndeterminate}
                        checked={viewModel.websiteSelectedAll}
                      />
                      <div className={styles.selectAllText}>
                        {i18n.t('edm_setting_modal_topic_setting_select_all')}
                      </div>
                    </div>
                  )
                }
              </div>
              <div className={styles.sourceContent}>
                <div className={styles.searchContent}>
                  <Input
                    className={styles.input}
                    placeholder={i18n.t('edm_setting_modal_topic_setting_search_placeholder')}
                    value={viewModel.websiteKeyword}
                    onPressEnter={viewModel.onWebsiteKeywordSubmit}
                    onChange={viewModel.onWebsiteKeywordChange}
                  />
                  <div
                    className={styles.searchBtn}
                    onClick={viewModel.onWebsiteKeywordSubmit}
                  >
                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
                {
                  viewModel.selectedCategory
                    ? (
                      <div className={styles.treeContainer}>
                        <Virtuoso
                          data={viewModel.showWebsite}
                          style={{ height: 188 }}
                          initialItemCount={6}
                          overscan={20}
                          fixedItemHeight={32}
                          totalCount={viewModel.showWebsite.length}
                          itemContent={(index, item) => this._renderWebsite(item)}
                        />
                      </div>
                    )
                    : (
                      <div className={styles.emptyContainer}>
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={i18n.t('edm_setting_modal_topic_setting_source_alert')}
                        />
                      </div>
                    )
                }

              </div>
            </div>
          )
        }
        {
          (viewModel.showSourceType === SOURCE_TYPE.Channel)
          && (
            <div className={styles.sourceItem}>
              <div className={styles.title}>
                <div className={styles.sourceTitle}>
                  {i18n.t('edm_setting_modal_topic_setting_channel')}
                </div>
                {
                  viewModel.selectedWebsites.length > 0
                  && (
                    <div className={styles.selectAllCheckbox}>
                      <Checkbox
                        onChange={viewModel.onSelectAllChannels}
                        indeterminate={viewModel.channelSelectedIndeterminate}
                        checked={viewModel.channelSelectedAll}
                      />
                      <div className={styles.selectAllText}>
                        {i18n.t('edm_setting_modal_topic_setting_select_all')}
                      </div>
                    </div>
                  )
                }
              </div>
              <div className={styles.sourceContent}>
                <div className={styles.searchContent}>
                  <Input
                    className={styles.input}
                    placeholder={i18n.t('edm_setting_modal_topic_setting_search_placeholder')}
                    value={viewModel.channelKeyword}
                    onPressEnter={viewModel.onChannelKeywordSubmit}
                    onChange={viewModel.onChannelKeywordChange}
                  />
                  <div
                    className={styles.searchBtn}
                    onClick={viewModel.onChannelKeywordSubmit}
                  >
                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
                {
                  viewModel.selectedWebsites.length > 0
                    ? (
                      <div className={styles.treeContainer}>
                        <Tree
                          treeData={viewModel.showChannel}
                          height={188}
                          checkable
                          selectable={false}
                          onCheck={viewModel.onChannelChecked}
                          checkedKeys={viewModel.availableChannelKeys}
                          defaultExpandAll
                        />
                      </div>
                    )
                    : (
                      <div className={styles.emptyContainer}>
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={i18n.t('edm_setting_modal_topic_setting_source_alert')}
                        />
                      </div>
                    )
                }

              </div>
            </div>
          )
        }
      </div>
    );
  }
}

SourceSelect.propTypes = {
  viewModel: PropTypes.object.isRequired
};

SourceSelect.defaultProps = {
};

export default SourceSelect;
