import { useState, useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import AuthorizationService from 'src/services/authorization';

export default function useMaintenance() {
  const [start, setStartTime] = useState();
  const [end, setEndTime] = useState();

  const checkMaintenance = useCallback(async () => {
    const { maintenanceStart, maintenanceEnd } = await AuthorizationService.checkMaintenance();
    const now = dayjs().tz('Asia/Taipei').valueOf();
    const startTimeValue = dayjs.tz(maintenanceStart, 'Asia/Taipei').valueOf();
    const endTimeValue = dayjs.tz(maintenanceEnd, 'Asia/Taipei').valueOf();
    if (now >= startTimeValue && now <= endTimeValue) {
      setStartTime(maintenanceStart);
      setEndTime(maintenanceEnd);
    } else {
      window.location.href = '/';
    }
  }, []);

  useEffect(() => {
    checkMaintenance();
  }, [checkMaintenance]);


  return { start, end };
}
