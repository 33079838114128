import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table, Input, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Waypoint } from 'react-waypoint';

import i18n from 'src/i18n';
import constants from 'src/constants';

import SearchIcon from 'src/assets/common/search.svg';
import ErrorModal from 'src/components/ErrorModal';

import TimeSelectModal from './components/timeSelectModal';
import CollaboratorEDMListSectionViewModel from './viewModel';

import styles from './styles.module.scss';
import './customize.scss';

const { EDM_LIST_COLLABORATOR_TABLE, EDM_LIST_KEYS } = constants.table;

@observer
class CollaboratorEDMListSection extends React.Component {
  constructor() {
    super();

    this.viewModel = new CollaboratorEDMListSectionViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount();
  }

  componentWillUnmount() {
    this.viewModel.willUnmount();
  }

  render() {
    return (
      <>
        <div className={styles.contentContainer}>
          <div className={styles.actions}>
            <div className={styles.countText}>
              {i18n.t('edm_list_total_count').replace('%count%', this.viewModel.totalCount)}
            </div>
            <div className={styles.inputContainer}>
              <Input
                placeholder={i18n.t('edm_list_search_placeholder')}
                size="small"
                onChange={this.viewModel.onKeywordChange}
                onKeyDown={this.viewModel.onKeydown}
              />
              <div onClick={this.viewModel.onKeywordSubmit}>
                <img
                  src={SearchIcon}
                  alt=""
                  className={styles.searchIcon}
                />
              </div>
            </div>
          </div>
          <div className={clsx('edmListTableContainer', styles.tableContainer)}>
            <Table
              dataSource={this.viewModel.list}
              className={clsx('edmListTable')}
              rowKey={(data) => data.id}
              scroll={{ y: this.viewModel.height - 355 }} // x: 1920 - 80(outer padding) - 56(inner padding)
              pagination={false}
              style={{ width: this.viewModel.width - 128 }}
            >
              {
                EDM_LIST_COLLABORATOR_TABLE.map((column) => {
                  return (
                    <Table.Column
                      title={column.title}
                      dataIndex={column.dataIndex}
                      key={column.key}
                      width={column.width}
                      fixed={column.fixed}
                      render={(text, record, index) => {
                        if (column.key === EDM_LIST_KEYS.Name) {
                          return (
                            <div className={styles.nameRow}>
                              <Tooltip
                                title={(
                                  <div style={{ fontSize: 12 }}>
                                    {i18n.t('edm_list_table_name_desc')}
                                  </div>
                                )}
                                placement="bottomLeft"
                              >
                                <Link
                                  to={`/newsletter/${record.id}`}
                                  className={styles.nameText}
                                >
                                  {text}
                                </Link>
                              </Tooltip>
                              <CopyToClipboard
                                text={record.id}
                                className={styles.copiedIcon}
                                onCopy={this.viewModel.onCopiedSuccess}
                              >
                                <CopyOutlined />
                              </CopyToClipboard>
                            </div>
                          );
                        }
                        if (column.key === EDM_LIST_KEYS.Action) {
                          return (
                            <div className={styles.actionsRow}>
                              <Tooltip
                                title={(
                                  <div style={{ fontSize: 12 }}>
                                    {i18n.t('edm_list_table_action_re-analysis')}
                                  </div>
                                )}
                                placement="bottom"
                              >
                                <div
                                  className={styles.redo}
                                  onClick={() => this.viewModel.onReAnalysisClick(record.id, record.time)}
                                />
                              </Tooltip>
                              <Tooltip
                                title={(
                                  <div style={{ fontSize: 12 }}>
                                    {i18n.t('edm_list_table_action_download')}
                                  </div>
                                )}
                                placement="bottom"
                              >
                                <div
                                  className={styles.download}
                                  onClick={() => this.viewModel.onDownloadClick(record.id, record.time)}
                                />
                              </Tooltip>
                              {
                                (this.viewModel.anchor && this.viewModel.list?.length === index + 1)
                                && (
                                  <Waypoint
                                    onEnter={this.viewModel.getList}
                                  />
                                )
                              }
                            </div>
                          );
                        }
                        return (
                          <div className={styles.row}>
                            {text}
                          </div>
                        );
                      }}
                    />
                  );
                })
              }
            </Table>
          </div>
        </div>
        <TimeSelectModal viewModel={this.viewModel.timeSelectModalViewModel} />
        <ErrorModal viewModel={this.viewModel.errorModelViewModel} />
      </>
    );
  }
}

CollaboratorEDMListSection.propTypes = {

};

CollaboratorEDMListSection.defaultProps = {

};

export default CollaboratorEDMListSection;
