import dayjs from 'dayjs';

import { LOG_STAGE } from 'src/constants/normal';

const formateDay = (time) => {
  return dayjs(time).tz('Asia/Taipei').format('YYYY-MM-DD HH:mm');
};

export default class MultipleEDMStatusModel {
  constructor(list) {
    this.list = list.map((el) => {
      return {
        id: el.newsletter?.id,
        project: {
          stage: el.project.stage,
          message: `${[LOG_STAGE.FAIL, LOG_STAGE.WAITING].includes(el.project.stage) ? '' : `${Math.floor(el.project.progress)}%`} ${el.project.stageDesc}\n ${formateDay(el.project.beginAt)} - \n${formateDay(el.project.endAt)}`
        },
        edmAuto: {
          ...(
            el.newsletter.auto.stage
              ? {
                stage: el.newsletter.auto.stage,
                message: `${[LOG_STAGE.FAIL, LOG_STAGE.WAITING].includes(el.newsletter.auto.stage) ? '' : `${Math.floor(el.newsletter.auto.progress)}%`} ${el.newsletter.auto.stageDesc}\n ${formateDay(el.newsletter.auto.beginAt)} - \n${formateDay(el.newsletter.auto.endAt)}`
              }
              : {
                stage: LOG_STAGE.DONE,
                message: '---'
              }
          )
        },
        edmManual: {
          ...(
            el.newsletter.manual.stage
              ? {
                stage: el.newsletter.manual.stage ?? '---',
                message: `${[LOG_STAGE.FAIL, LOG_STAGE.WAITING].includes(el.newsletter.manual.stage) ? '' : `${Math.floor(el.newsletter.manual.progress)}%`} ${el.newsletter.manual.stageDesc}\n ${formateDay(el.newsletter.manual.beginAt)} - \n${formateDay(el.newsletter.manual.endAt)}`
              }
              : {
                stage: LOG_STAGE.DONE,
                message: '---'
              }
          )
        }
      };
    });
  }

  static fromRes(list) {
    return new MultipleEDMStatusModel(list);
  }
}
