import dayjs from 'dayjs';

import { LOG_STAGE } from 'src/constants/normal';

const formateDay = (time) => {
  return dayjs(time).tz('Asia/Taipei').format('YYYY-MM-DD HH:mm');
};

export default class EDMStatusByTimeModel {
  constructor(item) {
    const { project, newsletter: { auto, manual } } = item;
    this.project = {
      stage: project.stage,
      message: `${[LOG_STAGE.FAIL, LOG_STAGE.WAITING].includes(project.stage) ? '' : `${Math.floor(project.progress)}%`} ${project.stageDesc}\n ${formateDay(project.beginAt)} - \n${formateDay(project.endAt)}`
    };
    this.edmAuto = {
      ...(
        auto.stage
          ? {
            stage: auto.stage,
            message: `${[LOG_STAGE.FAIL, LOG_STAGE.WAITING].includes(auto.stage) ? '' : `${Math.floor(auto.progress)}%`} ${auto.stageDesc}\n ${formateDay(auto.beginAt)} - \n${formateDay(auto.endAt)}`
          }
          : {
            stage: LOG_STAGE.DONE,
            message: '---'
          }
      )
    };

    this.edmManual = {
      ...(
        manual.stage
          ? {
            stage: manual.stage,
            message: `${[LOG_STAGE.FAIL, LOG_STAGE.WAITING].includes(manual.stage) ? '' : `${Math.floor(manual.progress)}%`} ${manual.stageDesc}\n ${formateDay(manual.beginAt)} - \n${formateDay(manual.endAt)}`
          }
          : {
            stage: LOG_STAGE.DONE,
            message: '---'
          }
      )
    };

  }

  static fromRes(item) {
    return new EDMStatusByTimeModel(item);
  }
}
