import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';

import i18n from 'src/i18n';
import styles from './styles.module.scss';


@observer
class TopicContentBar extends React.Component {
  constructor() {
    super();
  }

  render() {
    const { viewModel } = this.props;
    return (
      <div className={styles.topicRow}>
        <div className={clsx(styles.topicContent, viewModel.contentError && styles.error)}>
          <div className={styles.content}>
            <span className={styles.title}>
              {i18n.t('edm_drawer_topic_level')}
            </span>
            <span>
              {viewModel.levelText}
            </span>
          </div>
          <div className={styles.content}>
            <span className={styles.title}>
              {i18n.t('edm_drawer_topic_main_content')}
            </span>
            <span>
              {viewModel.description}
            </span>
          </div>
          <div className={styles.content}>
            <span className={styles.title}>
              {i18n.t('edm_drawer_topic_source')}
            </span>
            <span>
              {viewModel.sourceText}
            </span>
          </div>
        </div>
        <Button
          icon={<EditOutlined style={{ fontSize: 20 }} />}
          type="link"
          className={styles.topicAction}
          onClick={viewModel.onEditClick}
          disabled={viewModel.parent.readOnly}
        />
        <Button
          icon={<CloseOutlined style={{ fontSize: 20 }} />}
          type="link"
          className={styles.topicAction}
          onClick={viewModel.onDeleteClick}
          disabled={viewModel.parent.readOnly}
        />
      </div>
    );
  }
}

TopicContentBar.propTypes = {
  viewModel: PropTypes.object.isRequired
};

TopicContentBar.defaultProps = {
  // viewModel: {}
};

export default TopicContentBar;
