import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';
import is from 'is_js';

import i18n from 'src/i18n';

import mainStore from 'src/stores/mainStore';

import { TIME_SELECT_MODAL_TYPE, NEWSLETTER_STATUS_TYPE } from 'src/constants/normal';
import SSOService from 'src/services/sso';
import EDMService from 'src/services/edm';
import EDMEditDrawerViewModel from 'src/components/EDMEditDrawer/viewModel';
import ErrorModalViewModel from 'src/components/ErrorModal/viewModel';
import TimeSelectModalViewModel from './components/timeSelectModal/viewModel';
import DeleteModalViewModel from './components/DeleteModal/viewModel';

export default class EmployeeEDMListSectionViewModel {
  @observable selectedPersonInCharge = null;
  @observable personInChargeList = [];
  @observable isAutoSend = null;
  @observable selectedFrequency = [];

  @observable collaboratorList = [];
  @observable selectedCollaborator = null;

  @observable totalCount = 0;
  @observable list = [];
  @observable anchor = null;

  resizeObserver = null;
  @observable width = 1920;
  @observable height = 1080;

  @observable keyword = '';
  keywordCache = '';

  drawerViewModel = new EDMEditDrawerViewModel(this);
  timeSelectModalViewModel = new TimeSelectModalViewModel(this);
  deleteModalViewModel = new DeleteModalViewModel(this);
  errorModelViewModel = new ErrorModalViewModel(i18n.t('edm_list_table_action_analyzing_error'));

  @computed get showClean() {
    return this.selectedPersonInCharge || is.boolean(this.isAutoSend) || this.selectedFrequency.length >= 1 || this.selectedCollaborator;
  }

  constructor() {
    makeObservable(this);
  }

  @action didMount = async () => {
    const resizeObserver = new ResizeObserver((entries) => {
      const { height, width } = entries[0].contentRect;
      runInAction(() => {
        this.width = width;
        this.height = height;
      });
    });
    this.resizeObserver = resizeObserver;
    const container = document.querySelector('#app');
    this.resizeObserver.observe(container);

    await Promise.all([
      this.getPersonInChargeList(),
      this.getList(),
      this.getCollaboratorList()
    ]);
  };

  @action willUnmount = () => {
    const container = document.querySelector('#app');
    this.resizeObserver.unobserve(container);
  };

  // > filter related.
  @action getPersonInChargeList = async () => {
    try {
      if (mainStore.checkIsInQueue('personInCharge')) {
        return;
      }

      // > sales don't show this filter
      if (mainStore.userType === 'sales') {
        return;
      }

      mainStore.setLoading('personInCharge');
      const { list } = await SSOService.getOwners();

      runInAction(() => {
        this.personInChargeList = [...list.filter((user) => user.label)];
      });
    } catch (error) {
      message.error(i18n.t('api_get_owner_error'));
    } finally {
      mainStore.setLoadingComplete('personInCharge');
    }
  };

  @action getCollaboratorList = async () => {
    try {
      if (mainStore.checkIsInQueue('collaboratorList')) {
        return;
      }
      mainStore.setLoading('collaboratorList');
      const { list } = await SSOService.getCollaborators();

      runInAction(() => {
        this.collaboratorList = [...list];
      });
    } catch (error) {
      message.error(i18n.t('api_get_collaborator_error'));
    } finally {
      mainStore.setLoadingComplete('collaboratorList');
    }
  };

  @action onPersonInChargeSelect = (e) => {
    this.selectedPersonInCharge = e;
  };

  @action onAutoSendSelect = (e) => {
    this.isAutoSend = e;
  };

  @action onFrequencySelect = (e) => {
    this.selectedFrequency = e;
  };

  @action onSubmit = () => {
    this.anchor = null;
    this.list = [];

    this.getList();
  };

  @action onClean = () => {
    this.selectedPersonInCharge = null;
    this.isAutoSend = null;
    this.selectedFrequency = [];
    this.selectedCollaborator = null;

    this.anchor = null;
    this.list = [];

    this.getList();
  };

  @action onKeywordChange = (e) => {
    this.keyword = e.target.value;

    if (!this.keyword.trim()) {
      e.target.blur();
      this.onKeywordSubmit();
    }
  };

  @action onKeydown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      e.target.blur();
      this.onKeywordSubmit();
    }
  };

  @action onKeywordSubmit = () => {
    if (this.keyword === this.keywordCache) {
      return;
    }

    this.keywordCache = this.keyword;
    this.anchor = null;
    this.list = [];

    this.getList();
  };

  @action onCollaboratorSelect = (value) => {
    this.selectedCollaborator = value;
  };

  // > list related.
  @action getList = async () => {
    let resLits = [];
    try {
      if (mainStore.checkIsInQueue('getEdmList')) {
        return;
      }
      mainStore.setLoading('getEdmList');
      const params = {
        ...(this.anchor && { anchor: this.anchor }),
        ...(this.keywordCache && { keyword: this.keywordCache }),
        ...(this.selectedPersonInCharge && { ownerId: this.selectedPersonInCharge }),
        ...(this.selectedCollaborator && { collaboratorId: this.selectedCollaborator }),
        ...(is.boolean(this.isAutoSend) && { isAutoSend: this.isAutoSend }),
        ...(this.selectedFrequency.length > 0 && { frequency: this.selectedFrequency })
      };

      const { list, anchor, count } = await EDMService.getEDMList(params);
      resLits = [...list];
      runInAction(() => {
        this.anchor = anchor;
        if (count) {
          this.totalCount = count;
        }
      });

    } catch (error) {
      runInAction(() => {
        this.list = [];
        this.anchor = null;
        this.count = 0;
      });
      message.error(i18n.t('api_get_edm_list_error'));
    } finally {
      mainStore.setLoadingComplete('getEdmList');
    }

    if (resLits.length === 0) {
      return;
    }

    try {
      if (mainStore.checkIsInQueue('getEdmListStatus')) {
        return;
      }
      mainStore.setLoading('getEdmListStatus');
      const { list } = await EDMService.getMultipleEDMStatus(resLits.map((el) => el.id));
      runInAction(() => {
        this.list = [...this.list, ...resLits.map((item) => ({
          ...item,
          ...(list.find((status) => status.id === item.id) ?? {})
        }))];
      });
    } catch (error) {
      this.list = [...this.list, ...resLits];
      message.warning(i18n.t('api_get_edm_list_status_warning'));
    } finally {
      mainStore.setLoadingComplete('getEdmListStatus');
    }
  };

  @action onRefresh = () => {
    this.anchor = null;
    this.list = [];

    this.getList();
  };

  @action onCopiedSuccess = () => {
    message.success(i18n.t('common_copy_success'));
  };


  // > actions
  @action onEditClick = async (id) => {
    try {
      if (mainStore.checkIsInQueue('checkStatus')) {
        return;
      }
      mainStore.setLoading('checkStatus');
      const { newsletterAuto, newsletterManual } = await EDMService.getSingleEDMStatus(id);
      if (
        newsletterAuto === NEWSLETTER_STATUS_TYPE.Waiting
        || newsletterManual === NEWSLETTER_STATUS_TYPE.Waiting
      ) {
        this.drawerViewModel.onOpen(id, true);
        return;
      }
      this.drawerViewModel.onOpen(id);
    } catch (error) {
      message.error(i18n.t('api_get_edm_status_error'));
    } finally {
      mainStore.setLoadingComplete('checkStatus');
    }
  };

  @action onReAnalysisClick = async (id, time) => {
    try {
      if (mainStore.checkIsInQueue('checkStatus')) {
        return;
      }
      mainStore.setLoading('checkStatus');
      const { newsletterAuto, newsletterManual } = await EDMService.getSingleEDMStatus(id);
      if (
        !([NEWSLETTER_STATUS_TYPE.Done, NEWSLETTER_STATUS_TYPE.Fail].includes(newsletterAuto)
        && [NEWSLETTER_STATUS_TYPE.Done, NEWSLETTER_STATUS_TYPE.Fail].includes(newsletterManual))
      ) {
        this.errorModelViewModel.onOpen();
        return;
      }
      this.timeSelectModalViewModel.onOpen(id, time, TIME_SELECT_MODAL_TYPE.ReAnalysis);
    } catch (error) {
      message.error(i18n.t('api_get_edm_status_error'));
    } finally {
      mainStore.setLoadingComplete('checkStatus');
    }

  };

  @action onDeleteClick = async (id, name) => {
    try {
      if (mainStore.checkIsInQueue('checkStatus')) {
        return;
      }
      mainStore.setLoading('checkStatus');
      const { project, newsletterAuto, newsletterManual } = await EDMService.getSingleEDMStatus(id);
      if (![NEWSLETTER_STATUS_TYPE.Waiting, NEWSLETTER_STATUS_TYPE.Done, NEWSLETTER_STATUS_TYPE.Fail, NEWSLETTER_STATUS_TYPE.Cancel].includes(project)) {
        this.errorModelViewModel.onOpen();
        return;
      }
      if (
        !([NEWSLETTER_STATUS_TYPE.Done, NEWSLETTER_STATUS_TYPE.Fail].includes(newsletterAuto)
        && [NEWSLETTER_STATUS_TYPE.Done, NEWSLETTER_STATUS_TYPE.Fail].includes(newsletterManual))
      ) {
        this.errorModelViewModel.onOpen();
        return;
      }
      this.deleteModalViewModel.onOpen(id, name);
    } catch (error) {
      message.error(i18n.t('api_get_edm_status_error'));
    } finally {
      mainStore.setLoadingComplete('checkStatus');
    }
  };

  @action onDownloadClick = async (id, time) => {
    try {
      if (mainStore.checkIsInQueue('checkStatus')) {
        return;
      }
      mainStore.setLoading('checkStatus');
      const { newsletterAuto, newsletterManual } = await EDMService.getSingleEDMStatus(id);
      if (
        !([NEWSLETTER_STATUS_TYPE.Done, NEWSLETTER_STATUS_TYPE.Fail].includes(newsletterAuto)
        && [NEWSLETTER_STATUS_TYPE.Done, NEWSLETTER_STATUS_TYPE.Fail].includes(newsletterManual))
      ) {
        this.errorModelViewModel.onOpen();
        return;
      }
      this.timeSelectModalViewModel.onOpen(id, time, TIME_SELECT_MODAL_TYPE.Download);
    } catch (error) {
      message.error(i18n.t('api_get_edm_status_error'));
    } finally {
      mainStore.setLoadingComplete('checkStatus');
    }
  };
}
