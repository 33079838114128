import { makeObservable, observable, action, computed } from 'mobx';
import { getHost } from 'src/apis/utils';

import Auth from 'src/services/auth';
import AuthorizationService from 'src/services/authorization';

import mainStore from 'src/stores/mainStore';

export default class LoginPageViewModel {
  @observable isCheck = false;

  constructor() {
    makeObservable(this);
  }

  @action didMount = () => {
    // console.log('userType', !!mainStore.userType);
  };

  onLoginClick = async (router) => {
    if (!Auth.hasToken()) {
      try {
        window.location.href = `${getHost()}/api/v1/auth/login`;
      } catch (error) {
        // console.log('error', error);
      }
      return;
    }

    try {
      const { user } = await AuthorizationService.getProfile();
      if (user?.id) {
        router.navigate('/');
      }
    } catch (error) {
      // > remove token and jwt
      AuthorizationService.logout();
      window.location.href = `${getHost()}/api/v1/auth/login`;
    }

  };
}
