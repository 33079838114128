import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import i18n from 'src/i18n';

import styles from './styles.module.scss';

@observer
class WordCloud extends React.Component {
  componentDidMount() {
    this.props.viewModel.didMount();
  }

  componentWillUnmount() {
    this.props.viewModel.willUnmount();
  }

  render() {
    const { viewModel, sizeData, isScreenshot } = this.props;
    const { resizeRef } = sizeData;
    return (
      <div className={clsx(styles.container)}>
        {/* WORDS HERE */}
        {
          isScreenshot
            ? (
              <canvas
                id={viewModel.id}
                width="1080"
                height="1080"
                ref={resizeRef}
              />
            )
            : (
              <div
                id={viewModel.id}
                ref={resizeRef}
                className={clsx(styles.wordsContainer)}
              />
            )
        }

      </div>
    );
  }
}

WordCloud.propTypes = {
  viewModel: PropTypes.object.isRequired,
  sizeData: PropTypes.object,
  isScreenshot: PropTypes.bool
};

WordCloud.defaultProps = {
  sizeData: {
    resizeRef: null,
    height: 1,
    width: 1
  },
  isScreenshot: false
};


export default WordCloud;
