import dayjs from 'dayjs';
import { makeObservable, observable, action, runInAction } from 'mobx';
import { message } from 'antd';

import i18n from 'src/i18n';
import { COLOR_SET } from 'src/constants/chart';
import { resizeWords } from 'src/utils';

import WordCloudViewModel from 'src/components/WordCloud/viewModel';

import EDMService from 'src/services/edm';
import ResultService from 'src/services/result';
import ProjectService from 'src/services/project';
import mainStore from 'src/stores/mainStore';

// > don't show anything when error, or screen shot will catch it.

export default class ScreenshotPageViewModel {
  projectId = '';
  newsletterId = '';
  resultId = '';
  edmType = '';

  @observable searchAngle = 'brand';

  @observable chartStartDate = '';
  @observable chartEndDate = '';

  @observable mainBrand = {};

  @observable mainBrandTrend = { labels: [], datasets: [] };
  @observable mainBrandRanking = { labels: [], datasets: [] };
  @observable mainBrandWordCloud = null;
  @observable subjectWordCloud = [];

  // > new add for screen shot
  @observable isLineChartCompleted = false;
  @observable isBarCHartCompleted = false;

  constructor() {
    makeObservable(this);
  }

  @action didMount = async ({ id, rid, type = 'edm' }) => {
    this.newsletterId = id;
    this.resultId = rid;
    this.edmType = type;

    try {
      if (mainStore.checkIsInQueue('previewPage')) {
        return;
      }
      mainStore.setLoading('previewPage');

      await this.getNewsletterDetail();

      await Promise.all([
        this.getResultDetail(),
        this.getProjectDetail()
      ]);

      await Promise.all([
        this.getVolumeChartData(),
        this.getFeatureData()
      ]);
    } catch (error) {
      // > don't do anything here.
    } finally {
      mainStore.setLoadingComplete('previewPage');
    }
  };

  @action getProjectDetail = async () => {
    try {
      if (mainStore.checkIsInQueue('projectDetail')) {
        return;
      }
      mainStore.setLoading('projectDetail');

      const { meta: { searchAngle } } = await ProjectService.getProjectDetailById(this.projectId);

      runInAction(() => {
        this.searchAngle = searchAngle;
      });

    } catch (error) {
      message.error(i18n.t('api_get_project_detail_error'));
    } finally {
      mainStore.setLoadingComplete('projectDetail');
    }
  };

  @action getNewsletterDetail = async () => {
    try {
      if (mainStore.checkIsInQueue('newsletterDetail')) {
        return;
      }
      mainStore.setLoading('newsletterDetail');

      const { projectId, mainBrandId } = await EDMService.getEDMDetail(this.newsletterId);
      runInAction(() => {
        this.projectId = projectId;
        this.mainBrand.id = mainBrandId;
      });
      await this.updateMainBrand();
    } catch (error) {
      // message.error(i18n.t('api_get_edm_detail_error'));
    } finally {
      mainStore.setLoadingComplete('newsletterDetail');
    }
  };

  @action getResultDetail = async () => {
    try {
      if (mainStore.checkIsInQueue('resultDetail')) {
        return;
      }
      mainStore.setLoading('resultDetail');
      const { startDate, endDate } = await ResultService.getResultDetail(this.newsletterId, this.resultId);
      runInAction(() => {
        const diffDays = dayjs(endDate).diff(dayjs(startDate), 'day') + 1;

        this.chartStartDate = diffDays >= 7 ? startDate : dayjs(endDate).tz('Asia/Taipei').subtract(6, 'day').startOf('day')
          .toISOString();
        this.chartEndDate = endDate;
      });

    } catch (error) {
      // message.error(i18n.t('api_get_edm_result_detail_error'));
    } finally {
      mainStore.setLoadingComplete('resultDetail');
    }
  };

  @action updateMainBrand = async () => {
    if (!this.mainBrand.id) {
      return;
    }
    try {
      if (mainStore.checkIsInQueue('mainBrand')) {
        return;
      }
      mainStore.setLoading('mainBrand');
      const [brand] = await ProjectService.getLevelContentByIds(this.projectId, [this.mainBrand.id]);
      runInAction(() => {
        this.mainBrand.name = brand.name;
      });
    } catch (error) {
      // message.error(i18n.t('api_get_project_level_one_data_error'));
    } finally {
      mainStore.setLoadingComplete('mainBrand');
    }
  };

  @action getVolumeChartData = async () => {
    try {
      if (mainStore.checkIsInQueue('chartData')) {
        return;
      }
      mainStore.setLoading('chartData');
      const { trend, competitor } = await ResultService.getVolumeChartData(this.newsletterId, {
        gte: this.chartStartDate,
        lte: this.chartEndDate
      });

      runInAction(() => {
        this.mainBrandTrend = {
          labels: trend.map((item) => item.date),
          datasets: [{
            label: this.mainBrand?.name ?? '主品牌',
            data: trend.map((item) => item.count),
            borderColor: COLOR_SET[0]
          }]
        };

        this.mainBrandRanking = {
          labels: competitor.map((item) => item.name),
          datasets: [{
            label: `${dayjs(this.chartStartDate).tz('Asia/Taipei').format('YYYY/MM/DD')} - ${dayjs(this.chartEndDate).tz('Asia/Taipei').format('YYYY/MM/DD')}`,
            data: competitor.map((item) => item.count),
            backgroundColor: COLOR_SET[0]
          }]
        };
      });
    } catch (error) {
      // message.error(i18n.t('api_get_main_chart_data_error'));
    } finally {
      mainStore.setLoadingComplete('chartData');
    }
  };

  @action getFeatureData = async () => {
    try {
      if (mainStore.checkIsInQueue('cloudChart')) {
        return;
      }
      mainStore.setLoading('cloudChart');

      const { main, subject } = await ResultService.getFeatureData(this.newsletterId, {
        gte: this.chartStartDate,
        lte: this.chartEndDate
      });

      runInAction(() => {
        if (main) {
          this.mainBrand.keywords = main.map((keyword) => ({
            id: keyword.id,
            keyword: keyword.name,
            count: keyword.count
          }));
          this.mainBrandWordCloud = new WordCloudViewModel(
            {
              data: resizeWords(main, 'l').map((keyword, i) => [keyword.name, keyword.count, COLOR_SET[i % 20], keyword.id]),
              id: 'mainBrandCloud',
              name: `main${this.mainBrand?.id ?? 'brand'}`
            },
            this
          );
          this.subjectWordCloud = subject.map((subjectItem) => {
            const keywords = subjectItem.item?.map((keyword) => ({
              id: keyword.id,
              keyword: keyword.name,
              count: keyword.count
            }));
            return {
              id: subjectItem.id,
              wordCloud: new WordCloudViewModel(
                {
                  data: resizeWords(keywords, 'l').map((keyword, i) => [keyword.keyword, keyword.count, COLOR_SET[i % 20], keyword.id]),
                  id: `subjectCloud${subjectItem.id}`,
                  name: `subject${subjectItem.id}`
                },
                this
              )
            };
          });
        }
      });
    } catch (error) {
      // message.error(i18n.t('api_get_cloud_chart_error'));
    } finally {
      mainStore.setLoadingComplete('cloudChart');
    }
  };

  @action onLineChartCompleted = () => {
    this.isLineChartCompleted = true;
  };

  @action onBarChartCompleted = () => {
    this.isBarCHartCompleted = true;
  };

}
