import { request, getHost, getHeaders } from './utils';

export const getProjectList = (params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/project`,
    headers: getHeaders(),
    params
  };
  return request(options);
};

export const getProjectDetailById = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/project/${id}`,
    headers: getHeaders()
  };
  return request(options);
};

export const getFeatureSetByProject = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/project/${id}/featureSet`,
    headers: getHeaders()
  };
  return request(options);
};

export const getBrandByProject = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/project/${id}/searchSet/keyword`,
    headers: getHeaders(),
    params: {
      level: 1,
      limit: 1000
    }
  };
  return request(options);
};

export const getProductLineByBrandId = (id, brandId) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/project/${id}/searchSet/keyword`,
    headers: getHeaders(),
    params: {
      level: 2,
      limit: 10000,
      sids: [brandId]
    }
  };
  return request(options);
};

export const getProductByBrandId = (id, brandId) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/project/${id}/searchSet/keyword`,
    headers: getHeaders(),
    params: {
      level: 3,
      limit: 10000,
      sids: [brandId]
    }
  };
  return request(options);
};

export const getSearchTagByProjectId = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/project/${id}/searchSet/tag`,
    headers: getHeaders()
  };
  return request(options);
};

export const getLevelContentByIds = (id, ids) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/project/${id}/searchSet/keyword/ids`,
    headers: getHeaders(),
    data: {
      ids
    }
  };
  return request(options);
};

export const getTagContentByIds = (id, ids) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/project/${id}/searchSet/tag/ids`,
    headers: getHeaders(),
    data: {
      ids
    }
  };
  return request(options);
};

export const getSource = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/vocService/project/${id}/source`,
    headers: getHeaders()
  };
  return request(options);
};
