import { request, getHost, getHeaders } from './utils';

export const getTeamList = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/team`,
    headers: getHeaders()
  };
  return request(options);
};

export const getMembersByTeamId = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/team/${id}/user`,
    headers: getHeaders()
  };
  return request(options);
};

export const getOwners = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/user/owner`,
    headers: getHeaders()
  };
  return request(options);
};

export const getCollaborators = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/user/collaborator`,
    headers: getHeaders()
  };
  return request(options);
};
