export const ROUTES = {
  EDMList: '',
  Newsletter: '/newsletter/:id',
  Result: '/newsletter/:id/result/:rid',
  Preview: '/newsletter/:id/result/:rid/preview',
  TopicPreview: '/newsletter/:id/result/:rid/subject/:sid/preview',

  // > email related screen
  Screenshot: '/newsletter/:id/result/:rid/screenshot',
  EmailTopicPreview: '/preview/:token',

  // > auth
  Login: '/login',
  LoginSuccess: '/loginSuccess',

  // > others
  NotFound: '/notfound',
  Error: '/error',
  Maintenance: '/maintenance'
};

