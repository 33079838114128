import * as auth from './auth';
import * as project from './project';
import * as sso from './sso';
import * as edm from './edm';
import * as result from './result';

export default {
  auth,
  project,
  sso,
  edm,
  result
};
