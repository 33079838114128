import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';

import i18n from 'src/i18n';
import { SOURCE_TYPE } from 'src/constants/normal';
import mainStore from 'src/stores/mainStore';

import ProjectService from 'src/services/project';

export default class SourceSelectViewModel {
  parent = {};
  projectId = null;

  @observable showSourceType = SOURCE_TYPE.Category;
  @observable source = [];

  @observable selectedCategory = null;
  @observable selectedWebsites = [];
  @observable selectedChannels = [];

  @observable categoryKeyword = '';
  @observable categoryKeywordCache = '';
  @observable websiteKeyword = '';
  @observable websiteKeywordCache = '';
  @observable channelKeyword = '';
  @observable channelKeywordCache = '';

  @computed get searchCategory() {
    return this.categoryKeywordCache.trim
      ? this.source.filter((category) => category.name?.toLocaleLowerCase().includes(this.categoryKeywordCache?.toLocaleLowerCase())).map((category) => ({
        id: category.id,
        name: category.name
      }))
      : this.source.map((category) => ({
        id: category.id,
        name: category.name
      }));
  }

  @computed get showWebsite() {
    if (!this.selectedCategory) {
      return [];
    }
    const list = this.source.find((category) => category.id === this.selectedCategory).children;
    if (!this.websiteKeywordCache.trim()) {
      return list.map((website) => ({
        id: website.id,
        name: website.name
      }));
    }
    return list.filter((website) => website.name?.toLocaleLowerCase().includes(this.websiteKeywordCache?.toLocaleLowerCase())).map((website) => ({
      id: website.id,
      name: website.name
    }));
  }

  @computed get filterWebsiteKeys() {
    return this.selectedWebsites.filter((website) => this.showWebsite.map((w) => w.id).includes(website));
  }

  @computed get websiteSelectedIndeterminate() {
    return this.filterWebsiteKeys.length > 0 && this.filterWebsiteKeys.length !== this.showWebsite.length;
  }

  @computed get websiteSelectedAll() {
    return this.filterWebsiteKeys.length === this.showWebsite.length;
  }

  @computed get showChannel() {
    if (!this.selectedCategory) {
      return [];
    }
    const parentList = this.source.find((category) => category.id === this.selectedCategory).children;

    if (this.selectedWebsites.length === 0) {
      return [];
    }

    const websites = parentList.filter((website) => this.selectedWebsites.includes(website.id));

    if (this.channelKeywordCache.trim()) {
      const searchResult = [];
      websites.forEach((website) => {
        if (website.name?.toLocaleLowerCase().includes(this.channelKeywordCache.toLocaleLowerCase())) {
          searchResult.push({
            title: website.name,
            key: website.id,
            children: website.children.map((channel) => ({
              title: channel.name,
              key: channel.id
            }))
          });
          return;
        }
        const matchChannels = website.children.filter((channel) => channel.name.toLocaleLowerCase().includes(this.channelKeywordCache.toLocaleLowerCase()));

        if (matchChannels.length > 0) {
          searchResult.push({
            title: website.name,
            key: website.id,
            children: matchChannels.map((channel) => ({
              title: channel.name,
              key: channel.id
            }))
          });
        }
      });
      return searchResult;
    }

    return websites.map((website) => ({
      title: website.name,
      key: website.id,
      children: website.children.map((channel) => ({
        title: channel.name,
        key: channel.id
      }))
    }));
  }

  @computed get showChannelKeys() {
    return this.showChannel.map((el) => el.children).flat().map((channel) => channel.key);
  }

  @computed get availableChannelKeys() {
    return this.selectedChannels.filter((channelKeys) => this.showChannelKeys.includes(channelKeys));
  }

  @computed get channelSelectedIndeterminate() {

    return this.availableChannelKeys.length > 0 && this.showChannelKeys.length !== this.availableChannelKeys.length;
  }

  @computed get channelSelectedAll() {
    return this.showChannelKeys.length === this.availableChannelKeys.length;
  }


  constructor() {
    makeObservable(this);
  }

  @action updateProjectId = async (id) => {
    this.projectId = id;

    await this.updateSource();
  };

  @action updateContent = ({ category, website, channel, type }) => {
    this.selectedCategory = category[0] ?? null;
    this.selectedWebsites = website ?? [];

    this.selectedChannels = channel ?? [];
    this.showSourceType = type;
  };

  @action updateShowSourceType = (type) => {
    if (this.showSourceType === type) {
      return;
    }
    if (!type) {
      this.selectedCategory = null;
      this.selectedWebsites = [];
      this.selectedChannels = [];
    }

    if (type === SOURCE_TYPE.Category) {
      this.selectedWebsites = [];
      this.selectedChannels = [];
    }

    if (type === SOURCE_TYPE.Website) {
      this.selectedChannels = [];
    }


    this.showSourceType = type;
  };

  @action cleanData = () => {
    this.showSourceType = null;

    this.selectedCategory = null;
    this.selectedWebsites = [];
    this.selectedChannels = [];
  };

  @action cleanKeyword = () => {
    this.categoryKeyword = '';
    this.categoryKeywordCache = '';
    this.websiteKeyword = '';
    this.websiteKeywordCache = '';
    this.channelKeyword = '';
    this.channelKeywordCache = '';
  };

  @action updateSource = async () => {
    try {
      if (mainStore.checkIsInQueue('source')) {
        return;
      }

      mainStore.setLoading('source');

      const { source } = await ProjectService.getSourceById(this.projectId);

      runInAction(() => {
        this.source = source;
      });

    } catch (error) {
      message.error(i18n.t('api_get_project_source_error'));
    } finally {
      mainStore.setLoadingComplete('source');
    }
  };

  // > category
  @action onCategoryKeywordChange = (e) => {
    this.categoryKeyword = e.target.value;

    if (!this.categoryKeyword.trim()) {
      this.onCategoryKeywordSubmit();
    }
  };

  @action onCategoryKeywordSubmit = () => {
    if (this.categoryKeyword === this.categoryKeywordCache) {
      return;
    }

    this.categoryKeywordCache = this.categoryKeyword;
  };

  @action onCategorySelect = (boolean, id) => {
    if (boolean) {
      this.selectedCategory = id;
    } else {
      this.selectedCategory = null;
    }
    this.selectedWebsites = [];
    this.selectedChannels = [];
  };

  // > website
  @action onWebsiteKeywordChange = (e) => {
    this.websiteKeyword = e.target.value;

    if (!this.websiteKeyword.trim()) {
      this.onWebsiteKeywordSubmit();
    }
  };

  @action onWebsiteKeywordSubmit = () => {
    if (this.websiteKeyword === this.websiteKeywordCache) {
      return;
    }

    this.websiteKeywordCache = this.websiteKeyword;
  };

  @action onWebsiteSelect = (boolean, id) => {
    if (boolean) {
      this.selectedWebsites.push(id);
    } else {
      this.selectedWebsites = this.selectedWebsites.filter((website) => website !== id);
      const channelsOfTargetWebsite = this.source.find((category) => category.id === this.selectedCategory)?.children?.find((website) => website.id === id)?.children.map((channel) => channel.id) ?? [];
      this.selectedChannels = this.selectedChannels.filter((channel) => !(channelsOfTargetWebsite.includes((channel))));
    }
  };

  @action onSelectAllWebsites = () => {
    // > REMOVE
    if (this.websiteSelectedAll) {
      const removeList = this.selectedWebsites.filter((websiteKey) => this.showWebsite.map((website) => website.id).includes(websiteKey));
      removeList.forEach((websiteId) => this.onWebsiteSelect(false, websiteId));
      return;
    }
    // > ADD
    if (this.websiteSelectedIndeterminate || !this.websiteSelectedAll) {
      const sets = new Set([...this.selectedWebsites, ...this.showWebsite.map((website) => website.id)]);
      this.selectedWebsites = Array.from(sets);
    }
  };


  // > channel
  @action onChannelKeywordChange = (e) => {
    this.channelKeyword = e.target.value;

    if (!this.channelKeyword.trim()) {
      this.onChannelKeywordSubmit();
    }
  };

  @action onChannelKeywordSubmit = () => {
    if (this.channelKeyword === this.channelKeywordCache) {
      return;
    }

    this.channelKeywordCache = this.channelKeyword;
  };

  @action onChannelChecked = (_, e) => {
    const { checked, checkedNodes, node: { key } } = e;
    // > ADD
    if (checked) {
      const sets = new Set([...this.selectedChannels, ...checkedNodes.filter((item) => item.title !== item.key).map((item) => item.key)]);
      this.selectedChannels = Array.from(sets);
    // > REMOVE
    } else {
      this.selectedChannels = this.selectedChannels.filter((channelKey) => channelKey !== key);
    }
  };

  @action onSelectAllChannels = () => {
    // > REMOVE
    if (this.channelSelectedAll) {
      this.selectedChannels = this.selectedChannels.filter((channelKey) => !(this.showChannelKeys.includes(channelKey)));
      return;
    }
    // > ADD
    if (this.channelSelectedIndeterminate || !this.channelSelectedAll) {
      const sets = new Set([...this.selectedChannels, ...this.showChannelKeys]);
      this.selectedChannels = Array.from(sets);
    }
  };
}
