export default class TeamListModel {
  constructor(list) {
    this.list = list
      .filter((team) => team.id !== '000000000000000000000001' && team.id !== '200000000000000000000000')
      .map((team) => ({
        value: team.id,
        label: team.name
      }));
  }

  static fromRes(data) {
    return new TeamListModel(data);
  }
}
