import { SERVER_STATUS, COLLABORATOR_TEAM_ID } from 'src/constants/config';

export default class UserModel {
  constructor(user) {
    if (!user) {
      this.user = {};
      return;
    }

    const {
      id,
      name,
      email,
      isAdmin,
      isSales,
      teamId
    } = user;

    const userType = (() => {
      if (isAdmin) {
        return 'admin';
      }
      if (isSales) {
        return 'sales';
      }
      if (teamId === COLLABORATOR_TEAM_ID[SERVER_STATUS]) {
        return 'collaborator';
      }
      return null;
    })();

    this.user = {
      id,
      name,
      email,
      userType
    };
  }

  static fromRes(user) {
    return new UserModel(user);
  }
}
