import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Modal, Button, DatePicker } from 'antd';
import dayjs from 'dayjs';

import { TIME_SELECT_MODAL_TYPE } from 'src/constants/normal';
import i18n from 'src/i18n';

import styles from './styles.module.scss';


@observer
class TimeSelectModal extends React.Component {
  constructor() {
    super();

  }

  render() {
    const { viewModel } = this.props;
    return (
      <Modal
        open={viewModel.isOpen}
        centered
        footer={null}
        closeIcon={null}
        width={500}
        onCancel={viewModel.onClose}
        styles={{ content: { padding: 0 } }}
      >
        <div className={styles.container}>
          <div className={styles.title}>
            {
              viewModel.type === TIME_SELECT_MODAL_TYPE.ReAnalysis
                ? i18n.t('edm_list_table_action_re-analysis')
                : i18n.t('edm_list_table_action_download_title')
            }
          </div>
          <div className={styles.content}>
            <div className={styles.contentText}>
              {
                viewModel.type === TIME_SELECT_MODAL_TYPE.ReAnalysis
                  ? i18n.t('edm_list_table_action_re-analysis_desc')
                  : i18n.t('edm_list_table_action_download_desc')
              }
            </div>
            <DatePicker.RangePicker
              value={viewModel.selectedDay}
              onCalendarChange={viewModel.onDateSelect}
              placeholder={[i18n.t('edm_drawer_time_start_placeholder'), i18n.t('edm_drawer_time_end_placeholder')]}
              changeOnBlur
              allowClear
              disabledDate={(current) => {
                return dayjs(current).tz('Asia/Taipei') < dayjs(viewModel.availableDate[0]).tz('Asia/Taipei').startOf('day')
                  || dayjs(current).tz('Asia/Taipei') > dayjs(viewModel.availableDate[1]).tz('Asia/Taipei').endOf('day');
              }}
            />
          </div>
          <div className={styles.footer}>
            <Button
              className={styles.confirmBtn}
              onClick={viewModel.onConfirm}
            >
              {i18n.t('common_confirm')}
            </Button>
            <Button
              className={styles.cancelBtn}
              onClick={viewModel.onClose}
            >
              {i18n.t('common_cancel')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

TimeSelectModal.propTypes = {
  viewModel: PropTypes.object.isRequired
};

TimeSelectModal.defaultProps = {
  // viewModel: {}
};

export default TimeSelectModal;
