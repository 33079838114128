export default class SearchTagModel {
  constructor(list) {
    const parent = list.filter((tag) => !tag.parentId).map((tag) => ({ id: tag._id, name: tag.name, children: [] }));

    list.forEach((tag) => {
      if (!tag.parentId) {
        return;
      }
      const targetParent = parent.find((p) => p.id === tag.parentId);
      if (targetParent) {
        targetParent.children.push({ id: tag._id, name: tag.name });
      }
    });

    this.searchTag = parent;
  }

  static fromRes(data) {
    return new SearchTagModel(data);
  }
}
