import API from 'src/apis';

import TeamListModel from 'src/models/response/TeamList';
import MemberListModel from 'src/models/response/MemberList';

export default class SSOService {
  static async getTeamList() {
    const res = await API.sso.getTeamList();

    return TeamListModel.fromRes(res.data.result);
  }

  static async getMembersByTeamId(id) {
    const res = await API.sso.getMembersByTeamId(id);

    return MemberListModel.fromRes(res.data.result);
  }

  static async getOwners() {
    const res = await API.sso.getOwners();

    return { list: res.data.result.map((user) => ({ value: user.id, label: user.name })) };
  }

  static async getCollaborators() {
    const res = await API.sso.getCollaborators();

    return { list: res.data.result.map((user) => ({ value: user.id, label: user.name })) };
  }
}
