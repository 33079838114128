import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Tag, Tooltip, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import i18n from 'src/i18n';

import PlusTagViewModel from './viewModel';

import styles from './styles.module.scss';


@observer
class PlusTag extends React.Component {
  constructor() {
    super();

    this.viewModel = new PlusTagViewModel();
  }

  render() {
    const { onCreateEnd } = this.props;
    if (this.viewModel.isEdit) {
      return (
        <Input
          value={this.viewModel.tagName}
          onChange={this.viewModel.onChange}
          onKeyDown={(e) => this.viewModel.onKeydown(e, onCreateEnd)}
          className={styles.input}
          onBlur={this.viewModel.onCancel}
          autoFocus
          maxLength={30}
        />
      );
    }
    return (
      <Tooltip
        title={i18n.t('edm_drawer_setting_topic_add_desc')}
        placement="bottomLeft"
        overlayStyle={{ maxWidth: 'unset', whiteSpace: 'nowrap' }}
      >
        <Tag
          icon={<PlusOutlined />}
          className={clsx(styles.tagStyle, styles.plusTag)}
          onClick={this.viewModel.onEdit}
        />
      </Tooltip>
    );
  }
}

PlusTag.propTypes = {
  onCreateEnd: PropTypes.func.isRequired
};

PlusTag.defaultProps = {
  // viewModel: {}
};

export default PlusTag;
