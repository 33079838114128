import dayjs from 'dayjs';
import { TIME_RANGE, QUEUE_SCHEDULE_TYPE, QUEUE_SCHEDULE_OPTIONS, LOG_STAGE } from 'src/constants/normal';

export default class ResultListModel {
  constructor(data) {
    const {
      id,
      startDate,
      endDate,
      type,
      lastChangeDate,
      lastSendEmailDate,
      lastSender
    } = data;

    this.id = id;
    this.startDate = startDate;
    this.endDate = endDate;
    this.time = `${dayjs(startDate).tz('Asia/Taipei').format('YYYY/MM/DD')}${TIME_RANGE[type]}${[QUEUE_SCHEDULE_TYPE.Daily, QUEUE_SCHEDULE_TYPE.Noon, QUEUE_SCHEDULE_TYPE.Evening].includes(type) ? '' : `-\n${dayjs(endDate).format('YYYY/MM/DD')}${TIME_RANGE[type]}`}`;
    this.frequency = QUEUE_SCHEDULE_OPTIONS.find((item) => item.value === type)?.label;
    this.project = {
      stage: LOG_STAGE.DONE,
      message: '---'
    };
    this.edmAuto = {
      stage: LOG_STAGE.DONE,
      message: '---'
    };
    this.edmManual = {
      stage: LOG_STAGE.DONE,
      message: '---'
    };
    this.latestActionTime = lastChangeDate ? dayjs(lastChangeDate).tz('Asia/Taipei').format('YYYY/MM/DD \n HH:mm:ss a') : '---';
    this.latestSendTime = lastSendEmailDate ? dayjs(lastSendEmailDate).tz('Asia/Taipei').format('YYYY/MM/DD \n HH:mm:ss a') : '---';
    this.latestSender = lastSender;
  }

  static fromRes(data) {
    return new ResultListModel(data);
  }
}
