import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import styles from './styles.module.scss';

const FilledButton = observer(({ children, ...rest }) => {
  return (
    <Button
      {...rest}
      type="primary"
      className={styles.normalButton}
      icon={<CheckOutlined />}
    >
      {children}
    </Button>
  );
});

FilledButton.propTypes = {

};

FilledButton.defaultProps = {

};

export default FilledButton;
