import { message } from 'antd';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';

import i18n from 'src/i18n';
import Auth from 'src/services/auth';
import { delay } from 'src/utils';

import AuthorizationService from 'src/services/authorization';

// > 需要存在store的資訊不多 僅使用一個
class MainStore {
  @observable queue = [];

  @observable userId = '';
  @observable userName = '使用者';
  @observable userEmail = '';
  @observable userType = '';

  @observable isInitUserError = false;

  @computed get isLoading() {
    return this.queue.length > 0;
  }

  constructor() {
    makeObservable(this);

    this.init();
  }

  checkIsInQueue = (loadingName) => {
    return this.queue.some((item) => item === loadingName);
  };

  @action init = () => {
    // console.log('main store init');
  };

  @action initUser = async (router) => {
    if (mainStore.checkIsInQueue('user')) {
      return;
    }
    this.setLoading('user');
    try {
      const { user: { id, name, email, userType } } = await AuthorizationService.getProfile();
      runInAction(() => {
        this.userId = id;
        this.userName = name;
        this.userEmail = email;
        this.userType = userType;

        this.isInitUserError = false;
      });
    } catch (error) {

      runInAction(() => {
        this.isInitUserError = true;
        if (error.response?.status === 403) {
          AuthorizationService.logout();
          message.error(i18n.t('common_user_no_permission'));
        } else {
          message.error(i18n.t('common_user_not_found'));
          router.navigate('/login');
        }
      });
    } finally {
      this.setLoadingComplete('user');
    }
  };

  @action onLogout = (router) => {
    AuthorizationService.logout();

    this.userId = '';
    this.userName = '使用者';
    this.userEmail = '';
    this.userType = '';

    router.navigate('/login');
  };

  @action setLoading = (loadingName) => {
    this.queue.push(loadingName);
  };

  @action setLoadingComplete = (loadingName) => {
    this.queue = this.queue.filter((item) => item !== loadingName);
  };
}

const mainStore = new MainStore();

export default mainStore;
