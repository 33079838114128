import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Layout, Button, Popover, Empty } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import { Outlet, Link } from 'react-router-dom';
import Cookies from 'js-cookie';

import withRouter from 'src/components/withRouter';
import Loading from 'src/components/Loading';
import Auth from 'src/services/auth';

import mainStore from 'src/stores/mainStore';

import { CHANGE_PASSWORD_URL, SERVER_STATUS } from 'src/constants/config';

import i18n from 'src/i18n';
import Logo from 'src/assets/logo.svg';
import UserIcon from 'src/assets/common/user.svg';

import styles from './styles.module.scss';


const { Header, Content } = Layout;

@observer
class CustomizeLayout extends React.Component {

  componentDidMount() {
    mainStore.initUser(this.props.router);
  }

  _renderContent = () => {
    return (
      <div className={styles.userPopoverContainer}>
        <div className={styles.userContent}>
          <div className={styles.userName}>
            {mainStore.userName}
          </div>
          <div className={styles.userEmail}>
            {mainStore.userEmail}
          </div>
        </div>
        <a
          className={styles.btn}
          href={CHANGE_PASSWORD_URL[SERVER_STATUS]}
          target="_blank"
          rel="noreferrer"
        >
          {i18n.t('common_change_password')}
        </a>
        <div
          className={styles.btn}
          onClick={() => mainStore.onLogout(this.props.router)}
        >
          {i18n.t('common_logout')}
        </div>
      </div>
    );
  };

  render() {
    if (mainStore.isInitUserError) {
      return (
        <div className={styles.loadingUserContainer}>
          <div className={styles.innerContainer}>
            <Empty
              description={i18n.t('common_user_error_description')}
            >
              <Button href="/login">
                {i18n.t('common_user_error_btn')}
              </Button>
            </Empty>
          </div>
        </div>
      );
    }
    if (!mainStore.userType) {
      return (
        <div className={styles.loadingUserContainer}>
          <div className={styles.innerContainer}>
            <Empty
              description={i18n.t('common_loading')}
            />
          </div>
        </div>
      );
    }
    return (
      <>
        <Layout
          className={clsx(styles.main)}
        >
          <Header
            className={styles.header}
          >
            <div className={styles.headerContent}>

              <div className={styles.system}>
                <img src={Logo} alt={i18n.t('system_title')} />
                <div className={styles.divider} />
                <Button type="link">
                  <Link
                    to="/"
                    className={styles.btn}
                  >
                    {i18n.t('edm_list_page_title')}
                  </Link>
                </Button>
              </div>

              <div className={styles.actions}>
                <Button
                  type="link"
                  className={styles.btn}
                  href="https://www.i-buzz.com.tw"
                  target="_blank"
                >
                  {i18n.t('common_to_official_site')}
                </Button>
                <div className={styles.divider} />
                <Popover
                  content={this._renderContent}
                  placement="bottomRight"
                  overlayInnerStyle={{ padding: 0 }}
                >
                  <div className={styles.userDropdownContainer}>
                    <img src={UserIcon} alt="" />
                    <CaretDownFilled className={styles.caretDown} />
                  </div>
                </Popover>
              </div>
            </div>
          </Header>
          <Content className={clsx('main', styles.content)}>
            <Outlet />
          </Content>
        </Layout>
        <Loading />
      </>
    );
  }
}

CustomizeLayout.propTypes = {
  router: PropTypes.object
};

CustomizeLayout.defaultProps = {
  router: {}
};

export default withRouter(CustomizeLayout);
