import dayjs from 'dayjs';
import { LOG_STAGE, SEND_FREQUENCY_TYPE, EDM_TEMPLATE_OPTIONS } from 'src/constants/normal';

export default class EDMListItemModel {
  constructor(data) {
    const {
      id,
      name,
      projectId,
      emailStartDate,
      emailEndDate,
      isAutoSend,
      frequency,
      template,
      collaboratorIds,
      ownerId,
      lastChangeDate
      // status
    } = data;

    // const { project, edmAuto, edmManual } = status;

    this.id = id;
    this.name = name;
    this.projectId = projectId;
    this.time = `${dayjs(emailStartDate).tz('Asia/Taipei').format('YYYY/MM/DD')}-${dayjs(emailEndDate).tz('Asia/Taipei').format('YYYY/MM/DD')}`;
    this.autoSend = isAutoSend ? '是' : '否';
    this.frequency = frequency.sort((a, b) => Object.keys(SEND_FREQUENCY_TYPE).indexOf(a) - Object.keys(SEND_FREQUENCY_TYPE).indexOf(b)).map((el) => SEND_FREQUENCY_TYPE[el].slice(0, SEND_FREQUENCY_TYPE[el].length - 1)).join('/') ?? '';
    this.template = EDM_TEMPLATE_OPTIONS.find((t) => t.value === template).label;
    this.collaborator = collaboratorIds ?? '---';
    this.personInCharge = ownerId;
    this.lastChangedTime = dayjs(lastChangeDate).tz('Asia/Taipei').format('YYYY/MM/DD');

    this.project = {
      stage: LOG_STAGE.DONE,
      message: '---'
    };
    this.edmAuto = {
      stage: LOG_STAGE.DONE,
      message: '---'
    };
    this.edmManual = {
      stage: LOG_STAGE.DONE,
      message: '---'
    };
  }

  static fromRes(data) {
    return new EDMListItemModel(data);
  }
}
