import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { LINE_CHART_CONFIG_OPTIONS } from 'src/constants/chart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


@observer
class LineChart extends React.Component {
  render() {
    const { data, onCompletedCallback } = this.props;
    const key = uuidv4();
    return (
      <div style={{ width: '100%', display: 'flex', aspectRatio: 3 }} className="chartContainer">
        <Line
          key={key}
          id="mainBrandLine"
          options={{
            ...LINE_CHART_CONFIG_OPTIONS,
            animation: {
              onComplete: ({ initial }) => {
                if (initial) {
                  onCompletedCallback();
                }
              }
            }
          }}
          data={data}
        />
      </div>
    );
  }
}

LineChart.propTypes = {
  data: PropTypes.object,
  onCompletedCallback: PropTypes.func
};

LineChart.defaultProps = {
  data: {
    labels: [],
    datasets: []
  },
  onCompletedCallback: () => {}
};


export default LineChart;
