import { NEWSLETTER_STATUS_TYPE } from 'src/constants/normal';

export default class SingleEDMStatusModel {
  constructor(data) {
    const target = data[0];

    if (!target) {
      this.project = NEWSLETTER_STATUS_TYPE.Done;
      this.newsletterAuto = NEWSLETTER_STATUS_TYPE.Done;
      this.newsletterManual = NEWSLETTER_STATUS_TYPE.Done;
      return;
    }

    const {
      project,
      newsletter
    } = target;

    this.project = project?.stage ?? NEWSLETTER_STATUS_TYPE.Done;
    this.newsletterAuto = newsletter?.auto?.stage ?? NEWSLETTER_STATUS_TYPE.Done;
    this.newsletterManual = newsletter?.manual?.stage ?? NEWSLETTER_STATUS_TYPE.Done;
  }

  static fromRes(data) {
    return new SingleEDMStatusModel(data);
  }
}
