import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import mainStore from 'src/stores/mainStore';

import LoadingAnimation from 'src/assets/loading.svg';

import styles from './styles.module.scss';

const Loading = observer(() => {
  return (
    <div
      className={clsx(styles.loadingContainer, !mainStore.isLoading && styles.none)}
    >
      <div className={styles.curtain} />
      <div className={styles.loadingAnimationContainer}>
        <img
          src={LoadingAnimation}
          alt=""
          className={styles.loading}
        />
      </div>
    </div>
  );
});

export default Loading;
