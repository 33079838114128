import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';

import styles from './styles.module.scss';

const NoBorderButton = observer(({ children, ...rest }) => {
  return (
    <Button
      {...rest}
      type="link"
      className={styles.normalButton}
    >
      {children}
    </Button>
  );
});

NoBorderButton.propTypes = {

};

NoBorderButton.defaultProps = {

};

export default NoBorderButton;
