import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';

import styles from './styles.module.scss';

const NormalButton = observer(({ children, className, ...rest }) => {
  return (
    <Button
      {...rest}
      className={clsx(styles.normalButton, className)}
    >
      {children}
    </Button>
  );
});

NormalButton.propTypes = {

};

NormalButton.defaultProps = {

};

export default NormalButton;
