import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Input, Tooltip, Table, Switch, FloatButton } from 'antd';
import { Waypoint } from 'react-waypoint';

import { EDITABLE_TOPIC_KEYS, EDITABLE_TOPIC_TABLE } from 'src/constants/table';
import i18n from 'src/i18n';
import { isNegativeText } from 'src/utils';
import withRouter from 'src/components/withRouter';
import NormalButton from 'src/components/CustomizeButton/NormalButton';
import CustomizeSelect from 'src/components/CustomizeSelect';

import BackIcon from 'src/assets/common/back.svg';
import RefreshIcon from 'src/assets/common/refresh.svg';

import ResultPageViewModel from './viewModel';

import styles from './styles.module.scss';
import './customize.scss';

@observer
class ResultPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new ResultPageViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount(this.props.router.params);
  }

  componentWillUnmount() {
    this.viewModel.willUnmount();
  }

  render() {
    return (
      <>
        <div className={styles.pageContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.pageTitle}>
              <div>
                {`「${this.viewModel.newsletterName}」${i18n.t('result_page_title')}`}
              </div>
              <div className={styles.timeText}>
                {this.viewModel.timeText}
              </div>
            </div>
            <div className={styles.links}>
              <Link
                to={`/newsletter/${this.viewModel.newsletterId}`}
                className={styles.backBtn}
              >
                <img src={BackIcon} alt="" />
                {i18n.t('common_back_page')}
              </Link>
              <Link
                to={`/newsletter/${this.viewModel.newsletterId}/result/${this.viewModel.resultId}/preview`}
                className={styles.previewBtn}
                target="_blank"
              >
                {i18n.t('result_edit_page_preview')}
              </Link>
            </div>
          </div>
          {/* information: summary */}
          <div className={styles.summaryContainer}>
            <div className={styles.summaryTitle}>
              {i18n.t('result_edit_page_summary_title')}
            </div>
            <div className={styles.mainBrandSummaryContainer}>
              <div className={styles.summaryContent}>
                <div className={styles.summaryRow}>
                  <div className={styles.summaryTitle}>
                    {`${this.viewModel.mainBrand?.name ?? i18n.t('common_default_main_brand')} ${i18n.t('result_edit_page_today_volume')}`}
                  </div>
                  <div className={styles.summaryContent}>
                    {`${this.viewModel.mainBrandWOMCount} ${i18n.t('common_count_unit')}`}
                  </div>
                </div>
                {
                  (this.viewModel.manualStatistics.length === 0)
                  && (
                    <div className={styles.summaryRow}>
                      <div className={styles.summaryTitle}>
                        {`${this.viewModel.mainBrand?.name ?? i18n.t('common_default_main_brand')} ${i18n.t('result_edit_page_sentiment')}`}
                      </div>
                      <div className={styles.summaryContent}>
                        {
                          this.viewModel.mainBrandSentiments.map((sentiment, i) => {
                            return (
                              <span
                                key={sentiment.key}
                              >
                                {`${sentiment.name} ${sentiment.count} ${i18n.t('common_count_unit')} ${i !== 2 ? '/ ' : ''}`}
                              </span>
                            );
                          })
                        }
                      </div>
                    </div>
                  )
                }

                <div className={styles.summaryRow}>
                  <div className={styles.summaryTitle}>
                    {`${this.viewModel.mainBrand?.name ?? i18n.t('common_default_main_brand')} ${i18n.t('result_edit_page_top3_channel')}`}
                  </div>
                  <div className={styles.summaryContent}>
                    {
                      this.viewModel.mainBrandChannels.map((channel, i) => {
                        return (
                          <span
                            key={channel.id}
                          >
                            {`${channel.name} ${i + 1 !== this.viewModel.mainBrandChannels?.length ? '/ ' : ''}`}
                          </span>
                        );
                      })
                    }
                    {
                      this.viewModel.mainBrandChannels.length === 0
                      && (
                        <span>
                          {i18n.t('common_no_data')}
                        </span>
                      )
                    }
                  </div>
                </div>
                <div className={styles.summaryRow}>
                  <div className={styles.summaryTitle}>
                    {`${this.viewModel.mainBrand?.name ?? i18n.t('common_default_main_brand')} ${i18n.t('result_edit_page_top3_feature')}`}
                  </div>
                  <div className={styles.summaryContent}>
                    {
                      this.viewModel.mainBrandFeatures.map((feature, i) => {
                        return (
                          <span
                            key={feature.id}
                          >
                            {`${feature.name} ${i + 1 !== this.viewModel.mainBrandFeatures?.length ? '/ ' : ''}`}
                          </span>
                        );
                      })
                    }
                    {
                      this.viewModel.mainBrandFeatures.length === 0
                      && (
                        <span>
                          {i18n.t('common_no_data')}
                        </span>
                      )
                    }
                  </div>
                </div>
              </div>
              <NormalButton
                onClick={this.viewModel.onRefreshSummary}
                icon={<img src={RefreshIcon} alt="" className={styles.refreshIcon} />}
                className={styles.refreshBtn}
              >
                {i18n.t('result_edit_page_calculate_btn')}
              </NormalButton>

            </div>
            <div className={styles.manualSummary}>
              <div className={styles.manualTitle}>
                <div className={styles.titleText}>
                  {`${i18n.t('result_edit_page_manual_statistic')} - ${i18n.t('result_edit_page_topic_table_eval_type')}`}
                </div>
                <Tooltip
                  title={i18n.t('result_edit_page_manual_tooltip')}
                  overlayStyle={{ maxWidth: 'unset', whiteSpace: 'nowrap' }}
                  placement="bottomLeft"
                >
                  <ExclamationCircleOutlined />
                </Tooltip>
              </div>

              {
                this.viewModel.manualStatistics.map((item) => {
                  return (
                    <div
                      className={styles.manualRow}
                      key={item.id}
                    >
                      <div className={styles.rowTitle}>
                        {`${item.name}：`}
                      </div>
                      <div className={styles.rowContent}>
                        {
                          item.rating.map((rating, i) => {
                            return (
                              <span key={rating.id}>
                                {`${rating.name} ${rating.count} ${i18n.t('common_count_unit')} ${i !== item.rating.length - 1 ? '/ ' : ''}`}
                              </span>
                            );
                          })
                        }
                      </div>
                    </div>
                  );
                })
              }
            </div>
            <div className={styles.memoContainer}>
              <div className={styles.memoTitle}>
                {i18n.t('result_edit_page_memo')}
              </div>
              <Input.TextArea
                className={styles.input}
                placeholder={i18n.t('result_edit_page_memo_placeholder')}
                autoSize={{ minRows: 2 }}
                showCount
                maxLength={600}
                value={this.viewModel.memo}
                onChange={this.viewModel.onMemoChange}
                onBlur={this.viewModel.onMemoEditEnd}
                disabled={this.viewModel.isMemoUpdating}
              />
            </div>
            {
              this.viewModel.isSummaryCalculate
              && (
                <div className={styles.curtain} />
              )
            }
          </div>
          {/* information: topics */}
          <div className={styles.topicsContainer}>
            <div className={styles.actionRow}>
              <div className={styles.title}>
                {i18n.t('result_edit_page_edit_topic')}
              </div>
              <CustomizeSelect
                options={this.viewModel.subjectList}
                value={this.viewModel.selectedSubject}
                onChange={this.viewModel.onSubjectSelect}
                className={styles.subjectSelect}
                placeholder={i18n.t('result_edit_page_select_topic_placeholder')}
              />

            </div>
            <div className={styles.tableContainer}>
              <Table
                dataSource={this.viewModel.topics}
                className={clsx('topicListTable')}
                rowKey={(data) => data.id}
                scroll={{ x: 1784 }} // x: 1920 - 80(outer padding) - 56(inner padding)
                pagination={false}
                style={{ width: this.viewModel.width - 128 }}
              >
                {
                  EDITABLE_TOPIC_TABLE.map((column) => {
                    return (
                      <Table.Column
                        title={() => {
                          if (column.key === 'brand' && this.viewModel.searchAngle !== 'brand') {
                            return i18n.t('common_brand_category');
                          }
                          return column.title;
                        }}
                        dataIndex={column.dataIndex}
                        key={column.key}
                        width={column.width}
                        fixed={column.fixed}
                        render={(text, record, index) => {
                          if (column.key === EDITABLE_TOPIC_KEYS.Order) {
                            return (
                              <div className={styles.row}>
                                {index + 1}
                              </div>
                            );
                          }
                          if (column.key === EDITABLE_TOPIC_KEYS.Title) {
                            return (
                              <>
                                <a
                                  href={record.url}
                                  className={styles.oneLineRow}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {text}
                                </a>
                                {
                                  (index === this.viewModel.topics.length - 1 && this.viewModel.hasNextPage)
                                  && (
                                    <Waypoint
                                      onEnter={this.viewModel.getPagingTopics}
                                    />
                                  )

                                }
                              </>

                            );
                          }
                          if (column.key === EDITABLE_TOPIC_KEYS.Sentiment) {
                            return (
                              <div
                                className={
                                  clsx(
                                    styles.dot,
                                    record.sentiment === 1 && styles.positive,
                                    record.sentiment === 0 && styles.neutral,
                                    record.sentiment === -1 && styles.negative
                                  )
                                }
                              />
                            );
                          }
                          if (column.key === EDITABLE_TOPIC_KEYS.EvalType) {
                            if (this.viewModel.selectedSubjectContent.hasEvaluation) {
                              return (
                                <Observer>
                                  {() => {
                                    return (
                                      <CustomizeSelect
                                        value={record.evalType}
                                        options={this.viewModel.ratingTags}
                                        onChange={(value) => this.viewModel.onEvalTypeChange(value, record.id, record.brandId)}
                                        className={clsx(styles.select, isNegativeText(this.viewModel.ratingTagsObject[record.evalType]) && styles.negative)}
                                        placeholder={i18n.t('result_edit_page_topic_table_eval_type_placeholder')}
                                        popupClassName="resultPopup"
                                      />
                                    );
                                  }}
                                </Observer>

                              );
                            }
                            return (
                              <div className={styles.row}>
                                ---
                              </div>
                            );
                          }
                          if (column.key === EDITABLE_TOPIC_KEYS.TopicAttr) {
                            if (this.viewModel.selectedSubjectContent.hasEvaluation) {
                              return (
                                <Observer>
                                  {() => {
                                    return (
                                      <CustomizeSelect
                                        value={record.topicAttr}
                                        options={this.viewModel.topicTags}
                                        onChange={(value) => this.viewModel.onTopicAttrChange(value, record.id, record.brandId)}
                                        className={styles.select}
                                        placeholder={i18n.t('result_edit_page_topic_table_topic_attribute_placeholder')}
                                      />
                                    );
                                  }}
                                </Observer>

                              );
                            }
                            return (
                              <div className={styles.row}>
                                ---
                              </div>
                            );
                          }
                          if (column.key === EDITABLE_TOPIC_KEYS.ReportAdvice) {
                            if (this.viewModel.selectedSubjectContent.hasEvaluation) {
                              return (
                                <Observer>
                                  {() => {
                                    return (
                                      <CustomizeSelect
                                        value={record.reportAdvice}
                                        options={this.viewModel.suggestionTags}
                                        onChange={(value) => this.viewModel.onReportAdviceChange(value, record.id, record.brandId)}
                                        className={styles.select}
                                        placeholder={i18n.t('result_edit_page_topic_table_report_advice_placeholder')}
                                      />
                                    );
                                  }}
                                </Observer>

                              );
                            }
                            return (
                              <div className={styles.row}>
                                ---
                              </div>
                            );
                          }
                          if (column.key === EDITABLE_TOPIC_KEYS.DepartmentAttr) {
                            if (this.viewModel.selectedSubjectContent.hasEvaluation) {
                              return (
                                <Observer>
                                  {() => {
                                    return (
                                      <CustomizeSelect
                                        value={record.departmentAttr}
                                        options={this.viewModel.departmentTags}
                                        onChange={(value) => this.viewModel.onDepartmentAttrChange(value, record.id, record.brandId)}
                                        className={styles.select}
                                        placeholder={i18n.t('result_edit_page_topic_table_department_placeholder')}
                                      />
                                    );
                                  }}
                                </Observer>

                              );
                            }
                            return (
                              <div className={styles.row}>
                                ---
                              </div>
                            );
                          }
                          if (column.key === EDITABLE_TOPIC_KEYS.ShouldSend) {
                            return (
                              <Observer>
                                {() => {
                                  return (
                                    <Switch
                                      checked={!record.shouldSend}
                                      className={clsx(styles.switch, !record.shouldSend && styles.activeSwitch)}
                                      onChange={(boolean) => this.viewModel.onShouldSendChange(!boolean, record.id, record.brandId)}
                                    />
                                  );
                                }}
                              </Observer>

                            );
                          }
                          return (
                            <div className={styles.row}>
                              {text}
                            </div>
                          );
                        }}
                      />
                    );
                  })
                }
              </Table>
            </div>
          </div>

        </div>
        <FloatButton.BackTop
          visibilityHeight={100}
          target={() => document.querySelector('.main')}
          tooltip="回頂部"
        />
      </>
    );
  }
}

ResultPage.propTypes = {
  router: PropTypes.object
};

ResultPage.defaultProps = {
  router: {}
};

export default withRouter(ResultPage);
