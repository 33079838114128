import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { Layout, Table, FloatButton } from 'antd';
import { Waypoint } from 'react-waypoint';

import withRouter from 'src/components/withRouter';

import { OPEN_EDIT_TOPIC_TABLE, OPEN_EDIT_TOPIC_KEYS, CLOSE_EDIT_TOPIC_TABLE, CLOSE_EDIT_TOPIC_KEYS, OPEN_EDIT_TOPIC_WITH_LEVEL_ONE_TABLE, CLOSE_EDIT_TOPIC_WITH_LEVEL_ONE_TABLE } from 'src/constants/table';

import i18n from 'src/i18n';
import AnnouncementIcon from 'src/assets/common/announcement.svg';
import { isNegativeText } from 'src/utils';

import TopicPreviewPageViewModel from './viewModel';

import styles from './styles.module.scss';

const { Header, Content } = Layout;

@observer
class TopicPreviewPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new TopicPreviewPageViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount(this.props.router.params);
  }

  render() {
    return (
      <>
        <div className={styles.pageContainer}>
          <div className={styles.contentContainer}>
            <div className={styles.title}>
              <img src={AnnouncementIcon} alt="" className={styles.icon} />
              {`${this.viewModel.newsletterSubject} / ${i18n.t('preview_page_topics_title')}「${this.viewModel.subjectName}」`}
            </div>
            <div className={styles.topicContainer}>
              <div className={styles.tableContainer}>
                <Table
                  dataSource={this.viewModel.topicList}
                  className={clsx('previewTable')}
                  rowKey={(data) => data.id}
                  pagination={false}
                >
                  {
                    this.viewModel.subjectHasEvaluation
                      ? (
                        <>
                          {
                            (this.viewModel.subjectNeedShowBrand ? OPEN_EDIT_TOPIC_WITH_LEVEL_ONE_TABLE : OPEN_EDIT_TOPIC_TABLE).map((column) => {
                              return (
                                <Table.Column
                                  title={column.title}
                                  dataIndex={column.dataIndex}
                                  key={column.key}
                                  width={column.key === CLOSE_EDIT_TOPIC_KEYS.Title ? '30%' : column.key}
                                  fixed={column.fixed}
                                  render={(text, record, index) => {
                                    if (column.key === CLOSE_EDIT_TOPIC_KEYS.Order) {
                                      return (
                                        <div className={styles.row}>
                                          {index + 1}
                                        </div>
                                      );
                                    }
                                    if (column.key === CLOSE_EDIT_TOPIC_KEYS.Title) {
                                      return (
                                        <>
                                          <a
                                            href={record.url}
                                            className={styles.oneLineRow}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {text}
                                          </a>
                                          {
                                            (index === this.viewModel.topicList.length - 1 && this.viewModel.hasNextPage)
                                            && (
                                              <Waypoint
                                                onEnter={this.viewModel.getPagingTopics}
                                              />
                                            )

                                          }
                                        </>
                                      );
                                    }
                                    if (column.key === OPEN_EDIT_TOPIC_KEYS.EvalType) {
                                      return (
                                        <Observer>
                                          {() => {
                                            return (
                                              <div className={clsx(styles.row, isNegativeText(this.viewModel.tagPoolMap.get(record[`${column.key}`])) && styles.negative)}>
                                                {this.viewModel.tagPoolMap.get(record[`${column.key}`]) ?? '無'}
                                              </div>
                                            );
                                          }}
                                        </Observer>

                                      );
                                    }
                                    if (column.key === OPEN_EDIT_TOPIC_KEYS.TopicAttr) {
                                      return (
                                        <Observer>
                                          {() => {
                                            return (
                                              <div className={styles.row}>
                                                {this.viewModel.tagPoolMap.get(record[`${column.key}`]) ?? '無'}
                                              </div>
                                            );
                                          }}
                                        </Observer>

                                      );
                                    }
                                    if (column.key === OPEN_EDIT_TOPIC_KEYS.ReportAdvice) {
                                      return (
                                        <Observer>
                                          {() => {
                                            return (
                                              <div className={styles.row}>
                                                {this.viewModel.tagPoolMap.get(record[`${column.key}`]) ?? '無'}
                                              </div>
                                            );
                                          }}
                                        </Observer>

                                      );
                                    }
                                    if (column.key === OPEN_EDIT_TOPIC_KEYS.DepartmentAttr) {
                                      return (
                                        <Observer>
                                          {() => {
                                            return (
                                              <div className={styles.row}>
                                                {this.viewModel.tagPoolMap.get(record[`${column.key}`]) ?? '無'}
                                              </div>
                                            );
                                          }}
                                        </Observer>
                                      );
                                    }
                                    return (
                                      <div className={styles.row}>
                                        {text}
                                      </div>
                                    );
                                  }}
                                />
                              );
                            })
                          }
                        </>
                      )
                      : (
                        <>
                          {
                            (this.viewModel.subjectNeedShowBrand ? CLOSE_EDIT_TOPIC_WITH_LEVEL_ONE_TABLE : CLOSE_EDIT_TOPIC_TABLE).map((column) => {
                              return (
                                <Table.Column
                                  title={column.title}
                                  dataIndex={column.dataIndex}
                                  key={column.key}
                                  width={column.width}
                                  fixed={column.fixed}
                                  render={(text, record, index) => {
                                    if (column.key === CLOSE_EDIT_TOPIC_KEYS.Order) {
                                      return (
                                        <div className={styles.row}>
                                          {index + 1}
                                        </div>
                                      );
                                    }
                                    if (column.key === CLOSE_EDIT_TOPIC_KEYS.Sentiment) {
                                      return (
                                        <div
                                          className={
                                            clsx(
                                              styles.dot,
                                              record.sentiment === 1 && styles.positive,
                                              record.sentiment === 0 && styles.neutral,
                                              record.sentiment === -1 && styles.negative
                                            )
                                          }
                                        />
                                      );
                                    }
                                    if (column.key === CLOSE_EDIT_TOPIC_KEYS.Title) {
                                      return (
                                        <>
                                          <a
                                            href={record.url}
                                            className={styles.oneLineRow}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {text}
                                          </a>
                                          {
                                            (index === this.viewModel.topicList.length - 1 && this.viewModel.hasNextPage)
                                            && (
                                              <Waypoint
                                                onEnter={this.viewModel.getPagingTopics}
                                              />
                                            )

                                          }
                                        </>
                                      );
                                    }
                                    return (
                                      <div className={styles.row}>
                                        {text}
                                      </div>
                                    );
                                  }}
                                />
                              );
                            })
                          }
                        </>
                      )
                  }
                </Table>
              </div>
            </div>
          </div>
        </div>
        <FloatButton.BackTop
          target={() => document.querySelector('.main')}
          visibilityHeight={100}
        />
      </>
    );
  }
}

TopicPreviewPage.propTypes = {
  router: PropTypes.object
};

TopicPreviewPage.defaultProps = {
  router: {}
};

export default withRouter(TopicPreviewPage);
