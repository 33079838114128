export default class ProjectListModel {
  constructor(list) {
    this.list = list.map((project) => {
      const {
        autorun,
        owner,
        meta,
        _id
      } = project;

      return {
        id: _id,
        name: meta?.name ?? '',
        angle: meta?.searchAngle,
        teamId: owner.team?.id,
        userId: owner.user?.id,
        startAt: autorun?.startDate,
        endAt: autorun?.endDate
      };
    });
  }

  static fromRes(data) {
    return new ProjectListModel(data);
  }
}
