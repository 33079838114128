import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Modal, Button } from 'antd';

import i18n from 'src/i18n';

import styles from './styles.module.scss';


@observer
class ErrorModal extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <Modal
        open={viewModel.isOpen}
        centered
        footer={null}
        closeIcon={null}
        width={500}
        onCancel={viewModel.onClose}
        styles={{ content: { padding: 0 } }}
      >
        <div className={styles.container}>
          <div className={styles.title}>
            {viewModel.errorContent}
          </div>
          <div className={styles.footer}>
            <Button
              className={styles.cancelBtn}
              onClick={viewModel.onClose}
            >
              {i18n.t('common_cancel')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

ErrorModal.propTypes = {
  viewModel: PropTypes.object.isRequired
};

ErrorModal.defaultProps = {
};

export default ErrorModal;
