import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import styles from './styles.module.scss';

const CustomizeSelect = observer(({ container, className, ...rest }) => {
  const containerName = container ?? 'main';
  const [isFocus, setFocus] = useState(false);
  const ref = useRef();
  const blurFunction = useCallback(() => {
    if (ref.current) {
      ref.current.blur();
    }
  }, []);
  const onFocus = useCallback(() => {
    setFocus(true);
    const mainContainer = document.querySelector(`.${containerName}`);
    mainContainer.addEventListener('scroll', blurFunction);
  }, [blurFunction, containerName]);
  const onBlur = useCallback(() => {
    const mainContainer = document.querySelector(`.${containerName}`);
    mainContainer.removeEventListener('scroll', blurFunction);
  }, [blurFunction, containerName]);
  const onUmount = useCallback(() => {
    if (isFocus) {
      onBlur();
    }
  }, [isFocus, onBlur]);


  useEffect(() => {
    return () => {
      onUmount();
    };

  });

  return (
    <Select
      ref={ref}
      onFocus={onFocus}
      onBlur={onBlur}
      popupMatchSelectWidth={false}
      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
      style={{ minWidth: rest.mode ? rest.placeholder.length * 17 + 24 : 'none' }}
      className={clsx(rest.disabled ? styles.disabled : styles.active, className)}
      {...rest}
    />
  );
});

CustomizeSelect.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

CustomizeSelect.defaultProps = {
  disabled: false
};

export default CustomizeSelect;
