import dayjs from 'dayjs';

export const delay = (ms) => {
  return new Promise((res, rej) => {
    setTimeout(() => {
      res();
    }, ms);
  });
};

export const isNegativeText = (text) => {
  if (!text) {
    return false;
  }
  return ['差', '負評', '負面', 'Negative'].includes(text.trim());
};

export const formatDayjsWithWeekday = (value) => {
  const weekdayList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const weekdayIndex = dayjs(value).tz('Asia/Taipei').isoWeekday();
  const weekdayText = weekdayList[weekdayIndex - 1];

  return `${dayjs(value).tz('Asia/Taipei').format('YYYY/MM/DD')} ${weekdayText}.`;
};

export const resizeWords = (list, size = 's') => {
  const maxNumber = Math.max(...list.map((el) => el.count));
  const minNumber = Math.min(...list.map((el) => el.count));

  const getNew = (num) => {
    const ratio = size === 's' ? 3 : 4;
    const basicSize = size === 's' ? 20 : 50;
    if (maxNumber === minNumber) {
      return basicSize * ratio;
    }
    return ((num - minNumber) * (ratio / (maxNumber - minNumber)) + 1) * basicSize;
  };

  return list.map((word) => ({
    ...word,
    count: getNew(word.count)
  }));
};

export const getPMSystemHost = () => {
  if (/:\d{4,}$/.test(window.location.origin)) {
    const r = window.location.origin.split(/:\d{4,}/);
    if (r[0]) {
      // return `${r[0]}:9003`;
      return 'https://buzz-pm-d90d217a.miraclemobile.com.tw/';
    }
  }

  // dev
  if (/d90d217a/.test(window.location.host)) {
    return 'https://buzz-pm-d90d217a.miraclemobile.com.tw/';
  }

  // pro
  return 'https://pm.i-buzz-system.com/';
};
