import DirectClientMarketing from './DirectClientMarketing';
import DirectClientMarketingViewModel from './DirectClientMarketing/viewModel';


export default class PreviewTemplateFactory {
  static createViewModel(data) {
    switch (data.type) {
      case 'edm':
        return new DirectClientMarketingViewModel(data);
      default:
        return null;
    }
  }

  static generateView(viewModel) {
    switch (viewModel.edmType) {
      case 'edm':
        return <DirectClientMarketing viewModel={viewModel} />;
      default:
        return null;
    }
  }
}
