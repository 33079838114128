import { request, getHost, getHeaders } from './utils';

export const getEDMList = (params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/newsletter`,
    headers: getHeaders(),
    params: {
      order: 'desc',
      limit: 20,
      ...params
    }
  };
  return request(options);
};

export const getEDMDetail = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/newsletter/${id}`,
    headers: getHeaders()
  };
  return request(options);
};

export const getEDMDetailByToken = (token) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/newsletter/token/${token}`,
    headers: getHeaders()
  };
  return request(options);
};

export const createEDM = (data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/newsletter`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const updateEDM = (id, data) => {
  const options = {
    method: 'put',
    url: `${getHost()}/api/v1/newsletter/${id}`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const deleteEDM = (id) => {
  const options = {
    method: 'delete',
    url: `${getHost()}/api/v1/newsletter/${id}`,
    headers: getHeaders()
  };
  return request(options);
};

export const updateResultTopicsByTime = (id, { gte, lte }) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/newsletter/${id}/reanalysis`,
    headers: getHeaders(),
    data: {
      startDate: gte,
      endDate: lte
    }
  };
  return request(options);
};

export const reanalysisNewsletter = (id, date) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/newsletter/${id}`,
    headers: getHeaders(),
    data: {
      date
    }
  };
  return request(options);
};

export const getNewsLetterResult = (id, params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/newsletter/${id}/result`,
    headers: getHeaders(),
    params
  };
  return request(options);
};

export const getDownloadTopic = (id, date) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/newsletter/${id}/post`,
    headers: getHeaders(),
    params: {
      all: true
    },
    data: {
      date,
      excludeNotSend: false
    }
  };
  return request(options);
};

export const getSingleEDMStatus = (id) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/newsletter/logs`,
    headers: getHeaders(),
    data: [id]
  };
  return request(options);
};

export const getMultipleEDMStatus = (ids) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/newsletter/logs`,
    headers: getHeaders(),
    data: ids
  };
  return request(options);
};

export const getEDMStatusByTime = (id, date) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/newsletter/${id}/log`,
    headers: getHeaders(),
    data: {
      date
    }
  };
  return request(options);
};
