import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Modal, Button } from 'antd';
import { TOPIC_ATTRIBUTE_TYPE_TC } from 'src/constants/normal';

import i18n from 'src/i18n';

import styles from './styles.module.scss';


@observer
class CheckDeleteModal extends React.Component {
  constructor() {
    super();

  }

  render() {
    const { viewModel } = this.props;
    if (!viewModel.type.trim()) {
      return null;
    }
    return (
      <Modal
        open={viewModel.isOpen}
        centered
        footer={null}
        closeIcon={null}
        width={500}
        onCancel={viewModel.onClose}
        styles={{ content: { padding: 0 } }}
      >
        <div className={styles.container}>
          {
            viewModel.type === 'topic'
            && (
              <>
                <div className={styles.title}>
                  {
                    i18n.t('edm_setting_delete_modal_topic_title')
                      .replace('%level%', viewModel.value?.level)
                      .replace('%topic%', viewModel.value?.topic)
                  }
                </div>
                <div className={styles.content}>
                  {i18n.t('edm_setting_delete_modal_topic_description')}
                </div>
              </>
            )
          }
          {
            viewModel.type === 'email'
            && (
              <div className={styles.title}>
                {
                  i18n.t('edm_setting_delete_modal_email_title')
                    .replace('%email%', viewModel.value?.email)
                }
              </div>
            )
          }
          {
            viewModel.type === 'tag'
            && (
              <>
                <div className={styles.title}>
                  {
                    i18n.t('edm_setting_delete_modal_tag_title')
                      .replace('%type%', TOPIC_ATTRIBUTE_TYPE_TC[viewModel.value?.type] ?? '')
                      .replace('%tag%', viewModel.value?.tag)
                  }
                </div>
                <div className={styles.content}>
                  {i18n.t('edm_setting_delete_modal_tag_description')}
                </div>
              </>
            )
          }
          <div className={styles.footer}>
            <Button
              className={styles.confirmBtn}
              onClick={viewModel.onConfirm}
            >
              {i18n.t('common_confirm')}
            </Button>
            <Button
              className={styles.cancelBtn}
              onClick={viewModel.onClose}
            >
              {i18n.t('common_cancel')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

CheckDeleteModal.propTypes = {
  viewModel: PropTypes.object.isRequired
};

CheckDeleteModal.defaultProps = {
  // viewModel: {}
};

export default CheckDeleteModal;
