import API from 'src/apis';

import EDMListItemModel from 'src/models/response/EDMListItem';
import ResultListModel from 'src/models/response/ResultList';
import DownloadTopicsModel from 'src/models/response/DownloadTopics';
import SingleEDMStatusModel from 'src/models/response/SingleEDMStatus';
import MultipleEDMStatusModel from 'src/models/response/MultipleEDMStatus';
import EDMStatusByTimeModel from 'src/models/response/EDMStatusByTime';

export default class EDMService {
  static async getEDMList(params) {
    const res = await API.edm.getEDMList(params);

    const { list, anchor, count } = res.data;

    return { list: list.map((item) => EDMListItemModel.fromRes(item)), anchor, count };
  }

  static async getDownloadTopic(id, date) {
    const res = await API.edm.getDownloadTopic(id, date);

    return DownloadTopicsModel.fromRes(res.data.result);
  }

  static async getEDMDetail(id) {
    const res = await API.edm.getEDMDetail(id);

    return res.data.result;
  }

  static async getEDMDetailByToken(token) {
    const res = await API.edm.getEDMDetailByToken(token);

    return res.data.result;
  }

  static async createEDM(data) {
    await API.edm.createEDM(data);
  }

  static async updateEDM(id, data) {
    await API.edm.updateEDM(id, data);
  }

  static async deleteEDM(id) {
    await API.edm.deleteEDM(id);
  }

  static async updateResultTopicsByTime(id, date) {
    await API.edm.updateResultTopicsByTime(id, date);
  }

  static async reanalysisNewsletter(id, date) {
    await API.edm.reanalysisNewsletter(id, date);
  }

  static async getNewsLetterResult(id, params) {
    const res = await API.edm.getNewsLetterResult(id, params);

    const { list, anchor, count } = res.data;

    return { list: list.map((item) => ResultListModel.fromRes(item)), anchor, count };
  }

  static async getSingleEDMStatus(id) {
    const res = await API.edm.getSingleEDMStatus(id);

    return SingleEDMStatusModel.fromRes(res.data.result);
  }

  static async getMultipleEDMStatus(ids) {
    const res = await API.edm.getMultipleEDMStatus(ids);

    return MultipleEDMStatusModel.fromRes(res.data.result);
  }

  static async getEDMStatusByTime(id, date) {
    const res = await API.edm.getEDMStatusByTime(id, date);

    return EDMStatusByTimeModel.fromRes(res.data.result);
  }
}
