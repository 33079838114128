import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Table, FloatButton, Empty } from 'antd';
import { MailOutlined } from '@ant-design/icons';

import BookIcon from 'src/assets/common/bookIcon.svg';
import TrendIcon from 'src/assets/common/trendIcon.svg';
import RankingIcon from 'src/assets/common/rankingIcon.svg';
import AnnouncementIcon from 'src/assets/common/announcement.svg';
import CloudIcon from 'src/assets/common/cloud.svg';
import TopicIcon from 'src/assets/common/message.svg';

import ErrorModal from 'src/components/ErrorModal';

import { OPEN_EDIT_TOPIC_TABLE, CLOSE_EDIT_TOPIC_TABLE, OPEN_EDIT_TOPIC_KEYS, CLOSE_EDIT_TOPIC_KEYS, FEATURE_KEYWORD_KEYS, FEATURE_KEYWORD_TABLE, OPEN_EDIT_TOPIC_WITH_LEVEL_ONE_TABLE, CLOSE_EDIT_TOPIC_WITH_LEVEL_ONE_TABLE } from 'src/constants/table';
import { NUMBER_TC } from 'src/constants/normal';
import i18n from 'src/i18n';
import { formatDayjsWithWeekday, isNegativeText } from 'src/utils';

import withRouter from 'src/components/withRouter';

import LineChart from 'src/components/LineChart';
import BarChart from 'src/components/BarChart';
import WordCloud from 'src/components/WordCloud';

import styles from './styles.module.scss';
import './customize.scss';

@observer
class DirectClientMarketing extends React.Component {
  componentDidMount() {
    this.props.viewModel.didMount();
  }

  render() {
    const { viewModel, router } = this.props;
    return (
      <>
        <div className={styles.pageContainer}>
          <div className={styles.previewTitle}>
            <div className={styles.titleText}>
              {i18n.t('preview_page_title')}
            </div>
            <div className={styles.dateText}>
              {`${formatDayjsWithWeekday(viewModel.startDate)}${viewModel.notDaily ? ` - ${formatDayjsWithWeekday(viewModel.endDate)}` : ''}`}
            </div>
          </div>

          {/* 每日摘要 */}
          <div className={styles.summaryContainer}>
            <div className={styles.summaryTitle}>
              <img src={BookIcon} alt="" />
              {i18n.t('preview_page_summary_section_title')}
            </div>
            <div className={styles.mainBrandSummaryContainer}>
              <div className={styles.summaryContent}>
                <div className={styles.summaryRow}>
                  <div className={styles.summaryTitle}>
                    {`${viewModel.mainBrand?.name ?? i18n.t('common_default_main_brand')} ${i18n.t('preview_page_today_volume')}`}
                  </div>
                  <div className={styles.summaryContent}>
                    {`${viewModel.mainBrandWOMCount} ${i18n.t('common_count_unit')}`}
                  </div>
                </div>
                {
                  viewModel.manualStatistics.length === 0
                  && (
                    <div className={styles.summaryRow}>
                      <div className={styles.summaryTitle}>
                        {`${viewModel.mainBrand?.name ?? i18n.t('common_default_main_brand')} ${i18n.t('preview_page_sentiment')}`}
                      </div>
                      <div className={styles.summaryContent}>
                        {
                          viewModel.mainBrandSentiments.map((sentiment, i) => {
                            return (
                              <span
                                key={sentiment.key}
                              >
                                {`${sentiment.name} ${sentiment.count} ${i18n.t('common_count_unit')} ${i !== 2 ? '/ ' : ''}`}
                              </span>
                            );
                          })
                        }
                      </div>
                    </div>
                  )
                }
                <div className={styles.summaryRow}>
                  <div className={styles.summaryTitle}>
                    {`${viewModel.mainBrand?.name ?? i18n.t('common_default_main_brand')} ${i18n.t('preview_page_top3_channel')}`}
                  </div>
                  <div className={styles.summaryContent}>
                    {
                      viewModel.mainBrandChannels.map((channel, i) => {
                        return (
                          <span
                            key={channel.id}
                          >
                            {`${channel.name} ${i + 1 !== viewModel.mainBrandChannels?.length ? '/ ' : ''}`}
                          </span>
                        );
                      })
                    }
                    {
                      viewModel.mainBrandChannels.length === 0
                      && (
                        <span>
                          {i18n.t('common_no_data')}
                        </span>
                      )
                    }
                  </div>
                </div>
                <div className={styles.summaryRow}>
                  <div className={styles.summaryTitle}>
                    {`${viewModel.mainBrand?.name ?? i18n.t('common_default_main_brand')} ${i18n.t('preview_page_top3_feature')}`}
                  </div>
                  <div className={styles.summaryContent}>
                    {
                      viewModel.mainBrandFeatures.map((feature, i) => {
                        return (
                          <span
                            key={feature.id}
                          >
                            {`${feature.name} ${i + 1 !== viewModel.mainBrandFeatures?.length ? '/ ' : ''}`}
                          </span>
                        );
                      })
                    }
                    {
                      viewModel.mainBrandFeatures.length === 0
                      && (
                        <span>
                          {i18n.t('common_no_data')}
                        </span>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.manualSummary}>
              <div className={styles.manualTitle}>
                <div className={styles.titleText}>
                  {`${i18n.t('preview_page_manual_statistic')} - ${i18n.t('preview_page_topic_table_eval_type')}`}
                </div>
              </div>

              {
                viewModel.manualStatistics.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={styles.manualRow}
                    >
                      <div className={styles.rowTitle}>
                        {`${item.name}：`}
                      </div>
                      <div className={styles.rowContent}>
                        {
                          item.rating.map((rating, i) => {
                            return (
                              <span key={rating.id}>
                                {`${rating.name} ${rating.count} ${i18n.t('common_count_unit')} ${i !== item.rating.length - 1 ? '/ ' : ''}`}
                              </span>
                            );
                          })
                        }
                      </div>
                    </div>
                  );
                })
              }
            </div>
            <div className={styles.memoContainer}>
              <div className={styles.memoTitle}>
                {i18n.t('preview_page_memo')}
              </div>
              <div className={styles.memoContent}>
                {viewModel.memo.trim() ? viewModel.memo : '無'}
              </div>
            </div>
          </div>
          {/* 聲量分析 */}
          <div className={styles.volumeContainer}>
            <div className={styles.titleContainer}>
              <img src={BookIcon} alt="" />
              {i18n.t('preview_page_volume_analysis_title')}
            </div>
            <div className={styles.volumeContent}>
              <div className={styles.chartContainer}>
                <div className={styles.chartTitle}>
                  <img src={TrendIcon} alt="" />
                  {`${viewModel.mainBrand?.name ?? i18n.t('common_default_main_brand')}${i18n.t('preview_page_main_brand_trend')}`}
                </div>
                <div className={styles.chartCompareContainer}>
                  <div>
                    {
                      `${i18n.t('preview_page_date_current')} ${dayjs(viewModel.chartStartDate).tz('Asia/Taipei').format('YYYY/MM/DD')} - ${dayjs(viewModel.chartEndDate).tz('Asia/Taipei').format('YYYY/MM/DD')}`
                    }
                  </div>
                  <div>
                    {
                      `${i18n.t('preview_page_date_previous')} ${dayjs(viewModel.previousChartStartDate).tz('Asia/Taipei').format('YYYY/MM/DD')} - ${dayjs(viewModel.previousChartEndDate).tz('Asia/Taipei').format('YYYY/MM/DD')}`
                    }
                  </div>
                  <div>
                    {
                      `${i18n.t('preview_page_volume_calculate')} ${viewModel.currentTotal - viewModel.previousTotal}`
                    }
                  </div>
                </div>
                <LineChart data={viewModel.mainBrandTrend} />
              </div>
              <div className={styles.chartContainer}>
                <div className={styles.chartTitle}>
                  <img src={RankingIcon} alt="" />
                  {i18n.t('preview_page_competitor_ranking')}
                </div>
                <div className={styles.chartDate}>
                  {
                    `${i18n.t('preview_page_date')} ${dayjs(viewModel.chartStartDate).tz('Asia/Taipei').format('YYYY/MM/DD')} - ${dayjs(viewModel.chartEndDate).tz('Asia/Taipei').format('YYYY/MM/DD')}`
                  }
                </div>
                <BarChart data={viewModel.mainBrandRanking} searchAngle={viewModel.searchAngle} />
              </div>
            </div>
          </div>
          {/* 話題列表 */}
          <div className={styles.topicsContainer}>
            <div className={styles.titleContainer}>
              <img src={BookIcon} alt="" />
              {i18n.t('preview_page_topics_title')}
            </div>

            {
              viewModel.subjectList.map((subject, i) => {
                return (
                  <div
                    key={subject.id}
                    className={styles.topicContent}
                  >
                    <div className={styles.topicTitle}>
                      <img src={AnnouncementIcon} alt="" />
                      {`${i18n.t('preview_page_subject_prefix')}${NUMBER_TC[i]}：${subject.name}`}
                    </div>
                    <div className={styles.topicTableContainer}>
                      <Table
                        dataSource={subject.topics}
                        className={clsx('previewTable')}
                        rowKey={(data) => data.id}
                        pagination={false}
                      >
                        {
                          subject.hasEvaluation
                            ? (
                              <>
                                {
                                  (subject.needShowBrand ? OPEN_EDIT_TOPIC_WITH_LEVEL_ONE_TABLE : OPEN_EDIT_TOPIC_TABLE).map((column) => {
                                    return (
                                      <Table.Column
                                        title={() => {
                                          if (column.key === 'brand' && viewModel.searchAngle !== 'brand') {
                                            return i18n.t('common_brand_category');
                                          }
                                          return column.title;
                                        }}
                                        dataIndex={column.dataIndex}
                                        key={column.key}
                                        width={column.width}
                                        fixed={column.fixed}
                                        render={(text, record, index) => {
                                          if (column.key === OPEN_EDIT_TOPIC_KEYS.Order) {
                                            return (
                                              <div className={styles.row}>
                                                {index + 1}
                                              </div>
                                            );
                                          }
                                          if (column.key === OPEN_EDIT_TOPIC_KEYS.Title) {
                                            return (
                                              <a
                                                href={record.url}
                                                className={styles.oneLineRow}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {text}
                                              </a>
                                            );
                                          }
                                          if (column.key === OPEN_EDIT_TOPIC_KEYS.EvalType) {
                                            return (
                                              <div className={clsx(styles.row, isNegativeText(viewModel.ratingTags[text]) && styles.isNegative)}>
                                                {viewModel.ratingTags[text] ?? '無'}
                                              </div>
                                            );
                                          }
                                          if (column.key === OPEN_EDIT_TOPIC_KEYS.TopicAttr) {
                                            return (
                                              <div className={clsx(styles.row)}>
                                                {viewModel.topicTags[text] ?? '無'}
                                              </div>
                                            );
                                          }
                                          if (column.key === OPEN_EDIT_TOPIC_KEYS.ReportAdvice) {
                                            return (
                                              <div className={clsx(styles.row)}>
                                                {viewModel.suggestionTags[text] ?? '無'}
                                              </div>
                                            );
                                          }
                                          if (column.key === OPEN_EDIT_TOPIC_KEYS.DepartmentAttr) {
                                            return (
                                              <div className={clsx(styles.row)}>
                                                {viewModel.departmentTags[text] ?? '無'}
                                              </div>
                                            );
                                          }
                                          return (
                                            <div className={styles.row}>
                                              {text}
                                            </div>
                                          );
                                        }}
                                      />
                                    );
                                  })
                                }
                              </>
                            )
                            : (
                              <>
                                {
                                  (subject.needShowBrand ? CLOSE_EDIT_TOPIC_WITH_LEVEL_ONE_TABLE : CLOSE_EDIT_TOPIC_TABLE).map((column) => {
                                    return (
                                      <Table.Column
                                        title={() => {
                                          if (column.key === 'brand' && viewModel.searchAngle !== 'brand') {
                                            return i18n.t('common_brand_category');
                                          }
                                          return column.title;
                                        }}
                                        dataIndex={column.dataIndex}
                                        key={column.key}
                                        width={column.width}
                                        fixed={column.fixed}
                                        render={(text, record, index) => {
                                          if (column.key === CLOSE_EDIT_TOPIC_KEYS.Order) {
                                            return (
                                              <div className={styles.row}>
                                                {index + 1}
                                              </div>
                                            );
                                          }
                                          if (column.key === CLOSE_EDIT_TOPIC_KEYS.Sentiment) {
                                            return (
                                              <div
                                                className={
                                                  clsx(
                                                    styles.dot,
                                                    record.sentiment === 1 && styles.positive,
                                                    record.sentiment === 0 && styles.neutral,
                                                    record.sentiment === -1 && styles.negative
                                                  )
                                                }
                                              />
                                            );
                                          }
                                          if (column.key === CLOSE_EDIT_TOPIC_KEYS.Title) {
                                            return (
                                              <a
                                                href={record.url}
                                                className={styles.oneLineRow}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {text}
                                              </a>
                                            );
                                          }
                                          return (
                                            <div className={styles.row}>
                                              {text}
                                            </div>
                                          );
                                        }}
                                      />
                                    );
                                  })
                                }
                              </>
                            )
                        }

                      </Table>
                    </div>
                    <Link
                      to={`/newsletter/${viewModel.newsletterId}/result/${viewModel.resultId}/subject/${subject.id}/preview`}
                      className={styles.topicPreviewBtn}
                      target="_blank"
                    >
                      {i18n.t('preview_page_topic_table_more_topic')}
                    </Link>
                  </div>
                );
              })
            }

          </div>
          {/* 特性關鍵字分析 */}
          <div className={styles.featureContainer}>
            <div className={styles.titleContainer}>
              <img src={BookIcon} alt="" />
              {i18n.t('preview_page_feature_analysis_title')}
            </div>

            <div className={styles.contentContainer}>
              <div className={styles.featureRow}>
                <div className={styles.cloudContainer}>
                  <div className={styles.cloudTitle}>
                    <img src={CloudIcon} alt="" />
                    {`${viewModel.mainBrand.name}${i18n.t('preview_page_word_cloud')}`}
                  </div>
                  <div className={styles.dateText}>
                    {
                      `${i18n.t('preview_page_date')} ${dayjs(viewModel.chartStartDate).tz('Asia/Taipei').format('YYYY/MM/DD')} - ${dayjs(viewModel.chartEndDate).tz('Asia/Taipei').format('YYYY/MM/DD')}`
                    }
                  </div>
                  {
                    viewModel.mainBrandWordCloud
                    && (
                      <WordCloud viewModel={viewModel.mainBrandWordCloud} />
                    )
                  }
                </div>
                <div className={styles.tableContainer}>
                  <div className={styles.tableTitle}>
                    <img src={TopicIcon} alt="" />
                    {`${viewModel.mainBrand.name}${i18n.t('preview_page_feature_keyword')}`}
                  </div>
                  <div className={styles.table}>
                    <Table
                      dataSource={viewModel.mainBrand.keywords?.slice(0, 10)}
                      className={clsx('previewTable')}
                      rowKey={(data) => data.id}
                      pagination={false}
                    >
                      {
                        FEATURE_KEYWORD_TABLE.map((column, i) => {
                          return (
                            <Table.Column
                              title={column.title}
                              dataIndex={column.dataIndex}
                              key={column.key}
                              width={column.width}
                              fixed={column.fixed}
                              render={(text, record, index) => {
                                if (column.key === FEATURE_KEYWORD_KEYS.Ranking) {
                                  return (
                                    <div className={styles.row}>
                                      <div className={clsx(styles.ranking, index <= 2 && styles.tops)}>
                                        {index + 1}
                                      </div>
                                    </div>
                                  );
                                }
                                return (
                                  <div className={styles.row}>
                                    {text}
                                  </div>
                                );
                              }}
                            />
                          );
                        })
                      }

                    </Table>
                  </div>
                </div>
              </div>
            </div>

            {
              viewModel.subjectList.map((subjectItem, si) => {
                return (
                  <div
                    key={subjectItem.id}
                    className={styles.contentContainer}
                  >
                    <div className={styles.featureRow}>
                      <div className={styles.cloudContainer}>
                        <div className={styles.cloudTitle}>
                          <img src={CloudIcon} alt="" />
                          {`${i18n.t('preview_page_subject_prefix')}${NUMBER_TC[si]}：${subjectItem.name}${i18n.t('preview_page_word_cloud')}`}
                        </div>
                        <div className={styles.dateText}>
                          {
                            `${i18n.t('preview_page_date')} ${dayjs(viewModel.chartStartDate).tz('Asia/Taipei').format('YYYY/MM/DD')} - ${dayjs(viewModel.chartEndDate).tz('Asia/Taipei').format('YYYY/MM/DD')}`
                          }
                        </div>
                        {
                          subjectItem.cloudChart && subjectItem.keywords?.length > 0
                            ? (
                              <WordCloud viewModel={subjectItem.cloudChart} />
                            )
                            : (
                              <Empty style={{ paddingTop: 80, paddingBottom: 80 }} description={i18n.t('preview_page_feature_keyword_no_data')} />
                            )
                        }
                      </div>
                      <div className={styles.tableContainer}>
                        <div className={styles.tableTitle}>
                          <img src={TopicIcon} alt="" />
                          {`${subjectItem.name}${i18n.t('preview_page_feature_keyword')}`}
                        </div>
                        <div className={styles.table}>
                          <Table
                            dataSource={subjectItem.keywords?.slice(0, 10)}
                            className={clsx('previewTable')}
                            rowKey={(data) => data.id}
                            pagination={false}
                          >
                            {
                              FEATURE_KEYWORD_TABLE.map((column, i) => {
                                return (
                                  <Table.Column
                                    title={column.title}
                                    dataIndex={column.dataIndex}
                                    key={column.key}
                                    width={column.width}
                                    fixed={column.fixed}
                                    render={(text, record, index) => {
                                      if (column.key === FEATURE_KEYWORD_KEYS.Ranking) {
                                        return (
                                          <div className={styles.row}>
                                            <div className={clsx(styles.ranking, index <= 2 && styles.tops)}>
                                              {index + 1}
                                            </div>
                                          </div>
                                        );
                                      }
                                      return (
                                        <div className={styles.row}>
                                          {text}
                                        </div>
                                      );
                                    }}
                                  />
                                );
                              })
                            }
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
          {/* copy right */}
          <div className={styles.rightContainer}>
            {i18n.t('preview_page_right')}
          </div>
          <FloatButton
            icon={<MailOutlined />}
            tooltip={i18n.t('preview_page_manual_send')}
            onClick={() => viewModel.onManualSendEmail(router)}
          />
        </div>
        <ErrorModal viewModel={viewModel.errorModalViewModel} />
      </>
    );
  }
}

DirectClientMarketing.propTypes = {
  viewModel: PropTypes.object.isRequired,
  router: PropTypes.object
};

DirectClientMarketing.defaultProps = {
  router: {}
};

export default withRouter(DirectClientMarketing);
