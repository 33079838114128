import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Table, Input, Tooltip, Empty } from 'antd';
import { PlusOutlined, CheckOutlined, CloseOutlined, CopyOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Waypoint } from 'react-waypoint';

import i18n from 'src/i18n';
import mainStore from 'src/stores/mainStore';
import CustomizeSelect from 'src/components/CustomizeSelect';
import NormalButton from 'src/components/CustomizeButton/NormalButton';
import NoBorderButton from 'src/components/CustomizeButton/NoboderButton';
import constants from 'src/constants';

import SearchIcon from 'src/assets/common/search.svg';
import EDMEditDrawer from 'src/components/EDMEditDrawer';
import ErrorModal from 'src/components/ErrorModal';

import EmployeeEDMListSectionViewModel from './viewModel';
import TimeSelectModal from './components/timeSelectModal';
import DeleteModal from './components/DeleteModal';

import styles from './styles.module.scss';
import './customize.scss';

const { EDM_LIST_TABLE, EDM_LIST_KEYS } = constants.table;
const { LOG_STAGE } = constants.normal;

@observer
class EmployeeEDMListSection extends React.Component {
  constructor() {
    super();

    this.viewModel = new EmployeeEDMListSectionViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount();
  }

  componentWillUnmount() {
    this.viewModel.willUnmount();
  }

  render() {
    return (
      <>
        <div className={styles.contentContainer}>
          <div className={styles.actionRow}>
            <div className={styles.filterContainer}>
              <div className={styles.filterTitle}>
                {i18n.t('common_filter_title')}
              </div>
              <div className={styles.selectContainer}>
                {
                  mainStore.userType === 'admin'
                  && (
                    <CustomizeSelect
                      options={this.viewModel.personInChargeList}
                      placeholder={i18n.t('edm_list_filter_pic')}
                      onChange={this.viewModel.onPersonInChargeSelect}
                      value={this.viewModel.selectedPersonInCharge}
                      showSearch
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    />
                  )
                }
                <CustomizeSelect
                  options={constants.normal.AUTO_SEND_OPTIONS}
                  placeholder={i18n.t('select_is_auto_send')}
                  onChange={this.viewModel.onAutoSendSelect}
                  value={this.viewModel.isAutoSend}
                />
                <CustomizeSelect
                  options={constants.normal.SEND_FREQUENCY_OPTIONS}
                  placeholder={i18n.t('select_frequency_title')}
                  onChange={this.viewModel.onFrequencySelect}
                  value={this.viewModel.selectedFrequency}
                  mode="multiple"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                />
                <CustomizeSelect
                  options={this.viewModel.collaboratorList}
                  placeholder={i18n.t('edm_list_filter_collaborator')}
                  onChange={this.viewModel.onCollaboratorSelect}
                  value={this.viewModel.selectedCollaborator}
                  showSearch
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                />
                <NormalButton
                  onClick={this.viewModel.onSubmit}
                  icon={<CheckOutlined />}
                >
                  {i18n.t('common_submit')}
                </NormalButton>
                {
                  this.viewModel.showClean
                  && (
                    <NoBorderButton
                      onClick={this.viewModel.onClean}
                      icon={<CloseOutlined />}
                    >
                      {i18n.t('common_clean')}
                    </NoBorderButton>
                  )
                }
              </div>
            </div>
            <NormalButton
              onClick={() => this.viewModel.drawerViewModel.onOpen()}
              icon={<PlusOutlined />}
            >
              {i18n.t('edm_list_add_new')}
            </NormalButton>
          </div>
          <div className={styles.actions}>
            <div className={styles.countText}>
              {i18n.t('edm_list_total_count').replace('%count%', this.viewModel.totalCount)}
            </div>
            <div className={styles.inputContainer}>
              <Input
                placeholder={i18n.t('edm_list_search_placeholder')}
                size="small"
                onChange={this.viewModel.onKeywordChange}
                onKeyDown={this.viewModel.onKeydown}
              />
              <div onClick={this.viewModel.onKeywordSubmit}>
                <img
                  src={SearchIcon}
                  alt=""
                  className={styles.searchIcon}
                />
              </div>
            </div>
          </div>
          {
            this.viewModel.list?.length > 0
              ? (
                <div className={clsx('edmListTableContainer', styles.tableContainer)}>
                  <Table
                    dataSource={this.viewModel.list}
                    className={clsx('edmListTable')}
                    rowKey={(data) => data.id}
                    scroll={{ x: 1784, y: this.viewModel.height - 430 }} // x: 1920 - 80(outer padding) - 56(inner padding)
                    pagination={false}
                    style={{ width: this.viewModel.width - 128 }}
                  >
                    {
                      EDM_LIST_TABLE.map((column) => {
                        return (
                          <Table.Column
                            title={column.title}
                            dataIndex={column.dataIndex}
                            key={column.key}
                            width={column.width}
                            fixed={column.fixed}
                            render={(text, record, index) => {
                              if (column.key === EDM_LIST_KEYS.Name) {
                                return (
                                  <div className={styles.nameRow}>
                                    <Tooltip
                                      title={(
                                        <div style={{ fontSize: 12 }}>
                                          {i18n.t('edm_list_table_name_desc')}
                                        </div>
                                      )}
                                      placement="bottomLeft"
                                    >
                                      <Link
                                        to={`/newsletter/${record.id}`}
                                        className={styles.nameText}
                                      >
                                        {text}
                                      </Link>
                                    </Tooltip>
                                    <CopyToClipboard
                                      text={record.id}
                                      className={styles.copiedIcon}
                                      onCopy={this.viewModel.onCopiedSuccess}
                                    >
                                      <CopyOutlined />
                                    </CopyToClipboard>
                                  </div>
                                );
                              }
                              if (column.key === EDM_LIST_KEYS.ProjectId) {
                                return (
                                  <div className={styles.idRow}>
                                    <span className={styles.idText}>
                                      {text}
                                    </span>
                                    <CopyToClipboard
                                      text={text}
                                      className={styles.copiedIcon}
                                      onCopy={this.viewModel.onCopiedSuccess}
                                    >
                                      <CopyOutlined />
                                    </CopyToClipboard>
                                  </div>
                                );
                              }
                              if (column.key === EDM_LIST_KEYS.Collaborator) {
                                const list = text.map((user) => {
                                  const target = this.viewModel.collaboratorList.find((c) => c.value === user);
                                  return target?.label ?? '---';
                                });
                                if (list.length === 0) {
                                  return '---';
                                }
                                if (list.length === 1) {
                                  return (
                                    <div>
                                      {list[0]}
                                    </div>
                                  );
                                }
                                return (
                                  <Tooltip
                                    title={list.join(',\n')}
                                    placement="right"
                                    overlayStyle={{ maxWidth: 'unset', whiteSpace: 'pre-wrap' }}
                                  >
                                    <div className={styles.moreText}>
                                      {i18n.t('edm_list_table_collaborator_more')}
                                    </div>
                                  </Tooltip>
                                );
                              }
                              if (column.key === EDM_LIST_KEYS.PersonInCharge) {
                                const target = this.viewModel.personInChargeList.find((c) => c.value === text);
                                return (
                                  <div>
                                    {target?.label ?? '---'}
                                  </div>
                                );
                              }
                              if ([EDM_LIST_KEYS.Project, EDM_LIST_KEYS.EdmAuto, EDM_LIST_KEYS.EdmManual].includes(column.key)) {
                                return (
                                  <div className={text.stage === LOG_STAGE.FAIL ? styles.failStatus : styles.successStatus}>
                                    {text.message}
                                  </div>
                                );
                              }
                              if (column.key === EDM_LIST_KEYS.Action) {
                                return (
                                  <div className={styles.actionsRow}>
                                    <Tooltip
                                      title={(
                                        <div style={{ fontSize: 12 }}>
                                          {i18n.t('edm_list_table_action_edit')}
                                        </div>
                                      )}
                                      placement="bottom"
                                    >

                                      <div
                                        className={styles.edit}
                                        onClick={() => this.viewModel.onEditClick(record.id)}
                                      />
                                    </Tooltip>
                                    <Tooltip
                                      title={(
                                        <div style={{ fontSize: 12 }}>
                                          {i18n.t('edm_list_table_action_re-analysis')}
                                        </div>
                                      )}
                                      placement="bottom"
                                    >
                                      <div
                                        className={styles.redo}
                                        onClick={() => this.viewModel.onReAnalysisClick(record.id, record.time)}
                                      />
                                    </Tooltip>
                                    <Tooltip
                                      title={(
                                        <div style={{ fontSize: 12 }}>
                                          {i18n.t('edm_list_table_action_delete')}
                                        </div>
                                      )}
                                      placement="bottom"
                                    >
                                      <div
                                        className={styles.delete}
                                        onClick={() => this.viewModel.onDeleteClick(record.id, record.name)}
                                      />
                                    </Tooltip>
                                    <Tooltip
                                      title={(
                                        <div style={{ fontSize: 12 }}>
                                          {i18n.t('edm_list_table_action_download')}
                                        </div>
                                      )}
                                      placement="bottom"
                                    >
                                      <div
                                        className={styles.download}
                                        onClick={() => this.viewModel.onDownloadClick(record.id, record.time)}
                                      />
                                    </Tooltip>
                                    {
                                      (this.viewModel.anchor && this.viewModel.list?.length === index + 1)
                                      && (
                                        <Waypoint
                                          onEnter={this.viewModel.getList}
                                        />
                                      )
                                    }
                                  </div>
                                );
                              }
                              return text;
                            }}
                          />
                        );
                      })
                    }
                  </Table>
                </div>
              )
              : (
                <div className={styles.emptyContainer}>
                  <Empty
                    description={i18n.t('edm_list_no_data_desc')}
                  />
                </div>
              )
          }
        </div>
        <EDMEditDrawer
          viewModel={this.viewModel.drawerViewModel}
          width={this.viewModel.width}
        />
        <TimeSelectModal viewModel={this.viewModel.timeSelectModalViewModel} />
        <DeleteModal viewModel={this.viewModel.deleteModalViewModel} />
        <ErrorModal viewModel={this.viewModel.errorModelViewModel} />
      </>
    );
  }
}

EmployeeEDMListSection.propTypes = {

};

EmployeeEDMListSection.defaultProps = {

};

export default EmployeeEDMListSection;
