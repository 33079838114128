export const SERVER_STATUS = (() => {
  // local
  if (/:\d{4,}$/.test(window.location.origin)) {
    const r = window.location.origin.split(/:\d{4,}/);
    if (r[0]) {
      return 'dev';
    }
  }
  // dev
  if (/d90d217a/.test(window.location.host)) {
    return 'dev';
  }

  // pro
  return 'pro';
})();

export const SALES_TEAM_ID = {
  dev: '6297249f6a46f1001cd54311',
  pro: '633d45412f7975001edccc29'
};

export const COLLABORATOR_TEAM_ID = {
  dev: '64a2970d516cd9001a15d78f',
  pro: '6412a3b28f8fb7001af85353'
};

export const CHANGE_PASSWORD_URL = {
  dev: 'https://buzz-sso-d90d217a.miraclemobile.com.tw',
  pro: 'https://sso.i-buzz-system.com'
};
