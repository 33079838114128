import i18n from 'src/i18n';

export const LINE_CHART_CONFIG_OPTIONS = {
  responsive: true,
  tension: 0.3,
  scales: {
    x: {
      title: {
        display: true,
        text: i18n.t('common_date'),
        color: '#000'
      },
      ticks: {
        color: '#000'
      }
    },
    y: {
      beginAtZero: true,
      grace: '20%',
      title: {
        display: true,
        text: i18n.t('common_volume'),
        color: '#000'
      },
      ticks: {
        color: '#000'
      }
    }
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        color: '#000'
      }
    },
    datalabels: {
      display: false
    }
  }
};

export const BAR_CHART_CONFIG_OPTIONS_CATEGORY = {
  responsive: true,
  tension: 0.3,
  scales: {
    x: {
      title: {
        display: true,
        text: `${i18n.t('common_brand_category')}`,
        color: '#000'
      },
      ticks: {
        color: '#000'
      }
    },
    y: {
      beginAtZero: true,
      grace: '20%',
      title: {
        display: true,
        text: i18n.t('common_volume'),
        color: '#000'
      },
      ticks: {
        color: '#000'
      }
    }
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        color: '#000'
      }
    },
    datalabels: {
      display: false
    }
  }
};

export const BAR_CHART_CONFIG_OPTIONS_BRAND = {
  responsive: true,
  tension: 0.3,
  scales: {
    x: {
      title: {
        display: true,
        text: `${i18n.t('common_brand')}`,
        color: '#000'
      },
      ticks: {
        color: '#000'
      }
    },
    y: {
      beginAtZero: true,
      grace: '20%',
      title: {
        display: true,
        text: i18n.t('common_volume'),
        color: '#000'
      },
      ticks: {
        color: '#000'
      }
    }
  },
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        color: '#000'
      }
    },
    datalabels: {
      display: false
    }
  }
};

export const COLOR_SET = [
  '#B03B3B', '#ED8438', '#FFD233', '#99AE6B', '#339D77', '#37A2DD', '#3857A9', '#8C99FF', '#8F3AE4', '#F452A0',
  '#FCCBE3', '#E0C8F7', '#DDE0FF', '#9BABD4', '#AFDAF1', '#B3DACC', '#D6DFC4', '#FFEDAD', '#F8CEB0', '#E1B6B6'
];
