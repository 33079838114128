import dayjs from 'dayjs';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';

import i18n from 'src/i18n';
import { SOURCE_CATEGORY_TC, NEWSLETTER_STATUS_TYPE } from 'src/constants/normal';
import { COLOR_SET } from 'src/constants/chart';
import { resizeWords } from 'src/utils';

import ErrorModalViewModel from 'src/components/ErrorModal/viewModel';
import WordCloudViewModel from 'src/components/WordCloud/viewModel';

import EDMService from 'src/services/edm';
import ResultService from 'src/services/result';
import ProjectService from 'src/services/project';
import mainStore from 'src/stores/mainStore';

export default class DirectClientMarketingViewModel {
  projectId = '';
  newsletterId = '';
  resultId = '';
  @observable startDate = null;
  @observable endDate = null;
  edmType = '';

  @observable searchAngle = 'brand';

  @observable chartStartDate = '';
  @observable chartEndDate = '';
  @observable previousChartStartDate = '';
  @observable previousChartEndDate = '';

  @observable currentTotal = 0;
  @observable previousTotal = 0;

  @observable type = null;
  @observable tagPool = [];
  /**
   * @summary subjectItem
   * @param id string
   * @param name string
   * @param hasEvaluation boolean
   * @param topic topicItem[]
   */
  @observable subjectList = [];
  @observable mainBrand = {};
  @observable mainBrandWOMCount = 0;
  @observable mainBrandChannels = [];
  @observable mainBrandSentiments = [];
  @observable mainBrandFeatures = [];
  @observable manualStatistics = [];

  @observable mainBrandTrend = { labels: [], datasets: [] };
  @observable mainBrandRanking = { labels: [], datasets: [] };
  @observable mainBrandWordCloud = null;

  @observable memo = '';

  errorModalViewModel = new ErrorModalViewModel(i18n.t('preview_page_manual_send_message_error'));

  @computed get ratingTags() {
    const result = {};
    this.tagPool.filter((tag) => tag.type === 'rating').forEach((tag) => {
      result[`${tag.value}`] = tag.label;
    });
    return result;
  }

  @computed get availableRatingTags() {
    const result = {};
    this.tagPool.filter((tag) => tag.type === 'rating' && !tag.deletedAt).forEach((tag) => {
      result[`${tag.value}`] = tag.label;
    });
    return result;
  }

  @computed get topicTags() {
    const result = {};
    this.tagPool.filter((tag) => tag.type === 'topic').forEach((tag) => {
      result[`${tag.value}`] = tag.label;
    });
    return result;
  }

  @computed get suggestionTags() {
    const result = {};
    this.tagPool.filter((tag) => tag.type === 'suggestion').forEach((tag) => {
      result[`${tag.value}`] = tag.label;
    });
    return result;
  }

  @computed get departmentTags() {
    const result = {};
    this.tagPool.filter((tag) => tag.type === 'department').forEach((tag) => {
      result[`${tag.value}`] = tag.label;
    });
    return result;
  }

  @computed get notDaily() {
    return dayjs(this.endDate).diff(dayjs(this.startDate), 'day') > 1;
  }

  @computed get ratingTagsIndex() {
    return Object.keys(this.ratingTags);
  }


  constructor(data) {
    makeObservable(this);

    this.init(data);
  }

  @action init = ({ id, rid, type }) => {
    this.newsletterId = id;
    this.resultId = rid;
    this.edmType = type;
  };

  @action didMount = async () => {
    try {
      if (mainStore.checkIsInQueue('previewPage')) {
        return;
      }
      mainStore.setLoading('previewPage');

      await this.getNewsletterDetail();
      await Promise.all([
        this.updateSearchAngle(),
        this.getResultDetail(),
        this.getProjectDetail()
      ]);

      await Promise.all([
        this.getResultSummary(),
        this.getVolumeChartData(),
        this.getVolumeChartCompareData(),
        this.getFeatureData(),
        this.getResultTopics()
      ]);
    } catch (error) {
      // > don't do anything here.
    } finally {
      mainStore.setLoadingComplete('previewPage');
    }
  };

  @action getProjectDetail = async () => {
    try {
      if (mainStore.checkIsInQueue('projectDetail')) {
        return;
      }
      mainStore.setLoading('projectDetail');

      const { meta: { searchAngle } } = await ProjectService.getProjectDetailById(this.projectId);

      runInAction(() => {
        this.searchAngle = searchAngle;
      });

    } catch (error) {
      message.error(i18n.t('api_get_project_detail_error'));
    } finally {
      mainStore.setLoadingComplete('projectDetail');
    }
  };

  @action getNewsletterDetail = async () => {
    try {
      if (mainStore.checkIsInQueue('newsletterDetail')) {
        return;
      }
      mainStore.setLoading('newsletterDetail');

      const { projectId, name, tags } = await EDMService.getEDMDetail(this.newsletterId);
      runInAction(() => {
        this.projectId = projectId;
        this.newsletterName = name;
        this.tagPool = tags.map((tag) => ({ value: tag.id, label: tag.value, type: tag.type, deletedAt: tag.deletedAt }));
      });
    } catch (error) {
      message.error(i18n.t('api_get_edm_detail_error'));
    } finally {
      mainStore.setLoadingComplete('newsletterDetail');
    }
  };

  @action getResultDetail = async () => {
    try {
      if (mainStore.checkIsInQueue('resultDetail')) {
        return;
      }
      mainStore.setLoading('resultDetail');
      const { startDate, endDate, type, memo, topics, mainBrandId } = await ResultService.getResultDetail(this.newsletterId, this.resultId);
      const subjectList = await Promise.all([
        ...topics.map((item) => this.updateSubjectContent(item))
      ]);
      runInAction(() => {
        const diffDays = dayjs(endDate).diff(dayjs(startDate), 'day') + 1;
        const isMonth = diffDays >= 28;
        this.subjectList = subjectList;
        this.startDate = startDate;
        this.endDate = endDate;
        this.chartStartDate = diffDays >= 7
          ? startDate
          : dayjs(endDate).tz('Asia/Taipei').subtract(6, 'day').startOf('day')
            .toISOString();
        this.chartEndDate = endDate;
        this.previousChartStartDate = isMonth
          ? dayjs(this.chartStartDate).tz('Asia/Taipei').subtract(2, 'day').startOf('month')
            .toISOString()
          : dayjs(this.chartStartDate).tz('Asia/Taipei').subtract(Math.max(diffDays, 7), 'day').startOf('day')
            .toISOString();
        this.previousChartEndDate = isMonth
          ? dayjs(this.chartStartDate).tz('Asia/Taipei').subtract(2, 'day').endOf('month')
            .toISOString()
          : dayjs(this.chartEndDate).tz('Asia/Taipei').subtract(Math.max(diffDays, 7), 'day').endOf('day')
            .toISOString();
        this.type = type;
        this.memo = memo ?? '';
        this.mainBrand.id = mainBrandId;
      });
      await this.updateMainBrand();

    } catch (error) {
      message.error(i18n.t('api_get_edm_result_detail_error'));
    } finally {
      mainStore.setLoadingComplete('resultDetail');
    }
  };
  @action getResultSummary = async () => {
    try {
      if (mainStore.checkIsInQueue('summary')) {
        return;
      }
      mainStore.setLoading('summary');

      const {
        channel,
        feature,
        sentiment,
        subject,
        womCount
      } = await ResultService.getResultSummary(this.newsletterId, { gte: this.startDate, lte: this.endDate });

      runInAction(() => {
        this.mainBrandChannels = channel.slice().sort((a, b) => b.count - a.count);
        this.mainBrandWOMCount = womCount;
        this.mainBrandSentiments = [
          {
            key: 'positive',
            name: i18n.t('common_positive'),
            count: sentiment?.positiveCount ?? 0
          },
          {
            key: 'negative',
            name: i18n.t('common_negative'),
            count: sentiment?.negativeCount ?? 0
          },
          {
            key: 'neutral',
            name: i18n.t('common_neutral'),
            count: sentiment?.neutralCount ?? 0
          }
        ];
        this.mainBrandFeatures = feature;
        this.manualStatistics = subject.filter((sub) => this.subjectList.find((item) => item.id === sub.id && item.hasEvaluation)).map((sub) => ({
          id: sub.id,
          name: this.subjectList.find((item) => item.id === sub.id).name,
          rating: sub.rating.filter((r) => this.availableRatingTags[r.id]).sort((a, b) => this.ratingTagsIndex.indexOf(a.id) - this.ratingTagsIndex.indexOf(b.id)).map((r) => ({
            id: r.id,
            name: this.availableRatingTags[r.id],
            count: r.count
          }))
        }));
      });

    } catch (error) {
      message.error(i18n.t('api_get_edm_result_summary_error'));
    } finally {
      mainStore.setLoadingComplete('summary');
    }
  };

  @action updateMainBrand = async () => {
    if (!this.mainBrand.id) {
      return;
    }
    try {
      if (mainStore.checkIsInQueue('mainBrand')) {
        return;
      }
      mainStore.setLoading('mainBrand');
      const [brand] = await ProjectService.getLevelContentByIds(this.projectId, [this.mainBrand.id]);
      runInAction(() => {
        this.mainBrand.name = brand.name;
      });
    } catch (error) {
      message.error(i18n.t('api_get_project_level_one_data_error'));
    } finally {
      mainStore.setLoadingComplete('mainBrand');
    }
  };

  @action updateSubjectContent = async (item) => {
    const {
      id,
      hasEvaluation,
      searchKeywordLevel1Id,
      searchKeywordLevel2Id,
      searchKeywordLevel3Id,
      searchTagParentId,
      searchTagId,
      category
    } = item;
    try {
      const sourceText = !category[0]
        ? `-${i18n.t('edm_drawer_topic_source_all')}`
        : `-${SOURCE_CATEGORY_TC[category[0]]}`;


      let name = '';

      if (searchKeywordLevel1Id && searchKeywordLevel2Id) {
        const [brand, productLine] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id, searchKeywordLevel2Id]);
        name = `${brand.name}/${productLine.name}${sourceText}`;
      }

      if (searchKeywordLevel1Id && searchKeywordLevel3Id) {
        const [brand, product] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id, searchKeywordLevel3Id]);
        name = `${brand.name}/${product.name}${sourceText}`;
      }

      if (searchKeywordLevel1Id && !searchKeywordLevel2Id && !searchKeywordLevel3Id) {
        const [brand] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id]);
        name = `${brand.name}${sourceText}`;
      }

      if (searchTagParentId) {
        const [tagParent, tag] = await ProjectService.getTagContentByIds(this.projectId, [searchTagParentId, searchTagId]);
        if (searchKeywordLevel1Id) {
          const [brand] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id]);
          name = `${brand.name}/${tagParent.name}/${tag.name}${sourceText}`;
        } else {
          name = `${tagParent.name}/${tag.name}${sourceText}`;
        }
      }

      return {
        id,
        name,
        hasEvaluation,
        needShowBrand: searchTagParentId && !searchKeywordLevel1Id,
        topics: [],
        keywords: [],
        cloudChart: {}
      };
    } catch (error) {
      return {
        id,
        name: i18n.t('common_error_subject_title'),
        hasEvaluation,
        needShowBrand: false,
        topics: [],
        keywords: [],
        cloudChart: {}
      };
    }
  };

  @action updateSearchAngle = async () => {
    try {
      if (mainStore.checkIsInQueue('searchAngle')) {
        return;
      }
      mainStore.setLoading('searchAngle');

      const { meta: { searchAngle } } = await ProjectService.getProjectDetailById(this.projectId);

      runInAction(() => {
        this.searchAngle = searchAngle;
      });

    } catch (error) {
      message.error(i18n.t('api_get_project_angle_error'));
    } finally {
      mainStore.setLoadingComplete('searchAngle');
    }
  };

  @action getVolumeChartData = async () => {
    let count = 0;
    try {
      if (mainStore.checkIsInQueue('chartData')) {
        return;
      }
      mainStore.setLoading('chartData');
      const { trend, competitor } = await ResultService.getVolumeChartData(this.newsletterId, {
        gte: this.chartStartDate,
        lte: this.chartEndDate
      });

      runInAction(() => {
        this.mainBrandTrend = {
          labels: trend.map((item) => item.date),
          datasets: [{
            label: this.mainBrand?.name ?? '主品牌',
            data: trend.map((item) => item.count),
            borderColor: COLOR_SET[0]
          }]
        };

        this.mainBrandRanking = {
          labels: competitor.map((item) => item.name),
          datasets: [{
            label: `${dayjs(this.chartStartDate).tz('Asia/Taipei').format('YYYY/MM/DD')} - ${dayjs(this.chartEndDate).tz('Asia/Taipei').format('YYYY/MM/DD')}`,
            data: competitor.map((item) => item.count),
            backgroundColor: COLOR_SET[0]
          }]
        };

        trend.forEach((item) => {
          count += item.count;
        });

        this.currentTotal = count;
      });
    } catch (error) {
      message.error(i18n.t('api_get_main_chart_data_error'));
      this.currentTotal = 0;
    } finally {
      mainStore.setLoadingComplete('chartData');
    }
  };

  @action getVolumeChartCompareData = async () => {
    let count = 0;
    try {
      if (mainStore.checkIsInQueue('chartDataCompare')) {
        return;
      }
      mainStore.setLoading('chartDataCompare');
      const { trend } = await ResultService.getVolumeChartData(this.newsletterId, {
        gte: this.previousChartStartDate,
        lte: this.previousChartEndDate
      });

      runInAction(() => {
        trend.forEach((item) => {
          count += item.count;
        });
        this.previousTotal = count;
      });
    } catch (error) {
      // message.error(i18n.t('api_get_main_chart_data_error'));
      runInAction(() => {
        this.previousTotal = 0;
      });
    } finally {
      mainStore.setLoadingComplete('chartDataCompare');
    }
  };

  @action getResultTopics = async () => {
    try {
      if (mainStore.checkIsInQueue('topics')) {
        return;
      }
      mainStore.setLoading('topics');

      const lists = await Promise.all([
        ...this.subjectList.map((subject) => this.getSingleTopicPost(subject.id))
      ]);
      runInAction(() => {
        this.subjectList = this.subjectList.map((subject, i) => ({
          ...subject,
          topics: lists[i]
        }));
      });
    } catch (error) {
      message.error(i18n.t('api_get_topics_error'));
    } finally {
      mainStore.setLoadingComplete('topics');
    }
  };

  getSingleTopicPost = async (id) => {
    try {
      const { list } = await ResultService.getResultTopics(
        this.newsletterId,
        {
          date: {
            gte: this.startDate,
            lte: this.endDate
          },
          subjectId: id,
          excludeNotSend: true
        },
        {
          size: 10
        }
      );
      return list;
    } catch (error) {
      return [];
    }
  };

  @action getFeatureData = async () => {
    try {
      if (mainStore.checkIsInQueue('cloudChart')) {
        return;
      }
      mainStore.setLoading('cloudChart');

      const { main, subject } = await ResultService.getFeatureData(this.newsletterId, {
        gte: this.chartStartDate,
        lte: this.chartEndDate
      });

      runInAction(() => {
        this.mainBrand.keywords = main.map((keyword) => ({
          id: keyword.id,
          keyword: keyword.name,
          count: keyword.count
        }));
        this.mainBrandWordCloud = new WordCloudViewModel(
          {
            data: resizeWords(main).map((keyword, i) => [keyword.name, keyword.count, COLOR_SET[i % 20], keyword.id]),
            id: 'mainBrandCloud',
            name: `main${this.mainBrand?.id ?? 'brand'}`
          },
          this
        );

        this.subjectList = this.subjectList.map((subjectItem) => {
          const targetItem = subject.find((item) => item.id === subjectItem.id);

          if (!targetItem) {
            return {
              ...subjectItem,
              keywords: [],
              cloudChart: null
            };
          }

          const keywords = targetItem.item?.map((keyword) => ({
            id: keyword.id,
            keyword: keyword.name,
            count: keyword.count
          }));
          return {
            ...subjectItem,
            keywords,
            cloudChart: new WordCloudViewModel(
              {
                data: resizeWords(keywords).map((keyword, i) => [keyword.keyword, keyword.count, COLOR_SET[i % 20], keyword.id]),
                id: `subjectCloud${subjectItem.id}`,
                name: `subject${subjectItem.id}`
              },
              this
            )
          };
        });
      });
    } catch (error) {
      message.error(i18n.t('api_get_cloud_chart_error'));
    } finally {
      mainStore.setLoadingComplete('cloudChart');
    }
  };

  @action onManualSendEmail = async (router) => {
    try {
      if (mainStore.checkIsInQueue('sendEmail')) {
        return;
      }
      mainStore.setLoading('sendEmail');

      const canContinue = await this.onCheckStatus({ gte: this.startDate, lte: this.endDate });

      if (!canContinue) {
        this.errorModalViewModel.onOpen();
        return;
      }

      await ResultService.manualSendEmail(this.newsletterId, this.resultId);

      message.success(i18n.t('preview_page_manual_send_message_success'));
      router.navigate(`/newsletter/${this.newsletterId}/result/${this.resultId}`);

    } catch (error) {
      message.error(i18n.t('preview_page_manual_send_message_error'));
    } finally {
      mainStore.setLoadingComplete('sendEmail');
    }
  };

  onCheckStatus = async (date) => {
    if (mainStore.checkIsInQueue('checkStatus')) {
      return false;
    }
    try {
      mainStore.setLoading('checkStatus');
      const { edmAuto, edmManual } = await EDMService.getEDMStatusByTime(this.newsletterId, date);
      const newsletterAutoStatus = [NEWSLETTER_STATUS_TYPE.Done, NEWSLETTER_STATUS_TYPE.Fail].includes(edmAuto?.stage);
      const newsletterManualStatus = [NEWSLETTER_STATUS_TYPE.Done, NEWSLETTER_STATUS_TYPE.Fail].includes(edmManual?.stage);
      return newsletterAutoStatus && newsletterManualStatus;
    } catch (error) {
      return false;
    } finally {
      mainStore.setLoadingComplete('checkStatus');
    }
  };
}
