import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { BAR_CHART_CONFIG_OPTIONS_CATEGORY, BAR_CHART_CONFIG_OPTIONS_BRAND } from 'src/constants/chart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

@observer
class BarChart extends React.Component {
  render() {
    const { data, searchAngle, onCompletedCallback } = this.props;
    const key = uuidv4();
    return (
      <div style={{ width: '100%', display: 'flex', aspectRatio: 3 }} className="chartContainer">
        <Bar
          key={key}
          id="mainBrandBar"
          options={{
            ...(searchAngle === 'brand' ? BAR_CHART_CONFIG_OPTIONS_BRAND : BAR_CHART_CONFIG_OPTIONS_CATEGORY),
            animation: {
              onComplete: ({ initial }) => {
                if (initial) {
                  onCompletedCallback();
                }
              }
            }
          }}
          data={data}
        />
      </div>
    );
  }
}

BarChart.propTypes = {
  data: PropTypes.object,
  searchAngle: PropTypes.string,
  onCompletedCallback: PropTypes.func
};

BarChart.defaultProps = {
  data: {
    labels: [],
    datasets: []
  },
  searchAngle: 'brand',
  onCompletedCallback: () => {}
};


export default BarChart;
