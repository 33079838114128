import { makeObservable, observable, action } from 'mobx';

export default class CheckDeleteModalViewModel {
  @observable isOpen = false;

  @observable type = '';
  @observable value = {};
  callback = () => {};

  constructor() {
    makeObservable(this);
  }

  @action onCheckDeleteModalOpen = ({ type, value, callback }) => {
    this.type = type;
    this.value = value;
    this.callback = callback;

    this.isOpen = true;
  };

  @action onConfirm = () => {
    this.callback();

    this.onClose();
  };

  @action onClose = () => {
    this.type = '';
    this.value = {};
    this.callback = () => {};

    this.isOpen = false;
  };
}
