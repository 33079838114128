import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import withRouter from 'src/components/withRouter';
import PreviewPageViewModel from './viewModel';

import PreviewTemplateFactory from './templates/factory';

@observer
class PreviewPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new PreviewPageViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount(this.props.router.params);
  }

  render() {
    if (this.viewModel.edmViewModel) {
      return PreviewTemplateFactory.generateView(this.viewModel.edmViewModel);
    }
    return null;
  }
}

PreviewPage.propTypes = {
  router: PropTypes.object
};

PreviewPage.defaultProps = {
  router: {}
};

export default withRouter(PreviewPage);
