import { makeObservable, observable, action, computed } from 'mobx';
import { TAG_ACTIONS } from 'src/constants/normal';

export default class AttributeTagViewModel {
  parent = {};
  id = '';
  @observable name = '';
  @observable action = '';
  type = '';

  @observable isEdit = false;

  constructor(data, parent) {
    makeObservable(this);

    this.init(data, parent);
  }

  @action init = (data, parent) => {
    const {
      id,
      action: tagAction,
      type,
      value
    } = data;

    this.id = id;
    this.name = value;
    this.type = type;
    this.action = tagAction ?? '';

    this.parent = parent;
  };

  @action onEdit = () => {
    if (this.parent.readOnly) {
      return;
    }
    this.isEdit = true;
  };

  @action onKeydown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      if (this.name.trim()) {
        const name = e.target.value;

        const checkResult = this.parent.checkHasSame({
          name,
          type: this.type
        });

        if (!checkResult) {
          this.name = e.target.value;
          this.action = this.action === TAG_ACTIONS.Create ? TAG_ACTIONS.Create : TAG_ACTIONS.Update;
          this.onCancel();
        }
      }
    }
    if (e.code === 'Escape' || e.keyword === 27) {
      e.stopPropagation();
      e.preventDefault();
      this.onCancel();
    }
  };

  @action onCancel = () => {
    this.isEdit = false;
  };

  @action onDeleteClick = () => {
    this.parent.onCheckDeleteModalOpen({
      type: 'tag',
      value: {
        type: this.type,
        tag: this.name
      },
      callback: this.onTagRemove
    });
  };

  @action onTagRemove = () => {
    if (this.action === TAG_ACTIONS.Create) {
      this.parent.removeByValue({ id: this.id, action: this.action, type: this.type, name: this.name });
    } else {
      this.action = TAG_ACTIONS.Delete;
    }
  };
}
