import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Tag, Input } from 'antd';

import i18n from 'src/i18n';

import styles from './styles.module.scss';


@observer
class AttributeTag extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { viewModel, closable } = this.props;
    if (viewModel.isEdit) {
      return (
        <Input
          defaultValue={viewModel.name}
          onKeyDown={viewModel.onKeydown}
          className={styles.input}
          onBlur={viewModel.onCancel}
          maxLength={30}
          autoFocus
        />
      );
    }
    return (
      <Tag
        className={clsx(styles.tagStyle)}
        key={viewModel.name}
        closable={closable}
        onDoubleClick={viewModel.onEdit}
        onClose={viewModel.onDeleteClick}
      >
        {viewModel.name}
      </Tag>
    );
  }
}

AttributeTag.propTypes = {
  viewModel: PropTypes.object.isRequired,
  closable: PropTypes.bool
};

AttributeTag.defaultProps = {
  closable: false
};

export default AttributeTag;
