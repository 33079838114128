
import { SOURCE_CATEGORY_TC } from 'src/constants/normal';

export default class TopicListModel {
  constructor(data) {
    if (!data[0]) {
      this.post = [];
      return;
    }

    const { post } = data[0];

    this.post = post.map((postItem) => {
      const {
        mainId,
        title,
        brand,
        url,
        category,
        website,
        channel,
        womCount,
        sentiment,
        rating,
        topic,
        suggestion,
        department,
        shouldSend
      } = postItem;

      return {
        id: mainId,
        title: title.length === 0 ? '🔗' : title,
        url,
        brandId: brand.id,
        brand: brand.name,
        category: SOURCE_CATEGORY_TC[category],
        channel: `${website ?? '---'} / ${channel ?? '---'}`,
        womCount,
        sentiment,
        evalType: rating && rating !== 'None' ? rating : '無' ?? '無',
        topicAttr: topic && topic !== 'None' ? topic : '無' ?? '無',
        reportAdvice: suggestion && suggestion !== 'None' ? suggestion : '無' ?? '無',
        departmentAttr: department && department !== 'None' ? department : '無' ?? '無',
        shouldSend: shouldSend ?? true
      };
    });
  }

  static fromRes(data) {
    return new TopicListModel(data);
  }
}
