import { makeObservable, observable, action } from 'mobx';
import { message } from 'antd';

import mainStore from 'src/stores/mainStore';
import i18n from 'src/i18n';
import EDMService from 'src/services/edm';

export default class DeleteModalViewModel {
  parent = null;

  @observable isOpen = false;

  targetNewsletterId = null;
  @observable newsletterName = '';
  @observable input = '';

  constructor(parent) {
    makeObservable(this);

    this.init(parent);
  }

  @action init = (parent) => {
    this.parent = parent;
  };

  @action onOpen = (id, name) => {
    this.targetNewsletterId = id;
    this.newsletterName = name;

    this.isOpen = true;
  };

  @action onConfirm = async () => {

    const result = await this.onDelete();
    if (result) {
      message.success(i18n.t('edm_list_table_action_delete_message_success'));
      this.parent.onRefresh();
      this.onClose();
    }
  };

  @action onChange = (event) => {
    this.input = event.target.value;
  };

  @action onKeydown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onConfirm();
    }
  };

  @action onDelete = async () => {
    if (!this.targetNewsletterId) {
      return false;
    }

    if (this.input !== this.newsletterName) {
      message.error(i18n.t('edm_list_table_action_delete_message_error'));
      return false;
    }
    try {
      if (mainStore.checkIsInQueue('deleteLetter')) {
        return false;
      }
      mainStore.setLoading('deleteLetter');
      await EDMService.deleteEDM(this.targetNewsletterId);
      return true;

    } catch (error) {
      message.error(i18n.t('api_delete_edm_error'));
      return false;
    } finally {
      mainStore.setLoadingComplete('deleteLetter');
    }
  };

  @action onClose = () => {
    this.type = null;
    this.targetNewsletterId = null;
    this.newsletterName = '';
    this.input = '';

    this.isOpen = false;
  };
}
