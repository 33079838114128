import { makeObservable, observable, action, computed } from 'mobx';

export default class PlusTagViewModel {
  @observable tagName = '';
  @observable isEdit = false;

  constructor() {
    makeObservable(this);
  }

  @action onEdit = () => {
    this.isEdit = true;
  };

  @action onChange = (e) => {
    this.tagName = e.target.value;
  };

  @action onKeydown = (e, onCreateEnd) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      if (this.tagName.trim()) {
        const success = onCreateEnd(this.tagName);
        if (success) {
          this.onCancel();
        }
      }
    }
    if (e.code === 'Escape' || e.keyword === 27) {
      e.stopPropagation();
      e.preventDefault();
      this.onCancel();
    }
  };

  @action onCancel = () => {
    this.tagName = '';
    this.isEdit = false;
  };
}
