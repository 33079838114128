import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';

import i18n from 'src/i18n';
import mainStore from 'src/stores/mainStore';
import { TIME_SELECT_MODAL_TYPE, NEWSLETTER_STATUS_TYPE } from 'src/constants/normal';
import ErrorModalViewModel from 'src/components/ErrorModal/viewModel';

import EDMService from 'src/services/edm';
import TimeSelectModalViewModel from './components/timeSelectModal/viewModel';

export default class CollaboratorEDMListSectionViewModel {
  @observable totalCount = 0;
  @observable list = [];
  @observable anchor = null;

  resizeObserver = null;
  @observable width = 1920;
  @observable height = 1080;

  @observable keyword = '';
  keywordCache = '';

  timeSelectModalViewModel = new TimeSelectModalViewModel(this);
  errorModelViewModel = new ErrorModalViewModel(i18n.t('edm_list_table_action_analyzing_error'));


  constructor() {
    makeObservable(this);
  }

  @action didMount = async () => {
    const resizeObserver = new ResizeObserver((entries) => {
      const { height, width } = entries[0].contentRect;
      runInAction(() => {
        this.width = width;
        this.height = height;
      });
    });
    this.resizeObserver = resizeObserver;
    const container = document.querySelector('#app');
    this.resizeObserver.observe(container);
    await this.getList();
  };

  @action willUnmount = () => {
    const container = document.querySelector('#app');
    this.resizeObserver.unobserve(container);
  };

  @action onKeywordChange = (e) => {
    this.keyword = e.target.value;

    if (!this.keyword.trim()) {
      this.onKeywordSubmit();
    }
  };

  @action onKeydown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onKeywordSubmit();
    }
  };

  @action onKeywordSubmit = () => {
    if (this.keyword === this.keywordCache) {
      return;
    }

    this.keywordCache = this.keyword;
    this.anchor = null;
    this.list = [];

    this.getList();
  };

  @action onCollaboratorSelect = (value) => {
    this.selectedCollaborator = value;
  };

  // > list related.
  @action getList = async () => {
    if (mainStore.checkIsInQueue('getEdmList')) {
      return;
    }
    mainStore.setLoading('getEdmList');
    try {
      const params = {
        ...(this.anchor && { anchor: this.anchor }),
        ...(this.keywordCache && { keyword: this.keywordCache })
      };

      const { list, anchor, count } = await EDMService.getEDMList(params);
      const { list: statusList } = await EDMService.getMultipleEDMStatus(list.map((el) => el.id));
      runInAction(() => {
        this.list = [...this.list, ...list.map((item) => ({
          ...item,
          ...(statusList.find((status) => status.id === item.id) ?? {})
        }))];
        this.anchor = anchor;
        if (count) {
          this.totalCount = count;
        }
      });

    } catch (error) {
      message.error(i18n.t('api_get_edm_list_error'));
    } finally {
      mainStore.setLoadingComplete('getEdmList');
    }
  };

  @action onRefresh = () => {
    this.anchor = null;
    this.list = [];

    this.getList();
  };


  @action onReAnalysisClick = async (id, time) => {
    try {
      if (mainStore.checkIsInQueue('checkStatus')) {
        return;
      }
      mainStore.setLoading('checkStatus');
      const { newsletterAuto, newsletterManual } = await EDMService.getSingleEDMStatus(id);
      if (
        !([NEWSLETTER_STATUS_TYPE.Done, NEWSLETTER_STATUS_TYPE.Fail].includes(newsletterAuto)
        && [NEWSLETTER_STATUS_TYPE.Done, NEWSLETTER_STATUS_TYPE.Fail].includes(newsletterManual))
      ) {
        this.errorModelViewModel.onOpen();
        return;
      }
      this.timeSelectModalViewModel.onOpen(id, time, TIME_SELECT_MODAL_TYPE.ReAnalysis);

    } catch (error) {
      message.error(i18n.t('api_get_edm_status_error'));
    } finally {
      mainStore.setLoadingComplete('checkStatus');
    }
  };

  @action onDownloadClick = async (id, time) => {
    try {
      if (mainStore.checkIsInQueue('checkStatus')) {
        return;
      }
      mainStore.setLoading('checkStatus');
      const { newsletterAuto, newsletterManual } = await EDMService.getSingleEDMStatus(id);
      if (
        !([NEWSLETTER_STATUS_TYPE.Done, NEWSLETTER_STATUS_TYPE.Fail].includes(newsletterAuto)
        && [NEWSLETTER_STATUS_TYPE.Done, NEWSLETTER_STATUS_TYPE.Fail].includes(newsletterManual))
      ) {
        this.errorModelViewModel.onOpen();
        return;
      }
      this.timeSelectModalViewModel.onOpen(id, time, TIME_SELECT_MODAL_TYPE.Download);
    } catch (error) {
      message.error(i18n.t('api_get_edm_status_error'));
    } finally {
      mainStore.setLoadingComplete('checkStatus');
    }
  };

}
