import { EDITABLE_TOPIC_KEYS } from 'src/constants/table';
import { SOURCE_CATEGORY_TC, DEFAULT_SENTIMENT_TC } from 'src/constants/normal';

export default class DownloadTopicsModel {
  constructor(data) {
    this.list = data.map((subject) => {
      const { post, subjectId } = subject;

      const formattedPosts = post.map((postItem, i) => {
        const {
          title,
          brand,
          author,
          date,
          latestDate,
          category,
          website,
          channel,
          commentCount,
          womCount,
          sentiment,
          rating,
          topic,
          suggestion,
          department,
          url
        } = postItem;

        return {
          [`${EDITABLE_TOPIC_KEYS.Order}`]: i + 1,
          [`${EDITABLE_TOPIC_KEYS.Title}`]: title,
          [`${EDITABLE_TOPIC_KEYS.Brand}`]: brand.name,
          [`${EDITABLE_TOPIC_KEYS.Author}`]: author,
          [`${EDITABLE_TOPIC_KEYS.Date}`]: date,
          [`${EDITABLE_TOPIC_KEYS.LatestDate}`]: latestDate,
          [`${EDITABLE_TOPIC_KEYS.Category}`]: SOURCE_CATEGORY_TC[category],
          [`${EDITABLE_TOPIC_KEYS.Website}`]: website ?? '---',
          [`${EDITABLE_TOPIC_KEYS.Channel}`]: channel ?? '---',
          [`${EDITABLE_TOPIC_KEYS.CommentCount}`]: commentCount ?? 0,
          [`${EDITABLE_TOPIC_KEYS.WOMCount}`]: womCount ?? 0,
          [`${EDITABLE_TOPIC_KEYS.Sentiment}`]: DEFAULT_SENTIMENT_TC[sentiment + 1],
          [`${EDITABLE_TOPIC_KEYS.EvalType}`]: rating,
          [`${EDITABLE_TOPIC_KEYS.TopicAttr}`]: topic,
          [`${EDITABLE_TOPIC_KEYS.ReportAdvice}`]: suggestion,
          [`${EDITABLE_TOPIC_KEYS.DepartmentAttr}`]: department,
          [`${EDITABLE_TOPIC_KEYS.Url}`]: url
        };
      });

      return {
        id: subjectId,
        post: formattedPosts
      };
    });

  }

  static fromRes(data) {
    return new DownloadTopicsModel(data);
  }
}
