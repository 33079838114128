import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import i18n from 'src/i18n';
import withRouter from 'src/components/withRouter';

import LoginPageViewModel from './viewModel';

import styles from './styles.module.scss';

@observer
class LoginPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new LoginPageViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount();
  }

  render() {
    return (
      <div className={styles.pageContainer}>
        <div className={styles.loginContainer}>
          <div className={styles.title}>
            {i18n.t('login_page_title')}
          </div>
          <img src="/logo192.png" alt="logo" className={styles.img} />
          <Button
            className={styles.btn}
            onClick={() => this.viewModel.onLoginClick(this.props.router)}
          >
            {i18n.t('login_page_btn')}
          </Button>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  router: PropTypes.object
};

LoginPage.defaultProps = {
  router: {}
};

export default withRouter(LoginPage);
