import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DatePicker, Table, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import dayjs from 'dayjs';

import i18n from 'src/i18n';
import withRouter from 'src/components/withRouter';
import constants from 'src/constants';
import { getPMSystemHost } from 'src/utils';
import mainStore from 'src/stores/mainStore';

import BackIcon from 'src/assets/common/back.svg';
import CustomizeSelect from 'src/components/CustomizeSelect';
import NormalButton from 'src/components/CustomizeButton/NormalButton';
import NoBorderButton from 'src/components/CustomizeButton/NoboderButton';
import ErrorModal from 'src/components/ErrorModal';

import TimeSelectModal from './components/timeSelectModal';
import NewsletterPageViewModel from './viewModel';

import styles from './styles.module.scss';
import './customize.scss';

const { RESULT_LIST_KEYS, RESULT_LIST_TABLE } = constants.table;
const { LOG_STAGE } = constants.normal;

@observer
class NewsletterPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new NewsletterPageViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount(this.props.router.params.id);
  }

  componentWillUnmount() {
    this.viewModel.willUnmount();
  }

  render() {
    return (
      <>
        <div className={clsx(styles.pageContainer)}>
          <div className={styles.titleContainer}>
            <div className={styles.pageTitle}>
              {`${i18n.t('edm_list_page_title')} / 「${this.viewModel.newsletterName}」${i18n.t('result_page_title')}`}
            </div>
            <Link
              to="/"
              className={styles.backBtn}
            >
              <img src={BackIcon} alt="" />
              {i18n.t('common_back_page')}
            </Link>
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.subject}>
              {`${i18n.t('result_page_email_subject')}: ${this.viewModel.subject}`}
            </div>
            <div className={styles.actionRow}>
              <div className={styles.filterTitle}>
                {i18n.t('result_page_filter_title')}
              </div>
              <div className={styles.filterContainer}>
                <DatePicker.RangePicker
                  placeholder={[i18n.t('result_page_filter_date_picker_start_placeholder'), i18n.t('result_page_filter_date_picker_end_placeholder')]}
                  value={this.viewModel.selectedDate}
                  onCalendarChange={this.viewModel.onDateChange}
                  className={styles.datePicker}
                  changeOnBlur
                  allowClear
                  disabledDate={(current) => {
                    return dayjs(current).tz('Asia/Taipei') < dayjs(this.viewModel.availableDate[0].startOf('day')).tz('Asia/Taipei')
                      || dayjs(current).tz('Asia/Taipei') > dayjs(this.viewModel.availableDate[1].endOf('day')).tz('Asia/Taipei');
                  }}
                />
                <CustomizeSelect
                  placeholder={i18n.t('result_page_filter_frequency_placeholder')}
                  options={constants.normal.QUEUE_SCHEDULE_OPTIONS}
                  onChange={this.viewModel.onTypeChange}
                  value={this.viewModel.selectedType}
                  mode="multiple"
                />
                {/* <CustomizeSelect
                  placeholder={i18n.t('result_page_filter_analysis_status_placeholder')}
                  options={constants.normal.NEWSLETTER_STATUS_OPTIONS}
                  onChange={this.viewModel.onStatusSelect}
                  value={this.viewModel.selectedStatus}
                /> */}
                <CustomizeSelect
                  placeholder={i18n.t('result_page_filter_sender_placeholder')}
                  options={this.viewModel.senderList}
                  onChange={this.viewModel.onSenderSelect}
                  value={this.viewModel.selectedSender}
                  showSearch
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                />

                <NormalButton
                  onClick={this.viewModel.onSubmit}
                  icon={<CheckOutlined />}
                >
                  {i18n.t('common_submit')}
                </NormalButton>
                {
                  this.viewModel.showClean
                  && (
                    <NoBorderButton
                      onClick={this.viewModel.onClean}
                      icon={<CloseOutlined />}
                    >
                      {i18n.t('common_clean')}
                    </NoBorderButton>
                  )
                }
              </div>
            </div>
            <div className={styles.actions}>
              <div className={styles.noteContainer}>
                <ExclamationCircleOutlined />
                <div className={styles.note}>
                  {i18n.t('result_page_refresh_note')}
                </div>
              </div>
              <div className={styles.countText}>
                {i18n.t('edm_list_total_count').replace('%count%', this.viewModel.totalCount ?? 0)}
              </div>
            </div>
            <div className={clsx('edmListTableContainer', styles.tableContainer)}>
              <Table
                dataSource={this.viewModel.letterList}
                className={clsx('resultListTable')}
                rowKey={(data) => data.id}
                scroll={{ x: 1784, y: this.viewModel.height - 462 }} // x: 1920 - 80(outer padding) - 56(inner padding)
                pagination={false}
                style={{ width: this.viewModel.width - 128 }}
              >
                {
                  RESULT_LIST_TABLE.map((column) => {
                    return (
                      <Table.Column
                        title={
                          column.key === RESULT_LIST_KEYS.LatestActionTime
                            ? (
                              <Tooltip
                                title={i18n.t('result_page_table_latest_action_time_tooltip')}
                                overlayStyle={{ maxWidth: 'unset', whiteSpace: 'nowrap' }}
                              >
                                <div>
                                  {column.title}
                                  <ExclamationCircleOutlined style={{ marginLeft: 4, fontSize: 12 }} />
                                </div>
                              </Tooltip>
                            )
                            : column.title
                        }
                        dataIndex={column.dataIndex}
                        key={column.key}
                        width={column.width}
                        fixed={column.fixed}
                        render={(text, record, index) => {
                          if (column.key === RESULT_LIST_KEYS.LatestSender) {
                            if (!record.latestSender) {
                              return (
                                <div className={styles.row}>
                                  ---
                                </div>
                              );
                            }
                            if (record.latestSender === 'SYSTEM') {
                              return (
                                <div className={styles.row}>
                                  {i18n.t('result_page_table_latest_sender_system')}
                                </div>
                              );
                            }
                            return (
                              <div className={styles.row}>
                                {this.viewModel.findSender(record.latestSender)}
                              </div>
                            );
                          }
                          if ([RESULT_LIST_KEYS.EdmAuto, RESULT_LIST_KEYS.EdmManual, RESULT_LIST_KEYS.Project].includes(column.key)) {
                            return (
                              <div className={text.stage === LOG_STAGE.FAIL ? styles.failStatus : styles.successStatus}>
                                {record[column.key].message}
                              </div>
                            );
                          }
                          if (column.key === RESULT_LIST_KEYS.Action) {
                            return (
                              <div className={styles.actionsRow}>
                                <Tooltip
                                  title={(
                                    <div style={{ fontSize: 12 }}>
                                      {i18n.t('result_page_table_action_edit')}
                                    </div>
                                  )}
                                  placement="bottom"
                                >

                                  <div
                                    className={styles.edit}
                                    onClick={() => this.viewModel.onEditClick(record, this.props.router)}
                                  />
                                </Tooltip>
                                <Tooltip
                                  title={(
                                    <div style={{ fontSize: 12 }}>
                                      {i18n.t('result_page_table_action_preview')}
                                    </div>
                                  )}
                                  placement="bottom"
                                >
                                  <div
                                    className={styles.view}
                                    onClick={() => this.viewModel.onPreviewClick(record)}
                                  />
                                </Tooltip>
                                <Tooltip
                                  title={(
                                    <div style={{ fontSize: 12 }}>
                                      {i18n.t('result_page_table_action_download')}
                                    </div>
                                  )}
                                  placement="bottom"
                                >
                                  <div
                                    className={styles.download}
                                    onClick={() => this.viewModel.onDownloadClick(record.time, record.startDate, record.endDate, record.id)}
                                  />
                                </Tooltip>
                                {
                                  mainStore.userType !== 'collaborator'
                                  && (

                                    <Tooltip
                                      title={(
                                        <div style={{ fontSize: 12 }}>
                                          {i18n.t('result_page_table_action_pm')}
                                        </div>
                                      )}
                                      placement="bottom"
                                    >
                                      <a
                                        href={`${getPMSystemHost()}project/settings/${this.viewModel.projectId}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <div className={styles.link} />
                                      </a>
                                    </Tooltip>
                                  )
                                }
                                <Tooltip
                                  title={(
                                    <div style={{ fontSize: 12 }}>
                                      {i18n.t('result_page_table_action_reanalysis')}
                                    </div>
                                  )}
                                  placement="bottom"
                                >
                                  <div
                                    className={styles.redo}
                                    onClick={() => this.viewModel.onReAnalysisClick(record.time, record.startDate, record.endDate)}
                                  />
                                </Tooltip>
                                {
                                  (this.viewModel.anchor && this.viewModel.letterList?.length === index + 1)
                                  && (
                                    <Waypoint
                                      onEnter={this.viewModel.getList}
                                    />
                                  )
                                }
                              </div>
                            );
                          }
                          return (
                            <div className={styles.row}>
                              {text}
                            </div>
                          );
                        }}
                      />
                    );
                  })
                }
              </Table>
            </div>
          </div>
        </div>
        <TimeSelectModal viewModel={this.viewModel.timeSelectModalViewModel} />
        <ErrorModal viewModel={this.viewModel.errorModalViewModel} />
      </>
    );
  }
}

NewsletterPage.propTypes = {
  router: PropTypes.object
};

NewsletterPage.defaultProps = {
  router: {}
};

export default withRouter(NewsletterPage);
